import { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Breadcrumbs,
  Datepicker,
  Button,
  GridContainer,
  GridItem,
  Select,
  TextField,
} from '../../../components/shared';
import { useNavigate } from 'react-router-dom';
import { AttachMoney } from '@mui/icons-material';
import { useGetModelsQuery } from '../../../services/Models/ModelsService';
import { useGetAllSublocationsQuery } from '../../../services/Sublocation/SublocationService';
import { useGetMachineStatusQuery, useAddMachineMutation } from '../../../services/Machines/MachinesService';
import { dateFormatForApi, getOptions } from '../../../utils/common-methods';
import { useDebounce } from '../../../hooks';
import { routeConstants } from '../../../constants/routeConstants';
import { addMachineSchema } from '../../../schema/validationSchemas';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import useStyles from './style';

const AddMachine = () => {
  const [modelInputValue, setModelInputValue] = useState('');
  const [sublocationInputValue, setSublocationInputValue] = useState('');
  const [page, setPage] = useState(1);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [currentInputVal, setCurrentInputVal] = useState('');

  const navigate = useNavigate();
  const classes = useStyles();
  const {
    MACHINE: { MACHINE, ADD_MACHINE },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: MACHINE, redirection: `${routeConstants.MACHINES_ROUTE}?selectedTab=1` }, { text: ADD_MACHINE }],
    [],
  );
  const [addMachineApi, { isSuccess: addMachineSuccess }] = useAddMachineMutation();
  const { currentData: modelResponse } = useGetModelsQuery({
    searchParams: `q=${useDebounce(modelInputValue, 600)}`,
    perPage: 10,
    page: 1,
  });
  const { currentData: sublocationsResponse, isSuccess: subLocationIsSuccess } = useGetAllSublocationsQuery({
    searchParams: `q=${useDebounce(sublocationInputValue, 600)}`,
    perPage: 100,
    page: page,
  });

  useEffect(() => {
    const sublocationTypes = getOptions(sublocationsResponse);
    setSubLocationOptions((prev) => [...prev, ...sublocationTypes]);
  }, [sublocationsResponse, page]);

  const { currentData: assetStatusTypeResponse } = useGetMachineStatusQuery();
  const assetStatus = useMemo(
    () => getOptions(assetStatusTypeResponse?.data?.assetStatuses),
    [assetStatusTypeResponse],
  );

  const modelTypes = useMemo(() => getOptions(modelResponse?.data?.models), [modelResponse]);

  if (addMachineSuccess) {
    navigate(`/${routeConstants.MACHINES_ROUTE}`);
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      statusId: '',
      subLocationId: { text: '', value: '' },
      modelId: { text: '', value: '' },
      price: '',
      installedDate: '',
      uninstalledDate: '',
      costPerPlay: 0,
      clicksPerPlay: 0,
    },
    validationSchema: addMachineSchema,
    onSubmit: (data) => {
      const apiData = {
        title: data.title,
        statusId: data.statusId || null,
        subLocationId: data.subLocationId?.value || null,
        modelId: data.modelId?.value || null,
        price: data.price ? Number(data.price) : null,
        installedDate: dateFormatForApi(data.installedDate) || null,
        uninstalledDate: dateFormatForApi(data.uninstalledDate) || null,
        costPerPlay: data.costPerPlay || 0,
        clicksPerPlay: data.clicksPerPlay || 0,
      };
      addMachineApi(apiData);
      onSaveHandler();
    },
  });

  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  const onCancelHandler = () => {
    navigate(`/${routeConstants.MACHINES_ROUTE}`, { redirect: true });
  };

  const onSaveHandler = () => {
    navigate(`/${routeConstants.MACHINES_ROUTE}`, { redirect: true });
  };

  return (
    <Box>
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      <Typography variant="h1" className={classes.addMachineTitle}>
        Add Machine
      </Typography>
      <Box component={Paper}>
        <form onSubmit={formik.handleSubmit} className={classes.formContent}>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center" className={classes.formFieldContainer}>
            <GridItem xs={12} md={6}>
              <TextField
                className={classes.formFields}
                label="Machine Name"
                inputProps={{
                  'data-testid': 'add-machine-form',
                }}
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="Status"
                data-testid="add-machine-form"
                options={assetStatus || []}
                {...getFieldProps('statusId')}
                error={Boolean(touched.statusId && errors.statusId)}
                helperText={touched.statusId && errors.statusId}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Datepicker
                label="Installed Date"
                className={classes.formFields}
                value={values.installedDate ? new Date(values.installedDate) : null}
                InputProps={{
                  'data-testid': 'add-machine-form',
                }}
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('installedDate', newValue)}
                onBlur={() => formik.setFieldTouched('installedDate')}
                error={Boolean(touched.installedDate && errors.installedDate)}
                helperText={touched.installedDate && errors.installedDate}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Datepicker
                label="Uninstall Date"
                className={classes.formFields}
                value={values.uninstalledDate ? new Date(values.uninstalledDate) : null}
                InputProps={{
                  'data-testid': 'add-machine-form',
                }}
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('uninstalledDate', newValue)}
                onBlur={() => formik.setFieldTouched('uninstalledDate')}
                error={Boolean(touched.uninstalledDate && errors.uninstalledDate)}
                helperText={touched.uninstalledDate && errors.uninstalledDate}
              />
            </GridItem>
            <GridItem xs={12} md={6} className={classes.formFields}>
              <Autocomplete
                label="Model Name"
                data-testid="add-machine-form"
                value={values.modelId}
                options={modelTypes || []}
                getOptionLabel={(option) => option?.text || ''}
                onChange={(e, value) => {
                  setFieldValue('modelId', value);
                }}
                onInputChange={(event, newInputValue) => {
                  setModelInputValue(newInputValue);
                }}
                onBlur={() => formik.setFieldTouched('modelId')}
                error={Boolean(touched.modelId && errors.modelId)}
                helperText={touched.modelId && (errors.modelId?.text || errors.modelId)}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                className={classes.formFields}
                label="Price"
                {...getFieldProps('price')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                  'data-testid': 'add-machine-form',
                  startAdornment: <AttachMoney />,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Autocomplete
                ListboxProps={{
                  onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                      if (subLocationIsSuccess && currentInputVal === sublocationInputValue) {
                        setPage((prevPage) => prevPage + 1);
                      }
                    }
                  },
                }}
                label="Sub Location"
                data-testid="add-machine-form"
                value={values.subLocationId}
                options={subLocationOptions || []}
                getOptionLabel={(option) => option.text || ''}
                onChange={(e, value) => {
                  setFieldValue('subLocationId', value);
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                }}
                onInputChange={(event, newInputValue) => {
                  setCurrentInputVal(newInputValue);
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                  setSublocationInputValue(newInputValue);
                }}
                onBlur={() => {
                  formik.setFieldTouched('subLocationId');
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                className={classes.formFields}
                label="Cost Per Play"
                type="number"
                {...getFieldProps('costPerPlay')}
                InputProps={{
                  'data-testid': 'add-machine-form',
                  startAdornment: <AttachMoney />,
                }}
                error={Boolean(touched.costPerPlay && errors.costPerPlay)}
                helperText={touched.costPerPlay && errors.costPerPlay}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                className={classes.formFields}
                label="Clicks Per Play"
                type="number"
                {...getFieldProps('clicksPerPlay')}
                inputProps={{
                  'data-testid': 'add-machine-form',
                }}
                error={Boolean(touched.clicksPerPlay && errors.clicksPerPlay)}
                helperText={touched.clicksPerPlay && errors.clicksPerPlay}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.btnWrap}>
            <GridItem xs={12}>
              <Button type="Submit" variant="contained" data-testid="addMachineBtn">
                <Typography variant="button">CREATE</Typography>
              </Button>
              <Button onClick={onCancelHandler}>CANCEL</Button>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
    </Box>
  );
};

export default AddMachine;
