import React, { useState } from 'react';
import { Typography, Box, Paper, useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { GridContainer, GridItem, Button, Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import ActiveTab from './ActiveTab';
import { Link, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import ArchiveTab from './ArchiveTab';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';

const displayName = 'Locations';

const Locations = () => {
  const [totalLocationsCount, setTotalLocationsCount] = useState('');
  const canAdd = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.BUSDEV_ADMINISTRATION, ROLE.ACCOUNTING]);
  const [searchParams] = useSearchParams();
  const isArchiveTab = searchParams.get('archive');
  const defaultTabValue = isArchiveTab === 'true' ? '2' : '1';
  const theme = useTheme();
  const tabs = [
    {
      heading: TAB_TYPES.ACTIVE,
      content: <ActiveTab onTotalLocationsCount={(v) => setTotalLocationsCount(v)} />,
    },
    {
      heading: TAB_TYPES.ARCHIVE,
      content: <ArchiveTab onTotalLocationsCount={(v) => setTotalLocationsCount(v)} />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} display="flex" alignItems="center">
          <Typography variant="h1">Locations</Typography>
        </GridItem>
        <GridItem xs={6} display="flex" pr={3} justifyContent="end" alignItems="center">
          <Button
            component={Link}
            to={`/${routeConstants.ADD_LOCATION}`}
            variant="contained"
            data-testid="add-location-btn"
            startIcon={<AddIcon />}
            disabled={!canAdd}
          >
            ADD NEW
          </Button>
        </GridItem>
      </GridContainer>
      <Typography variant="h3" style={{ color: theme.palette.secondary.main }}>{`${
        totalLocationsCount ? `${totalLocationsCount} Locations` : ''
      }`}</Typography>
      <Box mt={2} component={Paper}>
        <Tabs defaultTab={defaultTabValue} tabsData={tabs} />
      </Box>
    </React.Fragment>
  );
};

Locations.displayName = displayName;
export default Locations;
