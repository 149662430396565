import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.location}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.location}`;
      },
      transformResponse: (responseData) => {
        const pagination = responseData?.pagination;
        const loadLocations = responseData?.data?.locations;
        const transformLocation = loadLocations.map((location) => {
          location.name = location.locationName;
          location.countryName = location?.country?.name;
          location.stateName = location?.state?.name;
          location.cityName = location?.city?.name;
          const productTypeNames = location?.sublocations?.flatMap((sublocation) => {
            const productCategoryNames = sublocation?.productCategories?.map(
              (productCategory) => productCategory?.name,
            );
            return productCategoryNames;
          });
          location.productTypes = productTypeNames ?? [];
          if (location.status) {
            location.status = 'Active';
          } else {
            location.status = 'Inactive';
          }
          return location;
        });

        return {
          ...responseData.data,
          locations: transformLocation,
          pagination,
        };
      },
      providesTags: ['Locations'],
    }),
    getLocationDetailsById: build.query({
      query: (args) => {
        const { id, searchParams = '' } = args;
        return `${apiUrl.location}/${id}?${searchParams}`;
      },
      providesTags: ['Location', 'LocationOwner', 'Leases'],
    }),
    addLocation: build.mutation({
      query: (data) => ({
        url: `${apiUrl.location}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Location'],
    }),
    deleteLocationById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.location}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Locations', 'Location'],
    }),
    getAllLocationTypes: build.query({
      query: () => `${apiUrl.location}/${apiUrl.locationType}?status=true`,
      transformResponse: (response) =>
        response.data?.types ? response.data?.types?.sort((a, b) => a.name.localeCompare(b.name)) : [],
    }),
    getAllLocationOwners: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.location}/${apiUrl.owner}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.location}/${apiUrl.owner}`;
      },
      transformResponse: (response) => {
        const owners = response.data?.owners;
        const pagination = response?.pagination;
        return { owners, pagination };
      },
      providesTags: ['LocationOwner'],
    }),
    addLocationOwner: build.mutation({
      query: (data) => ({
        url: `${apiUrl.location}/${apiUrl.owner}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['LocationOwner'],
    }),
    getLocationOwnerById: build.query({
      query: (id) => `${apiUrl.location}/${apiUrl.owner}/${id}`,
      providesTags: ['LocationOwner'],
      transformResponse: (response) => response.data,
    }),
    updateLocationOwnerById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.location}/${apiUrl.owner}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['LocationOwner'],
    }),
    deleteLocationOwnerById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.location}/${apiUrl.owner}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LocationOwner'],
    }),
    getAllLocationOwnerTypes: build.query({
      query: () => `${apiUrl.locationOwnerTypes}`,
      transformResponse: (response) => response.data?.locationOwnerTypes,
    }),
    updateLocationById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.location}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Locations', 'Location'],
    }),
    getOwnerInfoByLocationId: build.query({
      query: (id) => {
        if (id) {
          return `${apiUrl.location}/${id}/${apiUrl.ownerInfo}`;
        }
      },
      transformResponse: (response) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetLocationDetailsByIdQuery,
  useAddLocationMutation,
  useGetAllLocationOwnersQuery,
  useLazyGetAllLocationOwnersQuery,
  useAddLocationOwnerMutation,
  useGetLocationOwnerByIdQuery,
  useUpdateLocationOwnerByIdMutation,
  useDeleteLocationOwnerByIdMutation,
  useGetAllLocationTypesQuery,
  useLazyGetAllLocationTypesQuery,
  useUpdateLocationByIdMutation,
  useGetOwnerInfoByLocationIdQuery,
  useGetAllLocationOwnerTypesQuery,
  useDeleteLocationByIdMutation,
} = extendedApi;
