import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { Button, CheckBox, ControlledTooltips, Link, Modal, NoContent, ReactTable } from '../../../components/shared';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { MESSAGE } from '../../../constants/message';
import { COLUMNS } from '../../../constants/tableColumns';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetAllCollectionsQuery } from '../../../services/CollectionService/CollectionService';
import { COLLECTION_RECONCILIATION_SUBMITTED } from '../../../constants/CommonConstants';
import {
  checkObjectNotEmpty,
  getControlledTooltipValueAndCellValue,
  roundOffValue,
} from '../../../utils/common-methods';
import useStyles from '../style';
import { setReconciliationFilters } from '../../../redux-slice/collectionFilters';
import { useDispatch } from 'react-redux';

export default function SubmittedCollectionsTable({
  searchParams,
  reconciliation,
  allSelectedCollection = {},
  onCollectionSelectionChange = () => {},
  onCancelPress = () => {},
  onApprovePress = () => {},
}) {
  const hideCheckbox = true;
  const classes = useStyles();
  const [selectedCollection, setSelectedCollection] = useState({});
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const viewProductTypes = useCallback((productTypeNames) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypeNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const COLLECTION_LOCATIONS_COLUMNS = () => {
    const checkboxColumn = {
      Header: () => (
        <CheckBox
          onChange={(e) => onCheckAllSelect(e.target.checked)}
          checked={isCheckedAll}
          indeterminate={isIndeterminate}
        />
      ),
      id: 'selection',
      Cell: ({ row }) => (
        <CheckBox
          onChange={(e) => onRowSelect(row.original, e.target.checked)}
          checked={selectedCollection[row.original.transactionId]}
          disabled={row?.original?.hasError}
        />
      ),
      width: 50,
    };

    const COLLECTION_COLUMNS = [
      ...COLUMNS.SUBMITTED_COLLECTIONS_LOCATIONS_TABLE,
      {
        Header: () => null,
        id: 'warning',
        Cell: ({ row }) => {
          // Show error Icon on Reconciliation tab list
          if (row?.original?.hasError) {
            return (
              <ControlledTooltips tooltipValue={MESSAGE.RECONCILIATION_TOOLTIP_WARNING}>
                <Warning color="error" />
              </ControlledTooltips>
            );
          }
        },
        width: 56,
      },
    ];

    return hideCheckbox ? COLLECTION_COLUMNS : [checkboxColumn, ...COLLECTION_COLUMNS];
  };

  const reconciliationLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`${routeConstants.RECONCILIATION_COLLECTION_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );
  const [currentPage, setCurrentPage] = useState(reconciliation?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(reconciliation?.rowsPerPage);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const dispatch = useDispatch();

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetAllCollectionsQuery({
    searchParams,
    page: currentPage,
    perPage: perPageNumber,
    status: COLLECTION_RECONCILIATION_SUBMITTED,
  });

  useEffect(() => {
    refetch();
  }, []);

  const tableData = useMemo(() => {
    if (isSuccess && latestData) {
      const rawData = latestData?.collections;
      return rawData.map((data) => {
        const transformedData = { ...data };
        transformedData.locationName = reconciliationLink(data.transactionId, data?.location?.locationName);
        transformedData.locationType = data?.location?.locationType?.name;
        transformedData.locationRevenue = roundOffValue(data?.locationRevenue);
        transformedData.hasError = data.hasException;
        transformedData.productTypes = viewProductTypes(data?.productTypes);
        return transformedData;
      });
    }
  }, [isSuccess, latestData]);

  const totalCount = useMemo(() => {
    if (isSuccess && latestData) {
      return latestData?.pagination?.total ?? 0;
    }
    return 0;
  }, [isSuccess, latestData]);

  useEffect(() => {
    const initialSelectedCollection = {};
    tableData?.map((item) => {
      if (item.hasError) return;
      initialSelectedCollection[item.transactionId] = allSelectedCollection[item.transactionId] || false;
    });
    const isIndeterminate = checkIndeterminate(initialSelectedCollection);
    setIsIndeterminate(isIndeterminate);
    const isAllTrue = checkObjectNotEmpty(initialSelectedCollection)
      ? checkIsAllTrue(initialSelectedCollection)
      : false;
    setSelectedCollection(initialSelectedCollection);
    setIsCheckedAll(isAllTrue ? true : false);
  }, [tableData]);

  useEffect(() => {
    onCollectionSelectionChange(selectedCollection);
  }, [selectedCollection]);

  useEffect(() => {
    setCurrentPage(reconciliation?.pageNumber);
    setPerPageNumber(reconciliation?.rowsPerPage);
  }, [searchParams]);

  const checkIsAllTrue = (selectedCollection) => Object.values(selectedCollection)?.every((item) => item === true);
  const checkIsAllFalse = (selectedCollection) => Object.values(selectedCollection)?.every((item) => item === false);

  const checkIndeterminate = (selectedCollection) => {
    const isAllTrue = checkIsAllTrue(selectedCollection);
    const isAllFalse = checkIsAllFalse(selectedCollection);
    return isAllFalse || isAllTrue ? false : true;
  };

  const onCheckAllSelect = (checked) => {
    const updatedSelectedCollection = { ...selectedCollection };
    Object.keys(updatedSelectedCollection).map((item) => {
      updatedSelectedCollection[item] = checked;
    });
    setIsIndeterminate(false);
    setSelectedCollection(updatedSelectedCollection);
    setIsCheckedAll(checked);
  };

  const onRowSelect = (row, checked) => {
    const updatedSelectedCollection = { ...selectedCollection, [row.transactionId]: checked };
    const isAllTrue = checkIsAllTrue(updatedSelectedCollection);
    const isAllFalse = checkIsAllFalse(updatedSelectedCollection);
    setSelectedCollection(updatedSelectedCollection);
    setIsCheckedAll(isAllTrue ? true : false);
    setIsIndeterminate(isAllFalse || isAllTrue ? false : true);
  };

  const pageChangeHandler = (currentPage) => {
    dispatch(setReconciliationFilters({ ...reconciliation, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(setReconciliationFilters({ ...reconciliation, rowsPerPage: newPerPage, pageNumber: currentPage }));
    setPerPageNumber(newPerPage);
  };

  const approveDisable = useMemo(
    () => Object.values(allSelectedCollection).some((item) => item === true),
    [allSelectedCollection],
  );

  const resetSelections = () => {
    setSelectedCollection({});
    setIsCheckedAll(false);
    setIsIndeterminate(false);
    setShowApproveModal(false);
  };

  const onCancelButtonPress = () => {
    resetSelections();
    onCancelPress();
  };

  const onApproveButtonPress = () => {
    resetSelections();
    onApprovePress();
  };

  const BottomExtraBtn = () => (
    <Box sx={{ marginLeft: 3 }}>
      <Button onClick={() => setShowApproveModal(true)} variant="contained" disabled={!approveDisable}>
        APPROVE
      </Button>
      <Button onClick={onCancelButtonPress}>CANCEL</Button>
    </Box>
  );

  return (
    <Box className={classes.initialTableCheckbox}>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Collection Found"
          desc={(isError && error?.data?.message) || <>There are no collection present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={COLLECTION_LOCATIONS_COLUMNS()}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          bottomExtraBtn={hideCheckbox ? null : <BottomExtraBtn />}
        />
      )}
      <Modal
        title={MESSAGE.APPROVE_BULK_COLLECTIONS}
        titleVariant="subtitle10"
        open={showApproveModal}
        actionButtons={
          <Box my={3}>
            <Button variant="contained" size="large" onClick={onApproveButtonPress}>
              {MESSAGE.APPROVE}
            </Button>
            <Button size="large" onClick={() => setShowApproveModal(false)}>
              {MESSAGE.CANCEL}
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
