import { makeStyles } from '@mui/styles';
import { theme } from '../../theme';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 280,
    marginTop: -25,
    padding: 16,
    boxShadow: '0px 1px 4px rgba(100, 116, 139, 0.24)',
  },
  commentHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: '2px solid rgba(100, 116, 139, 0.24)',
    marginBottom: theme.spacing(1),
  },
  commentList: {
    maxHeight: '110px',
    overflowY: 'auto',
    marginBottom: 16,
  },
  commentListItem: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: 16,
    padding: 3.5,
    borderRadius: 7,
  },
  commentAvatar: {
    marginRight: 12,
  },
  commentContent: {
    flex: 1,
  },
  commentContentHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentAuthorName: {
    fontWeight: 'bold',
  },
  commentActionButtons: {
    display: 'flex',
    gap: 8,
  },
  commentCreationDate: {
    color: 'gray',
    marginBottom: 8,
  },
  commentText: {
    textTransform: 'none',
  },
  editingComment: {
    backgroundColor: theme.palette.primary.lighter,
    transition: 'font-weight 0.3s ease',
  },
  boldText: {
    fontWeight: 'bold',
  },
  editModeInput: {
    border: '2px solid transparent',
    animation: '$borderAnimation 2.8s infinite',
    borderRadius: '4px',
    transition: 'border-color 0.3s ease-in-out',
    '&:hover, &:focus': {
      borderColor: '#3f51b5',
    },
  },
  '@keyframes borderAnimation': {
    '0%': {
      borderColor: 'transparent',
    },
    '50%': {
      borderColor: '#3f51b5',
    },
    '100%': {
      borderColor: 'transparent',
    },
  },
}));

export default useStyles;
