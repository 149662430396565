import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Avatar, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import useStyles from './style';

const propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    createdByUser: PropTypes.string,
  }).isRequired,
  editMode: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const Comment = ({ comment, editMode, onEdit, onDelete, userDetails }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.commentListItem} ${editMode ? classes.editingComment : ''}`}>
      <Avatar className={classes.commentAvatar}>{comment.name.charAt(0)}</Avatar>
      <Box className={classes.commentContent}>
        <Box className={classes.commentContentHead}>
          <Typography className={classes.commentAuthorName} variant="body1">
            {comment.name}
          </Typography>
          {/* Edit/Delete actions only available to comment owner */}
          {(comment.id === null || comment.id === undefined || comment.createdByUser === userDetails.id) && (
            <Box className={classes.commentActionButtons}>
              <IconButton size="small" onClick={onEdit} disabled={editMode}>
                <Edit fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => onDelete(comment.id, comment.comment)} disabled={editMode}>
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Typography variant="body2" className={classes.commentCreationDate}>
          {comment.createdAt}
        </Typography>
        <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
          {comment.comment}
        </Typography>
        {comment.id === null || comment.id === undefined ? (
          <Tooltip title={'Comment not been saved yet.'}>
            <ErrorOutlineIcon style={{ fontSize: '18px' }} />
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};

Comment.propTypes = propTypes;
export default Comment;
