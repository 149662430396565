import { useEffect, useState, useMemo } from 'react';
import { Box, Chip, Typography, Stack, Paper } from '@mui/material';
import { Breadcrumbs, Accordion, GridContainer, GridItem, PositionedMenu, NoContent } from '../../../components/shared';
import { NavigateNext } from '@mui/icons-material';
import useStyles from './style';
import SublocationTable from './SublocationTable';
import LocationDetailAccordion from './LocationDetailAccordion';
import OwnerUpdateDetailsView from './OwnerUpdateDetailsView';
import {
  useGetLocationDetailsByIdQuery,
  useUpdateLocationByIdMutation,
} from '../../../services/Location/LocationService';
import {
  useAddSubLocationMutation,
  useUpdateSubLocationByIdMutation,
} from '../../../services/Sublocation/SublocationService';
import { useNavigate, useParams } from 'react-router-dom';
import SubLocationUpdateDetailView from './SubLocationUpdateDetailView';

import { routeConstants } from '../../../constants/routeConstants';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import LocationOwnerDetailAccordion from '../../../components/LocationOwnerDetailAccordion';
import { mapMarkerIcon } from '../../../assets/images/icons';
import LeaseTable from './LeaseTable';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';

const createLocDetToSubLocObjFunc = (locationDetails = {}) => ({
  locationName: locationDetails?.locationName?.trim() || '',
  locationCountry: locationDetails?.country?.name?.trim() || '',
  locationState: locationDetails?.state?.name?.trim() || '',
  locationCity: locationDetails?.city?.name?.trim() || '',
});

const LocationDetails = () => {
  const {
    LOCATION: { LOCATION, LOCATION_DETAIL },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` }, { text: LOCATION_DETAIL }],
    [],
  );
  const searchParams = 'includeSublocationAssets=true&includeSublocationManagers=true&includeContracts=true';
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const canEdit = useHasRoles([
    ROLE.BUSDEV_ADMINISTRATION,
    ROLE.SUPER_ADMIN_IT,
    ROLE.CUST_SUPPORT,
    ROLE.SALES,
    ROLE.ACCOUNTING,
  ]);
  const {
    isSuccess,
    currentData: currentLocationDetails,
    isLoading,
    isFetching,
  } = useGetLocationDetailsByIdQuery({ id: Number(params.id), searchParams });
  const [addNewSubLocation, { isLoading: subLocationLoading }] = useAddSubLocationMutation();
  const [updateSubLocationApi, updateSubLocationResult] = useUpdateSubLocationByIdMutation();
  const [updateLocationApi] = useUpdateLocationByIdMutation();
  const [isUpdateOwner, setIsUpdateOwner] = useState(false);
  const [showLocationOwner, setShowLocationOwner] = useState(false);
  const [isUpdateSublocationDetail, setIsUpdateSublocationDetail] = useState(false);
  const [updateSublocation, setUpdateSublocation] = useState({});
  const [prefillLocDetailForSubLocAdd, setPrefillLocDetailForSubLocAdd] = useState({});
  const [openAddNewSublocation, setOpenAddNewSublocation] = useState(false);
  const [locationDetails, setLocationDetails] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setLocationDetails(currentLocationDetails?.data);
    }
  }, [currentLocationDetails, isSuccess]);

  const onSaveOwnerDetailHandler = () => {
    setIsUpdateOwner(false);
    setShowLocationOwner(true);
  };

  const onCancelUpdateOwnerDetailHandler = () => {
    setLocationDetails(locationDetails);
    setIsUpdateOwner(false);
  };

  const updateSublocationDetailHandler = (data) => {
    const locationDetailsToSubLoc = createLocDetToSubLocObjFunc(currentLocationDetails?.data);
    setIsUpdateSublocationDetail(true);
    setUpdateSublocation({ ...data, ...locationDetailsToSubLoc });
  };

  const removeSublocationDetailHandler = async (data) => {
    const status = data.status || false;
    await updateSubLocationApi({ id: data.id, status: !status });
  };

  const saveSublocationDetailChangeHandler = async (data) => {
    // API to integrate new SublocationDetail changes
    await updateSubLocationApi(data);
    setIsUpdateSublocationDetail(false);
  };

  const addSublocationDetailChangeHandler = async (data) => {
    // API to add new SublocationDetail changes
    data.locationId = locationDetails?.id;
    await addNewSubLocation(data);
    setOpenAddNewSublocation(false);
  };

  const onAddNewBtnClick = () => {
    const locationDetailsToSubLoc = createLocDetToSubLocObjFunc(locationDetails);
    setPrefillLocDetailForSubLocAdd(locationDetailsToSubLoc);
    setOpenAddNewSublocation(true);
  };
  const editLocationDetailHandler = () => {
    navigate(`/${routeConstants.LOCATION_ROUTE}/${routeConstants.EDIT_LOCATION_ROUTE}/${Number(params.id)}`);
  };

  const archiveHandler = () => {
    setLoading(true);
    const status = locationDetails?.status;
    const archiveData = {
      id: params.id,
      status: !status,
    };
    updateLocationApi(archiveData).then((response) => {
      setLoading(false);
      if (response.data) {
        navigate(`/${routeConstants.LOCATION_ROUTE}?archive=${status}`);
      }
    });
    setShowArchiveModal(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>

      <Box mb={4}>
        <Stack direction={'row'} alignItems="center" spacing={1.5}>
          <Typography variant="h1" data-testid="locationName">
            {locationDetails?.locationName}
          </Typography>
          <Chip
            label={locationDetails?.status ? 'Active' : 'Inactive'}
            color={locationDetails?.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
      </Box>
      <Box mb={3}>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title={'Location Details'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <LocationDetailAccordion locationInfo={locationDetails} locationDetailsView={true} />
            )
          }
          showMore
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: editLocationDetailHandler,
                },
                {
                  btnName: locationDetails?.status ? 'Archive Location' : 'Activate Location',
                  btnClickFunc: () => setShowArchiveModal(true),
                  btnTypeStyle: locationDetails?.status ? 'dangerBtn' : 'successBtn',
                },
              ]}
              disabled={!canEdit}
            />
          }
        />
      </Box>
      <Box mb={3}>
        {!isUpdateOwner && (
          <Accordion
            title={'Location Owner'}
            defaultExpanded={showLocationOwner}
            content={
              isLoading || isFetching ? (
                <LoadingSpinner />
              ) : (
                <LocationOwnerDetailAccordion ownerInfo={locationDetails?.owner} />
              )
            }
            showMore={!isUpdateOwner}
            subComponent={
              <PositionedMenu
                menuItemData={[
                  {
                    btnName: 'Edit',
                    btnClickFunc: () => setIsUpdateOwner(true),
                  },
                ]}
                disabled={!canEdit}
              />
            }
          />
        )}
        {isUpdateOwner && (
          <Accordion
            title={'Edit Location Owner'}
            defaultExpanded
            content={
              <OwnerUpdateDetailsView
                locationId={locationDetails?.id}
                ownerInfo={locationDetails?.owner}
                onCancel={onCancelUpdateOwnerDetailHandler}
                onSave={onSaveOwnerDetailHandler}
              />
            }
            showMore={!isUpdateOwner}
            onUpdate={() => setIsUpdateOwner(true)}
          />
        )}
      </Box>
      <Box component={Paper} mb={3} className={classes.subLocationTable}>
        {isLoading || isFetching ? (
          <LoadingSpinner />
        ) : locationDetails?.contracts && locationDetails?.contracts?.length > 0 ? (
          <>
            <GridContainer pt={3} px={3} pb={2} justifyContent="space-between" alignItems="center">
              <GridItem xs={7} md={8}>
                <Typography variant="h2">Leases</Typography>
              </GridItem>
            </GridContainer>
            <Box>
              <LeaseTable locationDetails={locationDetails} disabled={!canEdit} />
            </Box>
          </>
        ) : (
          <NoContent
            icon={mapMarkerIcon}
            title="No Leases"
            desc={
              <>
                There are no Leases added to this location <br />
                Please add new Lease
              </>
            }
            buttonName="ADD NEW"
            disabled={!canEdit}
            onBtnClickHandler={() => navigate(`/${routeConstants.ADD_LEASE_ROUTE}`)}
          />
        )}
      </Box>

      <Box component={Paper} className={classes.subLocationBox}>
        {/* update sublocation details */}
        {isUpdateSublocationDetail && (
          <SubLocationUpdateDetailView
            subLocationDetails={updateSublocation}
            onSave={saveSublocationDetailChangeHandler}
            onCancel={() => setIsUpdateSublocationDetail(false)}
            loadingBtnUse
            isLoading={updateSubLocationResult?.isLoading}
          />
        )}
        {/* add sublocation details */}
        {openAddNewSublocation && (
          <SubLocationUpdateDetailView
            subLocationDetails={prefillLocDetailForSubLocAdd}
            onSave={addSublocationDetailChangeHandler}
            onCancel={() => setOpenAddNewSublocation(false)}
            isAddNewSubLoc
            loadingBtnUse
            isLoading={subLocationLoading}
          />
        )}

        {!isUpdateSublocationDetail && !openAddNewSublocation && (
          <>
            {locationDetails?.sublocations?.length > 0 && (
              <GridContainer pt={3} px={3} pb={2} justifyContent="space-between" alignItems="center">
                <GridItem xs={7} md={8}>
                  <Typography variant="h2">Sub Locations</Typography>
                </GridItem>
              </GridContainer>
            )}
            <Box className={classes.subLocationTable}>
              {isLoading || isFetching ? (
                <LoadingSpinner />
              ) : locationDetails?.sublocations?.length > 0 ? (
                <SublocationTable
                  locationDetails={locationDetails}
                  onUpdateSublocationDetail={updateSublocationDetailHandler}
                  onRemoveSublocationDetail={removeSublocationDetailHandler}
                  onAddNewBtnClick={onAddNewBtnClick}
                  disabled={!canEdit}
                />
              ) : (
                <NoContent
                  icon={mapMarkerIcon}
                  title="No Sub Locations"
                  desc={
                    <>
                      There are no sub locations added to this location <br />
                      Please add new sub location
                    </>
                  }
                  buttonName="ADD NEW"
                  onBtnClickHandler={onAddNewBtnClick}
                  disabled={!canEdit}
                />
              )}
            </Box>
          </>
        )}
      </Box>
      <ConfirmationModal
        isOpen={showArchiveModal}
        isLoading={loading}
        title="Confirmation"
        msg={`Are you sure you want to ${
          locationDetails?.status ? 'archive' : 'active'
        }  this location? This process cannot be undone.`}
        buttons={[
          { text: locationDetails?.status ? 'ARCHIVE' : 'ACTIVATE', value: true },
          { text: 'Cancel', value: false },
        ]}
        onClick={(value) => {
          if (value) archiveHandler();
          else setShowArchiveModal(false);
        }}
      />
    </Box>
  );
};

export default LocationDetails;
