import reconciliationCollectionSlice from './reconciliationCollection-slice';
export const {
  setCollectionCollected,
  setTransformedSublocations,
  setRevenueMeterData,
  setTotalPrizeDispensed,
  setTotalTokenCollected,
  setTotalTicketDispensed,
  setTotalTokenChangerRevenueValue,
  setSubTotalRevenues,
  setReconciliationWarningForModal,
  setFormikData,
  setLocationCurrency,
  resetState,
  setAccountSummaryData,
  setAccountSummaryError,
  setIntercardDataForReconciliation,
  setSublocationDatesForReconciliation,
  setAccountSummarySearchState,
  setAccountSummaryFormDirty,
  setTotalTokenDispensed,
  setTaxExempt,
  setLocationPaysTax,
  setUpdateStatesForReconciliationSublocationDatesChange,
  setDefaultAccountSummaryState,
  setReadFromDraftAccountSummary,
  setStoredAccountSummaryData,
  setRerender,
} = reconciliationCollectionSlice.actions;

export default reconciliationCollectionSlice;
