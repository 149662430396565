import { Box } from '@mui/material';
import React from 'react';
import SearchBar from '../../../components/SearchBar';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import SORT_CONSTANT, { SUBLOCATIONNAME_ASC } from '../../../constants/SortConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import { sortIcon } from '../../../assets/images/icons';
import { useSearchFilterParam } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../style';
import { ControlledTooltips, NoContent, ReactTable } from '../../../components/shared';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { COLUMNS } from '../../../constants/tableColumns';
import { useGetSubmittedCollectionsForSubLocationsQuery } from '../../../services/CollectionService/CollectionService';
import {
  resetSubLocationSubmittedCollectionFiltersState,
  setSubLocationSubmittedCollectionFilters,
} from '../../../redux-slice/submittedFilters';
import { getControlledTooltipValueAndCellValue, getOptions } from '../../../utils/common-methods';
import { COLLECTION_STATUS } from './data';
import { useLazyGetProductCategoriesQuery } from '../../../services/Sublocation/SublocationService';

const SubLocationSubmittedCollection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const { userDetails } = useSelector((state) => state.user);
  const { subLocationSubmittedCollection } = useSelector((state) => state.submittedCollectionFilter);

  const [filterParams, setFilterParams] = React.useState(FILTER_DATA.SUB_LOCATION_COLLECTION_SUBMITTED.INITIAL_PARAMS);
  const [searchText, setSearchText] = React.useState('');
  const [sort, setSort] = React.useState(SUBLOCATIONNAME_ASC);
  const [currentPage, setCurrentPage] = React.useState(subLocationSubmittedCollection?.pageNumber);
  const [perPageNumber, setPerPageNumber] = React.useState(subLocationSubmittedCollection?.rowsPerPage);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.SUB_LOCATION_COLLECTION_SUBMITTED,
    searchText,
    filterParams,
    sort,
  });

  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();
  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetSubmittedCollectionsForSubLocationsQuery({
    searchParams,
    page: currentPage,
    perPage: perPageNumber,
  });

  const viewProductTypes = React.useCallback((productTypeNames) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypeNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  React.useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
      [FILTER_VALUES.LOCATION]: [],
      [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
      [FILTER_VALUES.COLLECTION_STATUS]: [],
      [FILTER_VALUES.PRODUCT_TYPE]: [],
    };
    if (
      subLocationSubmittedCollection[FILTER_VALUES.DATE_RANGE] &&
      subLocationSubmittedCollection[FILTER_VALUES.DATE_RANGE]?.from &&
      subLocationSubmittedCollection[FILTER_VALUES.DATE_RANGE]?.to
    ) {
      storedFilterParams[FILTER_VALUES.DATE_RANGE] = subLocationSubmittedCollection[FILTER_VALUES.DATE_RANGE];
    }
    if (
      subLocationSubmittedCollection[FILTER_VALUES.LOCATION] &&
      subLocationSubmittedCollection[FILTER_VALUES.LOCATION]?.length > 0
    ) {
      storedFilterParams[FILTER_VALUES.LOCATION] = subLocationSubmittedCollection[FILTER_VALUES.LOCATION];
    }
    if (
      subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] &&
      subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION]?.length > 0
    ) {
      storedFilterParams[FILTER_VALUES.COLLECTION_SUBLOCATION] =
        subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION];
    }
    if (
      subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_STATUS] &&
      subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_STATUS]?.length > 0
    ) {
      storedFilterParams[FILTER_VALUES.COLLECTION_STATUS] =
        subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_STATUS];
    }
    if (
      subLocationSubmittedCollection[FILTER_VALUES.PRODUCT_TYPE] &&
      subLocationSubmittedCollection[FILTER_VALUES.PRODUCT_TYPE]?.length > 0
    ) {
      storedFilterParams[FILTER_VALUES.PRODUCT_TYPE] = subLocationSubmittedCollection[FILTER_VALUES.PRODUCT_TYPE];
    }
    setSearchText(subLocationSubmittedCollection.searchText);
    setSort(subLocationSubmittedCollection.sort);
    setFilterParams(storedFilterParams);
  }, [subLocationSubmittedCollection]);

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setSubLocationSubmittedCollectionFilters({
        ...subLocationSubmittedCollection,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const getCustomParams = (value) => {
    const sublocationsArray = userDetails?.locations.flatMap((location) => location.sublocations) || [];
    const locationsArray =
      userDetails?.locations.map(({ locationName, ...rest }) => ({
        name: locationName,
        ...rest,
      })) || [];
    switch (value) {
      case FILTER_VALUES.LOCATION:
        return getOptions(locationsArray);
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return getOptions(sublocationsArray);
      case FILTER_VALUES.COLLECTION_STATUS:
        return COLLECTION_STATUS;

      default:
        return [];
    }
  };

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.PRODUCT_TYPE:
        return { api: getAllProductTypes };
      default:
        return [];
    }
  };

  const filterData = React.useMemo(
    () =>
      FILTER_DATA.SUB_LOCATION_COLLECTION_SUBMITTED.FILTER.map((item) => {
        const api = getAPI(item.value);

        return {
          ...item,
          ...api,
          options: getCustomParams(item.value),
        };
      }),
    [],
  );

  const handleSort = (e) => {
    dispatch(
      setSubLocationSubmittedCollectionFilters({
        ...subLocationSubmittedCollection,
        sort: e.target.value,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setSort(e.target.value);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.SUB_LOCATION_COLLECTION_SUBMITTED.INITIAL_PARAMS);
    dispatch(resetSubLocationSubmittedCollectionFiltersState());
  };

  const onApplyFilterHandler = (v) => {
    dispatch(
      setSubLocationSubmittedCollectionFilters({
        ...subLocationSubmittedCollection,
        ...v,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(v);
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setSubLocationSubmittedCollectionFilters({
        ...subLocationSubmittedCollection,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const tableData = React.useMemo(() => {
    if (isSuccess && latestData) {
      const rawData = latestData?.data?.collections;
      return rawData.map((data) => {
        const transformedData = { ...data };
        transformedData.sublocationName = transformedData?.sublocation?.name;
        transformedData.locationName = transformedData?.sublocation?.location?.locationName;
        transformedData.productTypes = viewProductTypes(
          transformedData?.sublocation?.productCategories
            ? transformedData?.sublocation?.productCategories?.map((value) => value.name)
            : [],
        );
        return transformedData;
      });
    }
  }, [isSuccess, latestData]);

  const totalCount = React.useMemo(() => {
    if (isSuccess && latestData) {
      return latestData?.pagination?.total ?? 0;
    }
    return 0;
  }, [isSuccess, latestData]);

  React.useEffect(() => {
    setCurrentPage(subLocationSubmittedCollection?.pageNumber);
    setPerPageNumber(subLocationSubmittedCollection?.rowsPerPage);
  }, [searchParams]);

  React.useEffect(() => {
    dispatch(
      setSubLocationSubmittedCollectionFilters({
        ...subLocationSubmittedCollection,
        pageNumber: currentPage,
        rowsPerPage: perPageNumber,
      }),
    );
  }, [currentPage, perPageNumber]);

  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  return (
    <React.Fragment>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={searchHandler}
            placeholder={'Search by Sub Location'}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>
        <Box px={3}>
          <NewFilterDrawer
            name={TITLE.SORT_BY.toUpperCase()}
            title={TITLE.SORT_BY}
            drawerType={DRAWER_TYPES.SORT_DRAWER}
            startIcon={<>{sortIcon}</>}
            onSortSelect={handleSort}
            selectedSort={sort}
            sortData={SORT_CONSTANT.SUBMITTED_SUB_LOCATION_COLLECTION_SORT}
          />
        </Box>
        <Box>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={onApplyFilterHandler}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.SUB_LOCATION_COLLECTION_SUBMITTED.INITIAL_PARAMS)?.length}
          />
        </Box>
      </Box>

      {tableData && (
        <ReactTable
          data={tableData}
          columns={COLUMNS.SUBMITTED_COLLECTIONS_FOR_SUBLOCATIONS_TABLE}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
        />
      )}
      {isError && (
        <NoContent
          title="No Collection Found"
          desc={(isError && error?.data?.message) || <>There are no collection present with this filter.</>}
        />
      )}
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
    </React.Fragment>
  );
};

export default SubLocationSubmittedCollection;
