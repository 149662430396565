import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { SUBLOCATIONNAME_ASC, LOCATIONNAME_ASC } from '../../constants/SortConstants';

const initialState = {
  subLocationSubmittedCollection: {
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
    [FILTER_VALUES.COLLECTION_STATUS]: [],
    [FILTER_VALUES.PRODUCT_TYPE]: [],
    sort: SUBLOCATIONNAME_ASC,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
  locationSubmittedCollection: {
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
    [FILTER_VALUES.COLLECTION_STATUS]: [],
    sort: LOCATIONNAME_ASC,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const submittedCollectionSlice = createSlice({
  name: 'collectionFilter',
  initialState,
  reducers: {
    setSubLocationSubmittedCollectionFilters(state, action) {
      state.subLocationSubmittedCollection[FILTER_VALUES.DATE_RANGE] = action.payload?.[FILTER_VALUES.DATE_RANGE] || {
        from: '',
        to: '',
      };
      state.subLocationSubmittedCollection[FILTER_VALUES.LOCATION] = action.payload?.[FILTER_VALUES.LOCATION] || [];
      state.subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] =
        action.payload?.[FILTER_VALUES.COLLECTION_SUBLOCATION] || [];
      state.subLocationSubmittedCollection[FILTER_VALUES.COLLECTION_STATUS] =
        action.payload?.[FILTER_VALUES.COLLECTION_STATUS] || [];
      state.subLocationSubmittedCollection[FILTER_VALUES.PRODUCT_TYPE] =
        action.payload?.[FILTER_VALUES.PRODUCT_TYPE] || [];
      state.subLocationSubmittedCollection.sort = action.payload?.sort || '';
      state.subLocationSubmittedCollection.searchText = action.payload?.searchText || '';
      state.subLocationSubmittedCollection.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.subLocationSubmittedCollection.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetSubLocationSubmittedCollectionFiltersState(state) {
      state.subLocationSubmittedCollection = {
        ...initialState.subLocationSubmittedCollection,
        searchText: state.subLocationSubmittedCollection.searchText,
      };
    },
    setLocationSubmittedCollectionFilters(state, action) {
      state.locationSubmittedCollection[FILTER_VALUES.DATE_RANGE] = action.payload?.[FILTER_VALUES.DATE_RANGE] || {
        from: '',
        to: '',
      };
      state.locationSubmittedCollection[FILTER_VALUES.LOCATION] = action.payload?.[FILTER_VALUES.LOCATION] || [];
      state.locationSubmittedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] =
        action.payload?.[FILTER_VALUES.COLLECTION_SUBLOCATION] || [];
      state.locationSubmittedCollection[FILTER_VALUES.COLLECTION_STATUS] =
        action.payload?.[FILTER_VALUES.COLLECTION_STATUS] || [];
      state.locationSubmittedCollection.sort = action.payload?.sort || '';
      state.locationSubmittedCollection.searchText = action.payload?.searchText || '';
      state.locationSubmittedCollection.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.locationSubmittedCollection.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetLocationSubmittedCollectionFiltersState(state) {
      state.locationSubmittedCollection = {
        ...initialState.locationSubmittedCollection,
        searchText: state.locationSubmittedCollection.searchText,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default submittedCollectionSlice;
