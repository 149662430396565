import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import useStyles from '../style';

import { DEFAULT_PER_PAGE, DEFAULT_PAGE } from '../../../constants/pagination';
import FILTER_CONSTANTS, { FILTER_VALUES, FILTER_ACCOUNTING_ROLE } from '../../../constants/FilterConstants';
import { ROLE } from '../../../constants/roles';

import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import ApprovedCollectionTable from './ApprovedCollectionTable';
import { useLazyGetAllLocationTypesQuery, useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import { useLazyGetUsersForSelectQuery } from '../../../services/Users/UsersService';
import { useHasRoles, useSearchFilterParam } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetApprovedCollectionFiltersState,
  setApprovedCollectionFilters,
} from '../../../redux-slice/collectionFilters';
import { getUserName } from '../../../utils/common-methods';
import {
  useLazyGetAllSublocationsQuery,
  useLazyGetProductCategoriesQuery,
} from '../../../services/Sublocation/SublocationService';

const displayName = 'Approved Collections';
const ApprovedCollection = () => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [searchText, setSearchText] = useState('');
  const [noDefaultFilter, setNoDefaultFilter] = useState(false);

  const isAccounting = useHasRoles([ROLE.ACCOUNTING]);
  const dispatch = useDispatch();
  const { approvedCollection } = useSelector((state) => state.collectionFilter);
  const { userDetails } = useSelector((state) => state.user);

  const COLLECTION_RECONCILIATION = {
    INITIAL_PARAMS: isAccounting
      ? {
          ...FILTER_DATA.COLLECTION_RECONCILIATION.INITIAL_PARAMS,
          ...FILTER_DATA.COLLECTION_RECONCILIATION.ACCOUNTING_INITIAL_PARAMS,
        }
      : FILTER_DATA.COLLECTION_RECONCILIATION.INITIAL_PARAMS,
    FILTER: isAccounting
      ? [...FILTER_DATA.COLLECTION_RECONCILIATION.FILTER, ...FILTER_DATA.COLLECTION_RECONCILIATION.ACCOUNTING_FILTER]
      : FILTER_DATA.COLLECTION_RECONCILIATION.FILTER,
  };
  const [filterParams, setFilterParams] = useState(COLLECTION_RECONCILIATION.INITIAL_PARAMS);

  const [getLocations] = useLazyGetLocationsQuery();
  const [getAllLocationTypes] = useLazyGetAllLocationTypesQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getAccountingUsers] = useLazyGetUsersForSelectQuery();
  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();
  const [getUsersForSelect] = useLazyGetUsersForSelectQuery();

  const getCustomParams = (value) => {
    switch (value) {
      case FILTER_VALUES.LOCATION:
        return { api: getLocations };
      case FILTER_VALUES.LOCATION_TYPE:
        return { api: getAllLocationTypes };
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return { api: getAllSublocations, customParam: 'status=true' };
      case FILTER_VALUES.ACCOUNTING_USER:
        return { api: getAccountingUsers, customParam: `role=${FILTER_ACCOUNTING_ROLE}` };
      case FILTER_VALUES.PRODUCT_TYPE:
        return { api: getAllProductTypes };
      case FILTER_VALUES.USER:
        return { api: getUsersForSelect };
      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      COLLECTION_RECONCILIATION.FILTER.map((item) => {
        const params = getCustomParams(item.value);

        return {
          ...item,
          ...params,
        };
      }),
    [],
  );

  useEffect(() => {
    const storedFilterParams = isAccounting
      ? {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.LOCATION_TYPE]: [],
          [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.ACCOUNTING_USER]: [],
        }
      : {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.LOCATION_TYPE]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        };
    if (approvedCollection[FILTER_VALUES.LOCATION] && approvedCollection[FILTER_VALUES.LOCATION]?.length > 0) {
      storedFilterParams.locationId = approvedCollection[FILTER_VALUES.LOCATION];
    }
    if (
      approvedCollection[FILTER_VALUES.LOCATION_TYPE] &&
      approvedCollection[FILTER_VALUES.LOCATION_TYPE]?.length > 0
    ) {
      storedFilterParams.locationType = approvedCollection[FILTER_VALUES.LOCATION_TYPE];
    }
    if (
      approvedCollection[FILTER_VALUES.DATE_RANGE] &&
      approvedCollection[FILTER_VALUES.DATE_RANGE]?.from &&
      approvedCollection[FILTER_VALUES.DATE_RANGE]?.to
    ) {
      storedFilterParams.dateRange = approvedCollection[FILTER_VALUES.DATE_RANGE];
    }
    if (
      approvedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] &&
      approvedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION]?.length > 0
    ) {
      storedFilterParams.sublocation = approvedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION];
    }
    if (
      approvedCollection[FILTER_VALUES.ACCOUNTING_USER] &&
      approvedCollection[FILTER_VALUES.ACCOUNTING_USER]?.length > 0
    ) {
      storedFilterParams.accountingUser = approvedCollection[FILTER_VALUES.ACCOUNTING_USER];
    }
    if (approvedCollection[FILTER_VALUES.ACCOUNTING_USER]?.length === 0 && isAccounting && !noDefaultFilter) {
      const username = getUserName(userDetails);
      const user = { name: username, text: username, id: userDetails?.id, value: userDetails?.id };
      if (!storedFilterParams.accountingUser.find((au) => au.id === user.id)) {
        storedFilterParams.accountingUser.push(user);
      }
    }
    storedFilterParams.productType = approvedCollection[FILTER_VALUES.PRODUCT_TYPE] ?? [];
    storedFilterParams.user = approvedCollection[FILTER_VALUES.USER] ?? [];
    setSearchText(approvedCollection.searchText);
    setFilterParams(storedFilterParams);
  }, [approvedCollection, isAccounting, noDefaultFilter]);

  const searchParams = useSearchFilterParam({
    filterKey: COLLECTION_RECONCILIATION,
    filterParams,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setApprovedCollectionFilters({
        ...approvedCollection,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setApprovedCollectionFilters({
        ...approvedCollection,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetFilterParams = () => {
    setFilterParams(COLLECTION_RECONCILIATION.INITIAL_PARAMS);
    dispatch(resetApprovedCollectionFiltersState());
    setNoDefaultFilter(true);
  };

  const onApplyFilterHandler = (v) => {
    dispatch(
      setApprovedCollectionFilters({
        ...approvedCollection,
        ...v,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(v);
  };

  return (
    <React.Fragment>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={searchHandler}
            placeholder={'Search by location'}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>
        <Box pl={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={onApplyFilterHandler}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(COLLECTION_RECONCILIATION.INITIAL_PARAMS)?.length}
          />
        </Box>
      </Box>
      <ApprovedCollectionTable searchParams={searchParams} approvedCollection={approvedCollection} />
    </React.Fragment>
  );
};

ApprovedCollection.displayName = displayName;
export default ApprovedCollection;
