import React from 'react';
import PropTypes from 'prop-types';

import { Menu, Notifications } from '@mui/icons-material';
import { AppBar, Box, Container, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

import HeaderMenu from './HeaderMenu';
import { styles } from './style/Header.style';
import { isProd } from '../../utils/common';
import palette from '../../theme/palette';

const propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func,
  drawerWidth: PropTypes.number,
};

const Header = (props) => {
  const { classes, handleDrawerToggle, drawerWidth } = props;
  const theme = useTheme();

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 1px 4px rgba(100, 116, 139, 0.12)',
        }}
      >
        <Container maxWidth="100%">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { md: 'none' },
                color: theme.palette.common.black,
              }}
            >
              <Menu />
            </IconButton>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
              {!isProd && <div style={{ color: palette.wine, fontSize: 'bold', marginRight: 10 }}>STAGING</div>}
              <Notifications className={classes.notificationIcon} />
              <HeaderMenu />
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

Header.propTypes = propTypes;
export default withStyles(styles)(Header);
