import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addNewLocationForms: {
    locationDetails: {
      locationName: '',
      zipCode: '',
      phone1: '',
      phone2: '',
      currency: '',
      locationType: '',
      country: '',
      countrySalesTax: '',
      state: '',
      stateSalesTax: '',
      city: '',
      address1: '',
      address2: '',
      stateTaxRate: '',
      areaTaxRate: '',
      miscTax: '',
      X3_LocationsID: '',
      X3_VendorsID: '',
      X3_LocationName: '',
      X3_DepartmentID: '',
      X3_IndustryID: '',
      X3_OwnerID: '',
    },
    ownerDetails: {
      ownerId: null,
    },
    subLocationDetails: {
      subLocationName: '',
      zipCode: '',
      phone1: '',
      phone2: '',
      openHours: {
        from: null,
        to: null,
      },
      currency: '',
      subLocationType: '',
      startDate: null,
      endDate: null,
      uninstallDate: null,
      spaceIdentifier: '',
      address1: '',
      address2: '',
      productType: null,
      areaTaxLocation: null,
      taxExemptFlag: false,
    },
  },
  apiData: {
    countryData: [],
    statesData: [],
    citiesData: [],
  },
  buttonActions: {
    ownerClick: false,
    subLocationClick: false,
  },
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    locationDetailsAction(state, action) {
      state.addNewLocationForms.locationDetails = action.payload;
    },
    ownerDetailsAction(state, action) {
      state.addNewLocationForms.ownerDetails = action.payload;
    },
    subLocationDetailsAction(state, action) {
      state.addNewLocationForms.subLocationDetails = action.payload;
    },
    countryAction(state, action) {
      return {
        ...state,
        apiData: {
          ...state.apiData,
          countryData: action.payload,
        },
      };
    },
    stateAction(state, action) {
      return {
        ...state,
        apiData: {
          ...state.apiData,
          statesData: action.payload,
        },
      };
    },
    cityAction(state, action) {
      return {
        ...state,
        apiData: {
          ...state.apiData,
          citiesData: action.payload,
        },
      };
    },
    ownerClickAction(state, action) {
      state.buttonActions.ownerClick = action.payload;
    },
    subLocationClickAction(state, action) {
      state.buttonActions.subLocationClick = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default locationSlice;
