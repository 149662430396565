import dayjs from 'dayjs';
import { routeConstants } from '../constants/routeConstants';
import FIELD_TYPE from '../constants/fieldType';
import * as Yup from 'yup';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { KB } from '../constants/CommonConstants';
import { CURRENCY_CONSTANT } from '../constants/CurrencyConstants';

const { NOT_APPLICABLE } = FIELD_TYPE;

export const checkEmptyDate = (date) => {
  if ([undefined].includes(date?.toString())) {
    return null;
  } else {
    return date.toString();
  }
};

export const getControlledTooltipValueAndCellValue = (value) => {
  let cellValue;
  if (isArrayWithLength(value)) {
    cellValue = value.length > 1 ? `${value[0]}, (+${value.length - 1})` : value.join(', ');
  } else {
    cellValue = FIELD_TYPE.NOT_APPLICABLE;
  }
  return {
    tooltipValue: isArrayWithLength(value) ? value.join(', ') : null,
    cellValue,
  };
};

export const getKeyBasedObjectFromArray = (dataList, key) => {
  let keyBasedObject = {};
  dataList.forEach((data) => {
    keyBasedObject = {
      ...keyBasedObject,
      [data[key]]: data,
    };
  });
  return keyBasedObject;
};

export const getDateFormat = (date) => {
  const formateDate = dayjs(date).format('MM/DD/YYYY');
  if (formateDate !== 'Invalid Date') {
    return formateDate;
  }
  return null;
};

export const getTruncatedDateFormat = (date) => {
  const dateString = date?.split('T');
  let formateDate;
  if (dateString && dateString?.length > 0) {
    formateDate = getDateFormat(dateString[0]);
  } else {
    formateDate = dayjs(date).format('MM/DD/YYYY');
  }
  if (formateDate !== 'Invalid Date') {
    return formateDate;
  }
  return null;
};

export const getTimeFormate = (date) => {
  const formateTime = dayjs(date).format('hh:mma');
  return formateTime;
};

export const get24HoursTimeFormat = (date) => {
  const formatTime = dayjs(date).format('HH:mm');
  if (formatTime !== 'Invalid Date') {
    return formatTime;
  } else {
    return null;
  }
};

export const set24HoursTimeFormat = (hourAndMin) => {
  if (!hourAndMin || hourAndMin === NOT_APPLICABLE) return null;
  const hour = hourAndMin?.split(':')[0] || 0;
  const min = hourAndMin?.split(':')[1] || 0;
  const sec = hourAndMin?.split(':')[2] || 0;
  if (typeof hour === 'string' && isNaN(hour)) return null;
  if (typeof min === 'string' && isNaN(min)) return null;
  if (typeof sec === 'string' && isNaN(sec)) return null;
  const formatTime = dayjs()
    .set('hour', hour || 0)
    .set('minute', min || 0)
    .set('second', sec || 0);
  return formatTime;
};

export const getTimeFormatForStaticView = (time) => {
  const mergeTimeWithCurrentDate = `${dayjs().format('YYYY-MM-DD')} ${time}`;
  const getExactTime = dayjs(mergeTimeWithCurrentDate).format('hh:mma');
  return getExactTime;
};

export const getTimeFormatForFormView = (time) => {
  const mergeTimeWithCurrentDate = `${dayjs().format('YYYY-MM-DD')} ${time}`;
  return mergeTimeWithCurrentDate.toString();
};

export const dateFormatForApi = (date) => {
  const formateDate = dayjs(date).format('YYYY-MM-DD');
  if (formateDate !== 'Invalid Date') {
    return formateDate;
  } else {
    return null;
  }
};

export const isBothArraySame = (array1 = [], array2 = [], key) => {
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function (value, index) {
        return value[key] === array2Sorted[index][key];
      })
  );
};

export const isArrayWithLength = (arr) => Array.isArray(arr) && arr.length > 0;

export const intersection = (arrA, arrB) => arrA.filter((x) => arrB.includes(x));

export const checkValueNotNullUndefinedBlank = (value) =>
  value === null || value === undefined || value === '' ? false : true;

export const getOptions = (data, keyName) => {
  if (!data) {
    return [];
  }
  const key = keyName || 'name';
  return data?.map((value) => ({
    id: value?.id,
    name: value?.[key]?.trim() || value?.title?.trim(),
    text: value?.[key]?.trim() || value?.title?.trim(),
    value: value?.id,
  }));
};

export const getCurrency = (value) => {
  switch (value) {
    case CURRENCY_CONSTANT.USD:
    case CURRENCY_CONSTANT.BMD:
    case CURRENCY_CONSTANT.HKD:
    case CURRENCY_CONSTANT.AUD:
    case CURRENCY_CONSTANT.CAD:
      return '$';
    case CURRENCY_CONSTANT.INR:
      return '₹';
    case CURRENCY_CONSTANT.EUR:
      return '€';
    case CURRENCY_CONSTANT.CNY:
      return '¥';
    case CURRENCY_CONSTANT.THB:
      return '฿';
    case CURRENCY_CONSTANT.GBP:
    case CURRENCY_CONSTANT.GIP:
      return '£';
    default:
      return '$';
  }
};

export const removeItemOnceFromArray = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const isBothObjectSame = (obj1, obj2) => {
  if (!isBothArraySame(Object.keys(obj1), Object.keys(obj2))) return false;
  let isSame = true;
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      isSame = false;
    }
  }
  return isSame;
};

export const removeKeysFromObj = (obj = {}, keys = []) => {
  keys.forEach((key) => {
    delete obj[key];
  });
  return obj;
};

export const getPhNo10Digits = (phString = null, n = 10) => {
  if (!phString) return;
  phString = phString.replace(/\D/g, '');
  return phString.slice(phString.length - n);
};

export const getPhNo10DigitsWithCountryCode = (phString = null) => {
  if (!phString) return;
  if (phString.includes('+')) {
    let phArr = phString.split(' ');
    phArr[1] = getPhNo10Digits(phArr[1]);
    return phArr.join(' ');
  }
  return getPhNo10Digits(phString);
};

export const formatPhNo10Digit = (phString = null) => {
  //format to DDD-DDD-DDDD
  if (!phString || phString === NOT_APPLICABLE) return null;
  phString = phString.includes('+') ? getPhNo10DigitsWithCountryCode(phString) : getPhNo10Digits(phString);
  //string formation from last because the last 10 digits will always be valid phone number without country code
  const last4dig = phString.slice(phString.length - 4);
  const middle3dig = phString.slice(phString.length - 7, phString.length - 4);
  const first3dig = phString.slice(phString.length - 10, phString.length - 7);
  const countryCode = phString.slice(0, phString.length - 10);
  return `${countryCode}${first3dig}-${middle3dig}-${last4dig}`;
};

export const mergedTwoPhoneNumber = (phone1, phone2) => {
  const formatPhone1 = formatPhNo10Digit(phone1);
  const formatPhone2 = formatPhNo10Digit(phone2);
  if (phone1 && phone2) {
    return `${formatPhone1}, ${formatPhone2}`;
  } else if (phone1) {
    return formatPhone1;
  } else if (phone2) {
    return formatPhone2;
  } else {
    return 'N/A';
  }
};

export const getUsersMergeString = (users) => {
  const names = users?.map((value) => `${value?.firstName} ${value?.lastName}`).join(', ');
  return names;
};

export const getParentRoutePath = (path) => {
  const {
    USER_ROUTE,
    USERS_ROUTE,
    LOCATION_ROUTE,
    COLLECTIONS_ROUTE,
    MACHINES_ROUTE,
    MODELS_ROUTE,
    MODEMS_ROUTE,
    LOCATION_OWNERS_ROUTE,
    ADD_NEW_LOCATION_OWNER,
    LEASE_ROUTE,
  } = routeConstants;

  if (path.includes(USER_ROUTE)) {
    return '/' + USERS_ROUTE;
  }

  if (path.includes(LOCATION_OWNERS_ROUTE) || path.includes(ADD_NEW_LOCATION_OWNER)) {
    return '/' + LOCATION_OWNERS_ROUTE;
  }

  if (path.includes(LOCATION_ROUTE)) {
    return '/' + LOCATION_ROUTE;
  }

  if (path.includes(COLLECTIONS_ROUTE)) {
    return '/' + COLLECTIONS_ROUTE;
  }
  if (path.includes(LEASE_ROUTE)) {
    return '/' + LEASE_ROUTE;
  }

  const parentRoutes = [MACHINES_ROUTE, MODELS_ROUTE, MODEMS_ROUTE];
  const res = parentRoutes.find((route) => path.includes(route));
  if (res) return '/' + res;
  return path;
};

export const debounce = (func, delay = 600) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export const isValueValid = (value) => value !== null && value !== undefined;

export const isAllObjValuesNullOrEmpty = (obj) =>
  Object.values(obj).every((x) => x === null || parseInt(x) === 0 || x === '');

export const isAllObjectValuesNullOrZero = (obj) => Object.values(obj).every((x) => x === null || parseInt(x) === 0);

export const checkNumberIsNaNAndInfinity = (value = 0) => {
  if (typeof value !== 'number') {
    const transformedValue = Number(value);
    return isNaN(transformedValue) ? 0 : isFinite(transformedValue) ? transformedValue : 0;
  }
  return isNaN(value) ? 0 : isFinite(value) ? value : 0;
};

/**
 *
 * @param {Object} scheme
 * @param {Array} dependencies
 * @returns Yup schema object
 */
export const getValidationSchemeYupObject = (scheme, dependencies) =>
  Yup.object().shape({ ...scheme }, [...dependencies]);

export const getAddress = (location) => {
  const address = location?.address1 ?? 'N/A';
  const arr = [];
  arr.push(address);
  const address2 = location?.address2;
  const address3 = location?.address3;
  if (address2) {
    arr.push(address2);
  }
  if (address3) {
    arr.push(address3);
  }
  if (arr.length > 1) {
    return arr.join(', ');
  }
  return address;
};

export const getESTDateFormat = (date) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('America/New_York');
  const newDate = dayjs(Number(date)).tz().format('MM-DD-YYYY HH:mm');
  return newDate;
};

/**
 * Round value to 2 digits by default, return 0 if value is NaN or undefined
 *
 * @param {*} value
 * @param {*} digits
 * @param {*} setInteger
 * @returns
 */
export const roundOffValue = (value, digits, setInteger = false) => {
  if (setInteger && Number.isInteger(value)) {
    return value;
  }
  if (digits !== null && digits !== undefined && !isNaN(digits)) {
    return Number(checkNumberIsNaNAndInfinity(value).toFixed(checkNumberIsNaNAndInfinity(digits)));
  }
  return Number(checkNumberIsNaNAndInfinity(value).toFixed(2));
};

export const getUsedLocalStorageSpaceSize = () => {
  let data = '';
  for (const key in window.localStorage) {
    if (Object.prototype.hasOwnProperty.call(window.localStorage, key)) {
      data += window.localStorage[key];
    }
  }
  const size = (checkNumberIsNaNAndInfinity(data?.length) * 16) / (8 * KB);
  return size;
};

export const getFormDataSize = (formData) => {
  let data = '';
  for (const key in formData) {
    if (key in formData) {
      data += formData[key];
    }
  }
  const size = (checkNumberIsNaNAndInfinity(data?.length) * 16) / (8 * KB);
  return size;
};

export const isExceedLocalStorageLimit = (limit, usedSpaceSize) => {
  const remainSpaceSize = checkNumberIsNaNAndInfinity(limit) * KB - checkNumberIsNaNAndInfinity(usedSpaceSize);
  if (remainSpaceSize <= 0) {
    return true;
  }
  return false;
};

export const sortArrayOfObjects = (array, property, direction) => {
  direction = direction || 1;
  const sortArray = [...array];
  sortArray?.sort(function compare(a, b) {
    let comparison = 0;
    if (a[property] > b[property]) {
      comparison = 1 * direction;
    } else if (a[property] < b[property]) {
      comparison = -1 * direction;
    }
    return comparison;
  });
  return sortArray;
};

export const checkObjectNotEmpty = (obj) => {
  for (let i in obj) return true;
  return false;
};

export const rentTypeString = (type) => {
  switch (type) {
    case 'RATE_PLUS_FEE':
      return 'Rate + Fee';
    case 'FEE_ONLY':
      return 'Fee Only';
    case 'RATE_ONLY':
      return 'Rate Only';
    case 'BENCHMARK_RENT':
      return 'Benchmark Rent';
    case 'COMBO':
      return 'Combo';
    case 'FEE_ONLY_ANNUAL':
      return 'Fee Only Annual';
    case 'FEE_ONLY_YEAR':
      return 'Fee Only Year';
    case 'FEE_PER_PIC':
      return 'Fee Per Pic';
    case 'RATE_FEE_HIGHER':
      return 'Rate Fee Higher';
    case 'FEE_ONLY_AFTER_BENCHMARK':
      return 'Fee Only After Benchmark';
    case 'FEE_ONLY_SLIDING_BENCHMARK':
      return 'Fee Only Sliding Benchmark';
    case 'SUM':
      return 'Sum';
    default:
      return 'N/A';
  }
};

export const eachWordFirstLetterCapital = (str) => {
  const arr = str.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

/**
 * Calculate difference between subtrahend and minuend
 *
 * @param {any} subtrahend
 * @param {any} minuend
 * @returns
 */
export const calculateDifferenceBetweenTwoFields = (subtrahend, minuend) =>
  Math.abs(checkNumberIsNaNAndInfinity(subtrahend) - checkNumberIsNaNAndInfinity(minuend));

export const handledSpecialCharacterRegex = (str) => {
  const modifyPatter = str
    .split('')
    .map((value) => {
      let pattern = new RegExp('[a-zA-Z0-9]', 'i');
      if (!pattern.test(value)) {
        return `\\${value}`;
      }
      return value;
    })
    .join('');
  return modifyPatter;
};

export const timeCalculation = (expireTime) => {
  const MILLISECONDS_MINUTES_FORMULA = 60000;

  const currentTime = new Date().getTime();
  if (expireTime) {
    const getTimeDifference = expireTime - currentTime;
    const reducedTimeInMinutes = 5; // reduced 5 minutes of actual token time.
    const tokenReducedTime = reducedTimeInMinutes * MILLISECONDS_MINUTES_FORMULA;
    const finalRemainingTime = getTimeDifference - tokenReducedTime;
    return finalRemainingTime;
  }
  return 0;
};

export const replaceStartValueZeroWithNumber = (value) =>
  value.includes('-') ? value : value.replace(/^0+(?=\d+(\.\d+)?)/, '');

export const getDateWithoutConversion = (date) => {
  let formattedDate = date;
  if (formattedDate) {
    const arr = formattedDate?.split('');
    formattedDate = arr?.slice(0, arr?.length - 1)?.join('');
    formattedDate = getDateFormat(formattedDate);
  }
  return formattedDate;
};

export const getFullName = (firstName, lastName) => {
  const finalName =
    firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName && !lastName
      ? firstName
      : !firstName && lastName
      ? lastName
      : 'N/A';
  return finalName;
};

export const checkIsNegativeZero = (value) => 1 / value === -Infinity;

export const getUserName = (userDetail) => {
  if (userDetail?.firstName) {
    return `${userDetail?.firstName} ${userDetail?.lastName}`;
  }
  return '-';
};

export const getSum = (a, b) => {
  const value1 = checkNumberIsNaNAndInfinity(a);
  const value2 = checkNumberIsNaNAndInfinity(b);
  return parseFloat((value1 + value2).toFixed(10));
};

export const setActiveTab = (tabId) => {
  localStorage.setItem('ACTIVE_TAB', tabId);
};

export const getActiveTab = () => localStorage.getItem('ACTIVE_TAB');

export const getTabList = () => (localStorage.getItem('TAB_LIST') ? JSON.parse(localStorage.getItem('TAB_LIST')) : []);
export const addToTabList = (tabId) => {
  const tabList = getTabList();
  tabList.push(tabId);
  localStorage.setItem('TAB_LIST', JSON.stringify(tabList));
};

export const removeFromTabList = (tabId) => {
  const tabList = getTabList();
  const doesInclude = tabList.includes(tabId);
  if (tabList.length && doesInclude) {
    localStorage.setItem('TAB_LIST', JSON.stringify(tabList.filter((id) => id != tabId)));
  }
};
