export const LATEST_UPDATED = 'createdAt:ASC';
export const LATEST_UPDATED_DSC = 'createdAt:ASC';
export const FIRSTNAME_ASC = 'firstName:ASC';
export const FIRSTNAME_DESC = 'firstName:DESC';
export const TYPE = 'type:ASC';
export const LOCATION_TYPE = 'locationTypeId:ASC';
export const LOCATIONNAME_ASC = 'locationName:ASC';
export const LOCATIONNAME_DESC = 'locationName:DESC';
export const DATE_ARCHIVE_DESC = 'dateArchived:DESC';
export const DATE_ARCHIVE_ASC = 'dateArchived:ASC';
export const DATE_OF_READING_ASC = 'dateOfReading:ASC';
export const DATE_OF_READING_DESC = 'dateOfReading:DESC';
export const DATE_OF_SUBMISSION_ASC = 'dateEntered:ASC';
export const DATE_OF_SUBMISSION_DESC = 'dateEntered:DESC';
export const SUBLOCATIONNAME_ASC = 'name&order=ASC';
export const SUBLOCATIONNAME_DESC = 'name&order=DESC';

export const MACHINENAME_ASC = 'title:ASC';
export const MACHINENAME_DESC = 'title:DESC';

export const SUBLOCATION_ASC = 'sublocation:ASC';
export const GAMETYPE_ASC = 'gameType:ASC';
export const LEASE_LATEST_UPDATED = '';
export const LEASE_NAME_ASC = 'name:ASC';
export const LEASE_NAME_DESC = 'name:DESC';
export const LEASE_STATUS = 'processStatus:ASC';
export const ASSET_ID_ASC = 'assetId:ASC';
export const LEGACY_ASSET_ID_ASC = 'legacyAssetId:ASC';

export const COLLECTION_SORT_PROPERTY = {
  [LEGACY_ASSET_ID_ASC]: 'legacyAssetId',
  [ASSET_ID_ASC]: 'id',
  [MACHINENAME_ASC]: 'title',
};

const userSort = [
  {
    label: 'Latest Updated',
    value: LATEST_UPDATED,
    name: 'latest',
  },
  {
    label: 'Alphabetical A-Z',
    value: FIRSTNAME_ASC,
    name: 'asc',
  },
  {
    label: 'Alphabetical Z-A',
    value: FIRSTNAME_DESC,
    name: 'dsc',
  },
];

const locationSort = [
  {
    label: 'Location Type',
    value: LOCATION_TYPE,
    name: 'typeasc',
  },
  {
    label: 'Alphabetical A-Z',
    value: LOCATIONNAME_ASC,
    name: 'asc',
  },
  {
    label: 'Alphabetical Z-A',
    value: LOCATIONNAME_DESC,
    name: 'dsc',
  },
];

const archiveLocationSort = [
  { label: 'Archived Latest', value: DATE_ARCHIVE_DESC, name: 'dsc' },
  { label: 'Archived Oldest', value: DATE_ARCHIVE_ASC, name: 'asc' },
  ...locationSort,
];

const machineSort = [
  {
    label: 'Latest Updated',
    value: LATEST_UPDATED,
    name: 'latest',
  },
  {
    label: 'Alphabetical A-Z',
    value: MACHINENAME_ASC,
    name: 'asc',
  },
  {
    label: 'Alphabetical Z-A',
    value: MACHINENAME_DESC,
    name: 'dsc',
  },
];

const addCollectionSort = [
  {
    label: 'Asset ID',
    value: ASSET_ID_ASC,
    name: 'assetId',
  },
  {
    label: 'Legacy Asset ID',
    value: LEGACY_ASSET_ID_ASC,
    name: 'legacyAssetId',
  },
  {
    label: 'Machine Name',
    value: MACHINENAME_ASC,
    name: 'machines',
  },
];

const leaseSort = [
  {
    label: 'Status',
    value: LEASE_STATUS,
    name: 'leaseStatus',
  },
  {
    label: 'Latest Updated',
    value: LEASE_LATEST_UPDATED,
    name: 'latestUpdated',
  },
  {
    label: 'Alphabetical A-Z',
    value: LEASE_NAME_ASC,
    name: 'asc',
  },
  {
    label: 'Alphabetical Z-A',
    value: LEASE_NAME_DESC,
    name: 'dsc',
  },
];
const submittedSubLocationCollectionSort = [
  {
    label: 'Sub Location A-Z',
    value: SUBLOCATIONNAME_ASC,
    name: 'asc',
  },
  {
    label: 'Sub Location Z-A',
    value: SUBLOCATIONNAME_DESC,
    name: 'dsc',
  },
  {
    label: 'Date of Submission Latest',
    value: DATE_OF_SUBMISSION_DESC,
    name: 'dsc',
  },
  {
    label: 'Date of Submission Oldest',
    value: DATE_OF_SUBMISSION_ASC,
    name: 'asc',
  },
];

const submittedLocationCollectionSort = [
  {
    label: 'Location A-Z',
    value: LOCATIONNAME_ASC,
    name: 'asc',
  },
  {
    label: 'Location Z-A',
    value: LOCATIONNAME_DESC,
    name: 'dsc',
  },
  {
    label: 'Date of Submission Latest',
    value: DATE_OF_SUBMISSION_DESC,
    name: 'dsc',
  },
  {
    label: 'Date of Submission Oldest',
    value: DATE_OF_SUBMISSION_ASC,
    name: 'asc',
  },
];

const SORT_CONSTANT = {
  USER_SORT: userSort,
  LOCATION_SORT: locationSort,
  ARCHIVE_LOCATION_SORT: archiveLocationSort,
  MACHINE_SORT: machineSort,
  ADD_COLLECTION_SORT: addCollectionSort,
  LEASE_SORT: leaseSort,
  SUBMITTED_SUB_LOCATION_COLLECTION_SORT: submittedSubLocationCollectionSort,
  SUBMITTED_LOCATION_COLLECTION_SORT: submittedLocationCollectionSort,
};

export default SORT_CONSTANT;
