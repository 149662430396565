export const RATE_TYPES = {
  RATE_ONLY: 'RATE_ONLY',
  FIXED_ONLY: 'FIXED_ONLY',
  BOTH: 'BOTH',
  None: 'None',
};

export const RATE_TYPE_OPTIONS = [
  { value: RATE_TYPES.RATE_ONLY, text: 'Rate only' },
  { value: RATE_TYPES.FIXED_ONLY, text: 'Fixed Only' },
  { value: RATE_TYPES.BOTH, text: 'Both' },
  { value: RATE_TYPES.None, text: 'None' },
];
