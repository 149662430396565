import { Box, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CheckBox,
  Datepicker,
  GridContainer,
  GridItem,
  Select,
  TextField,
  Timepicker,
} from '../../../components/shared';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { subLocAddUpdateSchema } from '../../../schema/validationSchemas';
import {
  useGetAllCountriesQuery,
  useGetCountryStatesByCountryIdQuery,
  useGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import {
  checkValueNotNullUndefinedBlank,
  formatPhNo10Digit,
  getOptions,
  getTimeFormatForFormView,
  dateFormatForApi,
  getPhNo10Digits,
  get24HoursTimeFormat,
} from '../../../utils/common-methods';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import {
  useUpdateSubLocationByIdMutation,
  useGetSubLocationTypesQuery,
  useGetProductCategoriesQuery,
  useGetAreaTaxLocationsQuery,
} from '../../../services/Sublocation/SublocationService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from '../Locations/style';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { useDebounce } from '../../../hooks';

const SubLocationUpdateView = (props) => {
  const classes = useStyles();
  const [openHrsClockPopup, setOpenHrsClockPopup] = useState(false);
  const [openCloseHrsClockPopup, setOpenCloseHrsClockPopup] = useState(false);
  const [areaTaxLocationSearchText, setAreaTaxLocationSearchText] = useState('');
  const { subLocationDetails } = props;
  const { currentData: locationTypesData } = useGetSubLocationTypesQuery();
  const { currentData: countryData } = useGetAllCountriesQuery();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('locationId');
  const navigate = useNavigate();

  const debouncedTaxLocationSearchText = useDebounce(areaTaxLocationSearchText, 300);

  const { currentData: statesData } = useGetCountryStatesByCountryIdQuery(subLocationDetails?.location?.countryId, {
    skip: !subLocationDetails?.location?.countryId,
  });
  const { currentData: citiesData } = useGetStateCitiesByStateIdQuery(subLocationDetails?.location?.stateId, {
    skip: !subLocationDetails?.location?.stateId,
  });
  const [updateSubLocationApi, { isLoading: updating }] = useUpdateSubLocationByIdMutation();
  const locationTypes = getOptions(locationTypesData);
  const countryOptions = getOptions(countryData);
  const stateOptions = getOptions(statesData?.data?.states);
  const citiesOptions = getOptions(citiesData?.data?.cities);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const { currentData: productTypes } = useGetProductCategoriesQuery();

  const { currentData: areaTaxLocations } = useGetAreaTaxLocationsQuery({
    page: 1,
    perPage: 100,
    searchParams: `q=${debouncedTaxLocationSearchText}`,
  });

  const productTypeOptions = getOptions(productTypes);
  const productTypesInitialValues = subLocationDetails?.productCategories?.map((product) => ({
    text: product?.name,
    value: product?.id,
    id: product?.id,
    name: product?.name,
  }));

  const taxExemptOptions = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const formik = useFormik({
    initialValues: {
      name: subLocationDetails?.name || '',
      zipCode: subLocationDetails?.code || '',
      phone1: formatPhNo10Digit(subLocationDetails?.phone1) || '',
      phone2: formatPhNo10Digit(subLocationDetails?.phone2) || '',
      openHours: getTimeFormatForFormView(subLocationDetails?.openHours),
      closeHours: getTimeFormatForFormView(subLocationDetails?.closeHours),
      type: subLocationDetails?.type || '',
      location: subLocationDetails?.location?.locationName || '',
      country: subLocationDetails?.location?.countryId || '',
      countryRegion: subLocationDetails?.locationCountryRegion || '',
      state: subLocationDetails?.location?.stateId || '',
      stateTimezone: subLocationDetails?.locationStateTimezone || '',
      city: subLocationDetails?.location?.cityId || '',
      startDate:
        checkValueNotNullUndefinedBlank(subLocationDetails?.startDate) &&
        dayjs(subLocationDetails?.startDate).toString() !== 'Invalid Date'
          ? dayjs(subLocationDetails?.startDate).toString()
          : null,
      endDate:
        checkValueNotNullUndefinedBlank(subLocationDetails?.endDate) &&
        dayjs(subLocationDetails?.endDate).toString() !== 'Invalid Date'
          ? dayjs(subLocationDetails?.endDate).toString()
          : null,
      uninstallDate:
        checkValueNotNullUndefinedBlank(subLocationDetails?.uninstallDate) &&
        dayjs(subLocationDetails?.uninstallDate).toString() !== 'Invalid Date'
          ? dayjs(subLocationDetails?.uninstallDate).toString()
          : null,
      spaceIdentifier: subLocationDetails?.spaceIdentifier || '',
      address1: subLocationDetails?.address1 || '',
      address2: subLocationDetails?.address2 || '',
      productType: productTypesInitialValues?.[0] || null,
      areaTaxLocation: areaTaxLocations?.find((location) => location.text === subLocationDetails?.areaTaxLocation) || {
        text: subLocationDetails?.areaTaxLocation === 'None' ? '' : subLocationDetails?.areaTaxLocation || '',
        value: subLocationDetails?.areaTaxLocation || '',
      },
      stateTaxRate: subLocationDetails?.stateTaxRate || '',
      areaTaxRate: subLocationDetails?.areaTaxRate || '',
      miscTax: subLocationDetails?.miscTax || '',
      locPaysTaxFlag: subLocationDetails?.locPaysTaxFlag || false,
      calculateRent: subLocationDetails?.calculateRent || false,
      taxExemptFlag: subLocationDetails?.taxExemptFlag || false,
    },
    validationSchema: subLocAddUpdateSchema,
    onSubmit: (values) => {
      const productCategories = values?.productType?.id ? [values?.productType?.id] : [];
      const data = {
        id: Number(id),
        type: values?.type,
        code: values?.zipCode,
        startDate: values.startDate ? dateFormatForApi(values.startDate) : null,
        endDate: values.endDate ? dateFormatForApi(values.endDate) : null,
        uninstallDate: values?.uninstallDate ? dateFormatForApi(values.uninstallDate) : null,
        name: values.name,
        address1: values.address1,
        address2: values.address2,
        phone1: getPhNo10Digits(values.phone1),
        phone2: getPhNo10Digits(values.phone2),
        openHours: values.openHours ? get24HoursTimeFormat(values.openHours) : null,
        closeHours: values.closeHours ? get24HoursTimeFormat(values.closeHours) : null,
        locationId: locationId,
        spaceIdentifier: values?.spaceIdentifier,
        productCategories: productCategories || [],
        areaTaxLocation: values?.areaTaxLocation?.value || 'None',
        stateTaxRate: values?.stateTaxRate || '',
        areaTaxRate: values?.areaTaxRate || '',
        miscTax: values?.miscTax || '',
        locPaysTaxFlag: values?.locPaysTaxFlag,
        calculateRent: values?.calculateRent,
        taxExemptFlag: values?.taxExemptFlag,
      };

      updateSubLocationApi(data).then((res) => {
        if (res.data) {
          navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${locationId}`);
        }
      });
    },
  });
  const { touched, errors, getFieldProps, handleSubmit, values, setFieldValue } = formik;

  const autoCompleteChangeHandler = (name, value) => {
    setFieldValue(name, value);
    if (name === 'areaTaxLocation') {
      setAreaTaxLocationSearchText(value ? value.text : '');
    }
  };

  const onInputChangeHandler = (event, value) => {
    setAreaTaxLocationSearchText(value);
  };

  const openHoursChangeHandler = (value) => {
    if (dayjs(value).toString() !== 'Invalid Date') {
      setFieldValue('openHours', value.toString());
    } else {
      setFieldValue('openHours', '');
    }
  };
  const closeHoursChangeHandler = (value) => {
    if (dayjs(value).toString() !== 'Invalid Date') {
      setFieldValue('closeHours', value.toString());
    } else {
      setFieldValue('closeHours', '');
    }
  };
  const startDateChangeHandler = (value) => {
    setFieldValue('startDate', value);
  };
  const endDateChangeHandler = (value) => {
    setFieldValue('endDate', value);
  };

  const uninstallDateChangeHandler = (value) => {
    setFieldValue('uninstallDate', value);
  };

  const onCancelClickHandler = () => {
    navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${locationId}`);
  };

  const onArchiveClickHandler = () => {
    const status = subLocationDetails?.status;
    updateSubLocationApi({
      id: id,
      status: !status,
    }).then((res) => {
      if (res.data) {
        navigate(`/${routeConstants.SUBLOCATION_ROUTE}/${Number(id)}?locationId=${locationId}`);
      }
    });
  };
  const openHrsPopupHandler = () => {
    setOpenHrsClockPopup(true);
  };
  const closeHrsPopupHandler = () => {
    setOpenCloseHrsClockPopup(true);
  };
  const calculateRentChangeHandler = (e) => {
    setFieldValue('calculateRent', e.target.checked);
  };
  const locPaysTaxFlagChangeHandler = (e) => {
    setFieldValue('locPaysTaxFlag', e.target.checked);
  };

  return (
    <Box component={Paper} p={3}>
      <Box mb={3} px={2}>
        <Typography variant="h2">Sub Location Details</Typography>
      </Box>
      <Box>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <GridContainer m={1} rowSpacing={3} columnSpacing={3} alignItems="center">
              <GridItem xs={12} md={6}>
                <TextField
                  label="Sub Location Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone 1"
                  {...getFieldProps('phone1')}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'editSubLocation',
                  }}
                  error={Boolean(touched.phone1 && errors.phone1)}
                  helperText={touched.phone1 && errors.phone1}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Location"
                  {...getFieldProps('location')}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                  disabled
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Phone 2"
                  {...getFieldProps('phone2')}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    'data-testid': 'editSubLocation',
                  }}
                  error={Boolean(touched.phone2 && errors.phone2)}
                  helperText={touched.phone2 && errors.phone2}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Stack direction="row" spacing={2}>
                  <Box
                    className={formik.touched.openHours && formik.errors.openHours ? classes.formCustomFields : ''}
                    sx={{ width: '50%' }}
                  >
                    <Timepicker
                      label="Open Hours"
                      open={openHrsClockPopup}
                      onOpen={() => setOpenHrsClockPopup(true)}
                      onClose={() => setOpenHrsClockPopup(false)}
                      {...getFieldProps('openHours')}
                      onChange={openHoursChangeHandler}
                      InputProps={{
                        'data-testid': 'editSubLocation',
                        onClick: openHrsPopupHandler,
                        disabled: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      error={Boolean(formik.touched.openHours && formik.errors.openHours)}
                      helperText={formik.touched.openHours && formik.errors.openHours && 'Enter Valid Time'}
                    />
                  </Box>
                  <Box
                    className={formik.touched.closeHours && formik.errors.closeHours ? classes.formCustomFields : ''}
                    sx={{ width: '50%' }}
                  >
                    <Timepicker
                      label="Close Hours"
                      name="to"
                      open={openCloseHrsClockPopup}
                      onOpen={() => setOpenCloseHrsClockPopup(true)}
                      onClose={() => setOpenCloseHrsClockPopup(false)}
                      {...getFieldProps('closeHours')}
                      onChange={closeHoursChangeHandler}
                      InputProps={{
                        'data-testid': 'editSubLocation',
                        onClick: closeHrsPopupHandler,
                        disabled: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      error={Boolean(formik.touched.closeHours && formik.errors.closeHours)}
                      helperText={formik.touched.closeHours && formik.errors.closeHours && 'Enter Valid Time'}
                    />
                  </Box>
                </Stack>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Sub Location Type"
                  options={locationTypes}
                  {...getFieldProps('type')}
                  data-testid="editSubLocation"
                  error={formik.touched.type && formik.errors.type ? true : false}
                  helperText={formik.touched.type && formik.errors.type ? formik.errors.type : ''}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Datepicker
                  label="Installed Date"
                  name="startDate"
                  value={values.startDate}
                  onChange={startDateChangeHandler}
                  inputFormat="MM/DD/YYYY"
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                  error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Datepicker
                  label="Lease End Date"
                  name="endDate"
                  value={values.endDate}
                  onChange={endDateChangeHandler}
                  inputFormat="MM/DD/YYYY"
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                  error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Datepicker
                  label="Uninstalled Date"
                  name="uninstallDate"
                  value={values.uninstallDate}
                  onChange={uninstallDateChangeHandler}
                  inputFormat="MM/DD/YYYY"
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                  error={Boolean(formik.touched.uninstallDate && formik.errors.uninstallDate)}
                  helperText={formik.touched.uninstallDate && formik.errors.uninstallDate}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Space Identifier"
                  {...getFieldProps('spaceIdentifier')}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Address 1"
                  {...getFieldProps('address1')}
                  multiline
                  error={Boolean(touched.address1 && errors.address1)}
                  helperText={touched.address1 && errors.address1}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Address 2"
                  {...getFieldProps('address2')}
                  multiline
                  error={Boolean(touched.address2 && errors.address2)}
                  helperText={touched.address2 && errors.address2}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Zipcode"
                  multiline
                  maxRows={3}
                  {...getFieldProps('zipCode')}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  helperText={touched.zipCode && errors.zipCode}
                  InputProps={{
                    'data-testid': 'editSubLocation',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="Country"
                  options={countryOptions}
                  {...getFieldProps('country')}
                  disabled
                  data-testid="editSubLocation"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="State"
                  options={stateOptions}
                  {...getFieldProps('state')}
                  disabled
                  data-testid="editSubLocation"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  inputLabel="City"
                  options={citiesOptions}
                  {...getFieldProps('city')}
                  disabled
                  data-testid="updateField"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Autocomplete
                  label="Product Type"
                  data-testid="form-elements"
                  value={formik.values.productType || { text: '', value: '' }}
                  options={productTypeOptions || []}
                  getOptionLabel={(option) => option.text || ''}
                  onChange={(e, value) => {
                    formik.setFieldValue('productType', value);
                  }}
                  onBlur={() => formik.setFieldTouched('productType')}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  clearOnBlur={false}
                  error={Boolean(touched.productType && errors.productType)}
                  helperText={touched.productType && errors.productType}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="State tax %"
                  type="number"
                  {...getFieldProps('stateTaxRate')}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Area tax %"
                  type="number"
                  {...getFieldProps('areaTaxRate')}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  label="Misc tax %"
                  type="number"
                  {...getFieldProps('miscTax')}
                  InputProps={{
                    'data-testid': 'updateField',
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Select
                  options={taxExemptOptions}
                  inputLabel="Tax Exempt"
                  {...getFieldProps('taxExemptFlag')}
                  data-testid="updateField"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Autocomplete
                  label="Area Tax Location"
                  value={formik.values.areaTaxLocation || null}
                  options={areaTaxLocations || []}
                  getOptionLabel={(option) => option.text || ''}
                  onChange={(e, value) => autoCompleteChangeHandler('areaTaxLocation', value)}
                  onBlur={() => formik.setFieldTouched('areaTaxLocation', true)}
                  onInputChange={onInputChangeHandler}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CheckBox
                  label="Location Pays Tax"
                  labelPlacement="end"
                  checked={values.locPaysTaxFlag}
                  onChange={locPaysTaxFlagChangeHandler}
                  data-testid="editSubLocation"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CheckBox
                  label="Calculate Rent"
                  labelPlacement="end"
                  checked={values.calculateRent}
                  onChange={calculateRentChangeHandler}
                  data-testid="editSubLocation"
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-around" m={1} spacing={2}>
              <GridItem xs={6}>
                <LoadingButton loading={updating} variant="contained" type="submit">
                  UPDATE
                </LoadingButton>
                <Button onClick={onCancelClickHandler}>CANCEL</Button>
              </GridItem>
              <GridItem xs={6} display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setShowArchiveModal(true)}
                  color={subLocationDetails?.status ? 'error' : 'success'}
                >
                  {subLocationDetails?.status ? 'Archive' : 'Activate'} sub location
                </Button>
              </GridItem>
            </GridContainer>

            <ConfirmationModal
              isLoading={updating}
              isOpen={showArchiveModal}
              title="Confirmation"
              msg={`Are you sure you want to ${
                subLocationDetails?.status ? 'archive' : 'active'
              } this sub location? This process cannot be undone.`}
              buttons={[
                { text: subLocationDetails?.status ? 'Archive' : 'Active', value: true },
                { text: 'Cancel', value: false },
              ]}
              onClick={(value) => {
                if (value) onArchiveClickHandler();
                else setShowArchiveModal(false);
              }}
            />
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  );
};

export default SubLocationUpdateView;
