import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { setUserDetails } from '../../redux-slice/user';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.query({
      query: () => ({ url: `${apiUrl.profile}` }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const userData = data.data;
          dispatch(setUserDetails({ ...userData }));
        } catch (error) {
          console.error('error', error);
        }
      },
      providesTags: ['Profile'],
    }),
    getUsers: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.userList}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.userList}`;
      },
      transformResponse: (responseData) => {
        const pagination = responseData?.pagination;
        const loadUsers = responseData?.data?.users;
        const transformedUsers = loadUsers.map((user) => {
          const managementRole = user?.roles?.managementRole.map((role) => role.title);
          const nmRoles = user?.roles?.nmRoles.map((role) => role.title);
          user.roles = [...managementRole, ...nmRoles].join(', ');

          if (user.status) {
            user.status = 'Active';
          } else if (user.status !== null && user.status !== undefined && !user.status) {
            user.status = 'Inactive';
          } else {
            user.status = '';
          }
          return user;
        });
        return { users: transformedUsers, pagination };
      },
      providesTags: ['Users'],
    }),
    getDropdownUsers: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.userList}?perPage=${perPage}&page=${page}&isDropdown=true&${searchParams}`;
        }
        return `${apiUrl.userList}`;
      },
      transformResponse: (responseData) => {
        const pagination = responseData?.pagination;
        const loadUsers = responseData?.data?.users;
        return { users: loadUsers, pagination };
      },
      providesTags: ['Users'],
    }),
    getUsersForSelect: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.userListForSelect}?perPage=${perPage}&page=${page}&${searchParams}`;
        }
        return `${apiUrl.userListForSelect}`;
      },
      transformResponse: (responseData) => {
        const loadUsers = responseData?.data?.users;
        return { users: loadUsers };
      },
      providesTags: ['Users'],
    }),
    getUserById: build.query({
      query: (id) => `${apiUrl.userList}/${id}`,
      providesTags: ['User'],
    }),
    updateUserById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.userList}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    addSubLocationToUser: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.userList}/${id}/assign-sublocation`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['User', 'Users', 'Location', 'Locations'],
    }),
    updateUserRepRate: build.mutation({
      query: ({ id, sublocations }) => ({
        url: `${apiUrl.userList}/${id}/rep-rates`,
        method: 'PUT',
        body: sublocations,
      }),
    }),
    syncUsers: build.query({
      query: ({ headers }) => ({
        url: `${apiUrl.userList}/${apiUrl.updateGraphUsers}`,
        headers: headers,
      }),
    }),
  }),
  overrideExisting: false,
});
export const {
  useLazyLoginUserQuery,
  useLoginUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUsersForSelectQuery,
  useLazyGetUserByIdQuery,
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useAddSubLocationToUserMutation,
  useLazySyncUsersQuery,
  useGetDropdownUsersQuery,
  useUpdateUserRepRateMutation,
} = extendedApi;
