// Link for description
// https://javascript.plainenglish.io/role-based-authorization-role-based-access-control-v-2-in-react-js-cb958e338f4b

import { ROLE } from '../constants/roles';
import { routeConstants } from '../constants/routeConstants';
import { Users, UserDetails, EditUser } from '../pages/User';
import {
  Locations,
  AddNewLocation,
  LocationDetails,
  EditLocation,
  SubLocationDetails,
  EditSubLocation,
  EditLeaseAndRent,
  EditRemit,
} from '../pages/Location';
import { Models, AddModel, ModelDetails, EditModel } from '../pages/Model';
import { Machines, MachineDetails, EditMachine, AddMachine } from '../pages/Machine';
import { Modems, AddModem, ModemDetails, EditModem } from '../pages/Modem';
import { Collections, AddCollection, ReconciliationDetail, ApprovedCollectionDetail } from '../pages/Collection';
import { LocationOwners, AddNewLocationOwner, LocationOwnerDetails, EditLocationOwner } from '../pages/LocationOwners';
import { Deposit, ApprovedDetailDeposit, ReconciliationDetailDeposit } from '../pages/Deposit';
import { Lease, AddNewLease, LeaseDetails, EditLease } from '../pages/Lease';
import Settings from '../pages/Settings';
import { CurrencyRatesExchange, UpdateCurrencyRate } from '../pages/CurrencyRatesExchange';
import { IntercardFeed } from '../pages/ImportIntercardFeed';
// If we want to allow a particular page to be accessed to all roles then don't give permission to it or keep it an empty array.
const {
  MANAGER,
  DM,
  REGIONAL_MANAGER,
  VP,
  ACCOUNTING,
  BUSDEV_ADMINISTRATION,
  EQUIPMENT,
  EXECS,
  INDEPENDENT_REPS,
  LOGISTICS,
  MERCHANDISE,
  PARTS,
  SALES,
  SHIPPING,
  SUPER_ADMIN_IT,
  TECH_SUPPORT,
  CUST_SUPPORT,
} = ROLE;
export default [
  {
    component: <Users />,
    path: routeConstants.USERS_ROUTE,
    title: 'Users',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
    ],
  },
  {
    component: <Collections />,
    path: routeConstants.COLLECTIONS_ROUTE,
    title: 'Collections',
    permission: [SUPER_ADMIN_IT, EXECS, INDEPENDENT_REPS, ACCOUNTING, MANAGER, DM, VP, REGIONAL_MANAGER],
  },
  {
    component: <AddCollection />,
    path: `${routeConstants.COLLECTIONS_ROUTE}/${routeConstants.ADD_COLLECTIONS_ROUTE}/:id`,
    title: 'CollectionDetails',
    permission: [SUPER_ADMIN_IT, EXECS, INDEPENDENT_REPS, ACCOUNTING, MANAGER, DM, VP, REGIONAL_MANAGER],
  },
  {
    component: <ReconciliationDetail />,
    path: `${routeConstants.COLLECTIONS_ROUTE}/${routeConstants.RECONCILIATION_COLLECTION_ROUTE}/:id`,
    title: 'CollectionDetails',
    permission: [SUPER_ADMIN_IT, EXECS, ACCOUNTING, MANAGER, DM, VP, REGIONAL_MANAGER],
  },
  {
    component: <ApprovedCollectionDetail />,
    path: `${routeConstants.COLLECTIONS_ROUTE}/${routeConstants.APPROVED_COLLECTION_ROUTE}/:id`,
    title: 'CollectionDetails',
    permission: [SUPER_ADMIN_IT, EXECS, ACCOUNTING, MANAGER, DM, VP, REGIONAL_MANAGER],
  },
  {
    component: <Locations />,
    path: routeConstants.LOCATION_ROUTE,
    title: 'Locations',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <AddNewLocation />,
    path: routeConstants.ADD_LOCATION,
    title: 'AddNewLocation',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <Models />,
    path: routeConstants.MODELS_ROUTE,
    title: 'Models',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <AddModel />,
    path: `${routeConstants.ADD_MODEL}`,
    title: 'AddModel',
    permission: [SUPER_ADMIN_IT],
  },
  {
    component: <ModelDetails />,
    path: `${routeConstants.MODELS_ROUTE}/:id`,
    title: 'ModelDetail',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditModel />,
    path: `${routeConstants.MODELS_ROUTE}/${routeConstants.EDIT_MODELS_ROUTE}/:id`,
    title: 'EditModel',
    permission: [SUPER_ADMIN_IT],
  },
  {
    component: <Machines />,
    path: routeConstants.MACHINES_ROUTE,
    title: 'Machine',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <UserDetails />,
    path: `${routeConstants.USER_ROUTE}/:id`,
    title: 'UserDetails',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
    ],
  },
  {
    component: <EditUser />,
    path: `${routeConstants.USER_ROUTE}/${routeConstants.EDIT_USER_ROUTE}/:id`,
    title: 'EditUser',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <LocationDetails />,
    path: `${routeConstants.LOCATION_ROUTE}/:id`,
    title: 'LocationDetails',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <EditLocation />,
    path: `/${routeConstants.LOCATION_ROUTE}/${routeConstants.EDIT_LOCATION_ROUTE}/:id`,
    title: 'EditLocationDetails',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditSubLocation />,
    path: `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_SUB_LOCATION_ROUTE}/:id`,
    title: 'EditSubLocationDetails',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditRemit />,
    path: `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_REMIT_ROUTE}/:id`,
    title: 'EditRemit',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditLeaseAndRent />,
    path: `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_LEASE_AND_RENT}/:id`,
    title: 'EditLeaseAndRent',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <SubLocationDetails />,
    path: `${routeConstants.SUBLOCATION_ROUTE}/:id`,
    title: 'SubLocationDetails',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <LocationOwners />,
    path: `${routeConstants.LOCATION_OWNERS_ROUTE}`,
    title: 'LocationOwners',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <LocationOwnerDetails />,
    path: `${routeConstants.LOCATION_OWNERS_ROUTE}/:id`,
    title: 'LocationOwnerDetails',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <ModemDetails />,
    path: `${routeConstants.MODEMS_ROUTE}/:id`,
    title: 'ModemDetails',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <MachineDetails />,
    path: `${routeConstants.MACHINES_ROUTE}/:id`,
    title: 'MachineDetails',
    permission: [
      SUPER_ADMIN_IT,
      EXECS,
      SALES,
      ACCOUNTING,
      EQUIPMENT,
      MERCHANDISE,
      BUSDEV_ADMINISTRATION,
      TECH_SUPPORT,
      LOGISTICS,
      SHIPPING,
      PARTS,
      MANAGER,
      DM,
      VP,
      REGIONAL_MANAGER,
      CUST_SUPPORT,
    ],
  },
  {
    component: <EditMachine />,
    path: `/${routeConstants.MACHINES_ROUTE}/${routeConstants.EDIT_MACHINE_ROUTE}/:id`,
    title: 'EditMachine',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <AddMachine />,
    path: `/${routeConstants.ADD_MACHINE_ROUTE}`,
    title: 'AddMachine',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <AddNewLocationOwner />,
    path: `${routeConstants.ADD_NEW_LOCATION_OWNER}`,
    title: 'AddNewLocationOwner',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditLocationOwner />,
    path: `${routeConstants.LOCATION_OWNERS_ROUTE}/${routeConstants.EDIT_LOCATION_OWNER}/:id`,
    title: 'EditUser',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <Modems />,
    path: routeConstants.MODEMS_ROUTE,
    title: 'Modems',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <AddModem />,
    path: `${routeConstants.ADD_MODEM}`,
    title: 'AddModem',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditModem />,
    path: `/${routeConstants.MODEMS_ROUTE}/${routeConstants.EDIT_MODEM_ROUTE}/:id`,
    title: 'EditModem',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <Deposit />,
    path: routeConstants.DEPOSIT_ROUTE,
    title: 'Deposit',
    permission: [SUPER_ADMIN_IT, ACCOUNTING, VP, MANAGER, DM, REGIONAL_MANAGER, INDEPENDENT_REPS],
  },
  {
    component: <ApprovedDetailDeposit />,
    path: `${routeConstants.DEPOSIT_ROUTE}/${routeConstants.APPROVED_DETAIL_DEPOSIT}/:id`,
    title: 'ApprovedDetailDeposit',
    permission: [SUPER_ADMIN_IT, ACCOUNTING, VP, MANAGER, DM, REGIONAL_MANAGER, INDEPENDENT_REPS],
  },
  {
    component: <ReconciliationDetailDeposit />,
    path: `${routeConstants.DEPOSIT_ROUTE}/${routeConstants.RECONCILIATION_DETAIL_DEPOSIT}/:id`,
    title: 'ReconciliationDetailDeposit',
    permission: [SUPER_ADMIN_IT, ACCOUNTING, VP, MANAGER, DM, REGIONAL_MANAGER, INDEPENDENT_REPS],
  },
  {
    component: <Lease />,
    path: routeConstants.LEASE_ROUTE,
    title: 'Lease',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <AddNewLease />,
    path: routeConstants.ADD_LEASE_ROUTE,
    title: 'AddNewLease',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <LeaseDetails />,
    path: `${routeConstants.LEASE_ROUTE}/:id`,
    title: 'LeaseDetails',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <EditLease />,
    path: `/${routeConstants.LEASE_ROUTE}/${routeConstants.EDIT_LEASE_ROUTE}/:id`,
    title: 'EditLease',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <Settings />,
    path: `${routeConstants.SETTING_ROUTE}`,
    title: 'Settings',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <CurrencyRatesExchange />,
    path: `${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}`,
    title: 'Currency rates master',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <UpdateCurrencyRate />,
    path: `${routeConstants.SETTING_ROUTE}/${routeConstants.CURRENCY_RATES_EXCHANGE_ROUTE}/:id`,
    title: 'Update Currency Rate',
    permission: [SUPER_ADMIN_IT, ACCOUNTING],
  },
  {
    component: <IntercardFeed />,
    path: `${routeConstants.SETTING_ROUTE}/${routeConstants.INTERCARD_FEED_IMPORT}`,
    title: 'Import Intercard Feed',
    permission: [SUPER_ADMIN_IT],
  },
];
