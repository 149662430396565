import { useFormik } from 'formik';
import { useEffect, useState, useMemo } from 'react';
import useStyles from './style';
import { AttachMoney, HighlightOff } from '@mui/icons-material';
import { useDebounce } from '../../../hooks';
import { Box, Paper, Typography, Stack } from '@mui/material';
import {
  Button,
  GridContainer,
  GridItem,
  Select,
  Breadcrumbs,
  TextField,
  Datepicker,
  Autocomplete,
} from '../../../components/shared';
import { machineUpdateSchema } from '../../../schema/validationSchemas';
import { useParams, useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import { useGetModelsQuery } from '../../../services/Models/ModelsService';
import { useGetAllSublocationsQuery } from '../../../services/Sublocation/SublocationService';
import {
  useGetMachineDetailsByIdQuery,
  useGetMachineStatusQuery,
  useLazyGetMachineDetailsHistoryByIdQuery,
  useUpdateMachineByIdMutation,
} from '../../../services/Machines/MachinesService';
import { dateFormatForApi, getOptions } from '../../../utils/common-methods';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import HistoryTabs from '../../../components/HistoryTabs/HistoryTabs';
import TimerModal from '../../../components/TimerModal/TimerModal';
import { MESSAGE } from '../../../constants/message';

const EditMachine = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    MACHINE: { MACHINE, MACHINE_DETAIL, EDIT_MACHINE },
  } = BREADCRUMB_NAMES;

  const [modelInputValue, setModelInputValue] = useState('');
  const [sublocationInputValue, setSublocationInputValue] = useState('');
  const [page, setPage] = useState(1);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [currentInputVal, setCurrentInputVal] = useState('');
  const [machineHistoryPagination, setMachineHistoryPagination] = useState(null);
  const [machineHistoryPage, setMachineHistoryPage] = useState(1);
  const [showHistory, setShowHistory] = useState(false);
  const [showNoHistoryModal, setShowNoHistoryModal] = useState(false);

  const { isSuccess, currentData: currentMachineDetails } = useGetMachineDetailsByIdQuery(Number(id));
  const [updateMachineApi] = useUpdateMachineByIdMutation();
  const [getMachineHistoryById, { isSuccess: machineHistoryIsSuccess, currentData: machineHistoryData }] =
    useLazyGetMachineDetailsHistoryByIdQuery();
  const machineHistory =
    machineHistoryData?.data?.assetHistories?.length > 0 ? machineHistoryData?.data?.assetHistories?.[0] : null;

  let MachineTitle = currentMachineDetails?.data?.title || 'N/A';
  const machineStatus = currentMachineDetails?.data?.assetStatus;
  const breadcrumbData = [
    { text: MACHINE, redirection: `${routeConstants.MACHINES_ROUTE}?selectedTab=${machineStatus ? '1' : '2'}` },
    {
      text: MACHINE_DETAIL,
      redirection: `${routeConstants.MACHINES_ROUTE}/${id}`,
    },
    { text: EDIT_MACHINE },
  ];
  useEffect(() => {
    if (isSuccess && currentMachineDetails.data) {
      setValues({
        title: currentMachineDetails.data.title || '',
        statusId: currentMachineDetails.data.statusId || '',
        subLocationId: currentMachineDetails.data.sublocation
          ? {
              text: currentMachineDetails.data.sublocation?.name ?? '',
              value: currentMachineDetails.data.sublocation?.id ?? '',
            }
          : null,
        modelId: currentMachineDetails.data.model
          ? {
              text: currentMachineDetails.data.model?.title,
              value: currentMachineDetails.data.model?.id,
            }
          : null,
        price: currentMachineDetails.data?.price || 0,
        installedDate: currentMachineDetails.data.installedDate,
        uninstalledDate: currentMachineDetails.data.uninstalledDate,
        numberOfPlays: currentMachineDetails.data.numberOfPlays || 0,
        id: currentMachineDetails.data.id || 0,
        legacyAssetId: currentMachineDetails.data.legacyAssetId || '',
        costPerPlay: currentMachineDetails.data.costPerPlay,
        clicksPerPlay: currentMachineDetails.data.clicksPerPlay || 0,
      });
    }
  }, [isSuccess, currentMachineDetails]);

  const formik = useFormik({
    initialValues: {
      title: '',
      statusId: '',
      subLocationId: { text: '', value: null },
      modelId: { text: '', value: '' },
      price: 0,
      installedDate: '',
      uninstalledDate: '',
      numberOfPlays: 0,
      costPerPlay: 0,
      clicksPerPlay: 0,
      id: 0,
      legacyAssetId: '',
    },
    validationSchema: machineUpdateSchema,
    onSubmit: (data) => {
      const machineId = Number(id);
      updateMachineApi({
        id: machineId,
        title: data.title,
        statusId: data.statusId,
        modelId: data.modelId.value,
        subLocationId: data?.subLocationId?.value || null,
        installedDate: dateFormatForApi(data.installedDate),
        uninstalledDate: dateFormatForApi(data.uninstalledDate),
        price: data.price || 0,
        clicksPerPlay: data.clicksPerPlay || 0,
        costPerPlay: data.costPerPlay || 0,
        legacyAssetId: data.legacyAssetId || '',
      }).then((response) => {
        if (response.data) {
          onSaveHandler();
        }
      });
    },
  });

  const { currentData: modelResponse } = useGetModelsQuery({
    searchParams: `q=${useDebounce(modelInputValue, 600)}`,
    perPage: 10,
    page: 1,
  });

  const { currentData: sublocationsResponse, isSuccess: subLocationIsSuccess } = useGetAllSublocationsQuery({
    searchParams: `q=${useDebounce(sublocationInputValue, 600)}`,
    perPage: 100,
    page: page,
  });

  useEffect(() => {
    const sublocationTypes = getOptions(sublocationsResponse);
    setSubLocationOptions((prev) => [...prev, ...sublocationTypes]);
  }, [sublocationsResponse, page]);

  useEffect(() => {
    if (machineHistoryIsSuccess && showHistory) {
      if (machineHistory) {
        const values = {
          title: machineHistory?.title || '',
          statusId: machineHistory?.statusId || '',
          subLocationId: machineHistory?.sublocation
            ? {
                text: machineHistory?.sublocation?.name ?? '',
                value: machineHistory?.sublocation?.id ?? '',
              }
            : null,
          modelId: machineHistory?.model
            ? {
                text: machineHistory?.model?.title,
                value: machineHistory?.model?.id,
              }
            : null,
          price: machineHistory?.price || 0,
          installedDate: machineHistory?.installedDate,
          uninstalledDate: machineHistory?.uninstalledDate,
          numberOfPlays: machineHistory?.numberOfPlays || 0,
          id: machineHistory?.assetId || 0,
          legacyAssetId: machineHistory?.legacyAssetId || '',
          costPerPlay: machineHistory?.costPerPlay,
          clicksPerPlay: machineHistory?.clicksPerPlay || 0,
        };
        setValues(values);
        setMachineHistoryPagination(machineHistoryData?.pagination || null);
      } else if (machineHistoryPage === 1 && machineHistory === null) {
        setShowNoHistoryModal(true);
      }
    }
  }, [machineHistoryIsSuccess, machineHistoryData, showHistory]);

  const { currentData: assetStatusTypeResponse } = useGetMachineStatusQuery();

  const assetStatus = useMemo(
    () => getOptions(assetStatusTypeResponse?.data?.assetStatuses),
    [assetStatusTypeResponse],
  );

  const modelTypes = useMemo(() => getOptions(modelResponse?.data?.models), [modelResponse]);

  const onCancelHandler = () => {
    navigate(`/${routeConstants.MACHINES_ROUTE}/${id}`, { redirect: true });
  };

  const onSaveHandler = () => {
    navigate(`/${routeConstants.MACHINES_ROUTE}/${id}`, { redirect: true });
  };

  const onPreviousClick = () => {
    getMachineHistoryById({
      id,
      page: machineHistoryPage + 1,
      perPage: 1,
    });
    setMachineHistoryPage((prev) => prev + 1);
  };

  const onNextClick = () => {
    if (machineHistoryPage - 1 !== 0) {
      getMachineHistoryById({
        id,
        page: machineHistoryPage - 1,
        perPage: 1,
      });
    }
    setMachineHistoryPage((prev) => prev - 1);
  };

  const onLatestClick = () => {
    setMachineHistoryPage(1);
    setShowHistory(false);
    setMachineHistoryPagination(null);
    setValues({
      title: currentMachineDetails.data.title || '',
      statusId: currentMachineDetails.data.statusId || '',
      subLocationId: currentMachineDetails.data.sublocation
        ? {
            text: currentMachineDetails.data.sublocation?.name ?? '',
            value: currentMachineDetails.data.sublocation?.id ?? '',
          }
        : null,
      modelId: currentMachineDetails.data.model
        ? {
            text: currentMachineDetails.data.model?.title,
            value: currentMachineDetails.data.model?.id,
          }
        : null,
      price: currentMachineDetails.data?.price || 0,
      installedDate: currentMachineDetails.data.installedDate,
      uninstalledDate: currentMachineDetails.data.uninstalledDate,
      numberOfPlays: currentMachineDetails.data.numberOfPlays || 0,
      id: currentMachineDetails.data.id || 0,
      legacyAssetId: currentMachineDetails.data.legacyAssetId || '',
      costPerPlay: currentMachineDetails.data.costPerPlay,
      clicksPerPlay: currentMachineDetails.data.clicksPerPlay || 0,
    });
  };

  const { values, errors, touched, getFieldProps, setFieldValue, setValues } = formik;
  const isOldRecord = machineHistoryPagination !== null;

  return (
    <Box>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs icon={'/'} variant="smallText" underline="hover" data={breadcrumbData} />
      </Box>
      <Box>
        <Stack direction={'row'} spacing={2} alignItems="center">
          <Typography variant="h1" pb={4} data-testid="machineTitle">
            {MachineTitle}
          </Typography>
        </Stack>
      </Box>
      <Box component={Paper} className={classes.formContent}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0px 8px 16px',
          }}
        >
          <div>
            <Typography variant="h2">Machine Details</Typography>
          </div>
          {showHistory ? (
            <HistoryTabs
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
              onLatestClick={onLatestClick}
              historyPage={machineHistoryPage}
              historyPagination={machineHistoryPagination}
              updatedBy={{
                name: `${machineHistory?.user?.firstName} ${machineHistory?.user?.lastName}`,
                date: machineHistory?.updatedAt || null,
              }}
            />
          ) : (
            <Button
              onClick={() => {
                setShowHistory(true);
                getMachineHistoryById({
                  id,
                  page: 1,
                  perPage: 1,
                });
              }}
            >
              <Typography variant="button">Show History</Typography>
            </Button>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
            <GridItem xs={12} md={6}>
              <TextField
                label="Machine Name"
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Select
                inputLabel="Status"
                data-testid="form-elements"
                options={assetStatus}
                {...getFieldProps('statusId')}
                error={Boolean(touched.statusId && errors.statusId)}
                helperText={touched.statusId && errors.statusId}
                disabled={isOldRecord}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <Datepicker
                label="Installed Date"
                value={values.installedDate ? new Date(values.installedDate) : null}
                data-testid="form-elements"
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('installedDate', newValue)}
                onBlur={() => formik.setFieldTouched('installedDate')}
                error={Boolean(touched.installedDate && errors.installedDate)}
                helperText={touched.installedDate && errors.installedDate}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Datepicker
                label="Uninstall Date"
                value={values.uninstalledDate ? new Date(values.uninstalledDate) : null}
                data-testid="form-elements"
                inputFormat="MM/DD/YYYY"
                onChange={(newValue) => setFieldValue('uninstalledDate', newValue)}
                onBlur={() => formik.setFieldTouched('uninstalledDate')}
                error={Boolean(touched.uninstalledDate && errors.uninstalledDate)}
                helperText={touched.uninstalledDate && errors.uninstalledDate}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="No.of Plays"
                disabled
                {...getFieldProps('numberOfPlays')}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Autocomplete
                label="Model Name"
                data-testid="form-elements"
                value={values.modelId}
                options={modelTypes || []}
                getOptionLabel={(option) => option.text || ''}
                onChange={(e, value) => {
                  setFieldValue('modelId', value);
                }}
                onInputChange={(event, newInputValue) => {
                  setModelInputValue(newInputValue);
                }}
                onBlur={() => formik.setFieldTouched('modelId')}
                error={Boolean(touched.modelId && errors.modelId)}
                helperText={touched.modelId && errors.modelId}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Price"
                {...getFieldProps('price')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                  'data-testid': 'form-elements',
                  startAdornment: <AttachMoney className={classes.attachMoneyIcon} />,
                }}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Clicks Per Play"
                type="number"
                {...getFieldProps('clicksPerPlay')}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                error={Boolean(touched.clicksPerPlay && errors.clicksPerPlay)}
                helperText={touched.clicksPerPlay && errors.clicksPerPlay}
                disabled={isOldRecord}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <TextField
                label="Cost Per Play"
                type="number"
                {...getFieldProps('costPerPlay')}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                error={Boolean(touched.costPerPlay && errors.costPerPlay)}
                helperText={touched.costPerPlay && errors.costPerPlay}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Autocomplete
                ListboxProps={{
                  onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                      if (subLocationIsSuccess && currentInputVal === sublocationInputValue) {
                        setPage((prevPage) => prevPage + 1);
                      }
                    }
                  },
                }}
                label="Sub Location"
                data-testid="form-elements"
                value={values.subLocationId}
                options={subLocationOptions || []}
                getOptionLabel={(option) => option.text || ''}
                onChange={(e, value) => {
                  setFieldValue('subLocationId', value);
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                }}
                onInputChange={(event, newInputValue) => {
                  setCurrentInputVal(newInputValue);
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                  setSublocationInputValue(newInputValue);
                }}
                onBlur={() => {
                  formik.setFieldTouched('subLocationId');
                  setSubLocationOptions(() => []);
                  setPage(() => 1);
                }}
                disabled={isOldRecord}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Asset ID"
                disabled
                {...getFieldProps('id')}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Legacy Asset ID(eg. static|475839,MDB|293081,ELT|109372)"
                {...getFieldProps('legacyAssetId')}
                InputProps={{
                  'data-testid': 'form-elements',
                }}
                error={Boolean(touched.legacyAssetId && errors.legacyAssetId)}
                helperText={touched.legacyAssetId && errors.legacyAssetId}
                disabled={isOldRecord}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="space-around" pl={4}>
            <GridItem xs={12} md={12}>
              <Stack direction={'row'} className={classes.btn}>
                <Stack direction={'row'}>
                  <Button type="Submit" variant="contained" disabled={isOldRecord}>
                    <Typography variant="button">UPDATE</Typography>
                  </Button>
                  <Button onClick={onCancelHandler}>
                    <Typography variant="button">CANCEL</Typography>
                  </Button>
                </Stack>
              </Stack>
            </GridItem>
          </GridContainer>
        </form>
      </Box>
      <TimerModal
        isOpen={showNoHistoryModal}
        timer={1}
        activate={showNoHistoryModal}
        onTimeComplete={() => {
          setShowNoHistoryModal(false);
          onLatestClick();
        }}
        icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
        title={MESSAGE.NO_HISTORY}
        msg={`${MESSAGE.NO_MACHINE_HISTORY} This message will be closed in`}
      />
    </Box>
  );
};

export default EditMachine;
