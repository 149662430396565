import React from 'react';
import { Button } from '../shared';
import { Typography } from '@mui/material';
import { getDateFormat } from '../../utils/common-methods';

const HistoryTabs = (props) => {
  const {
    onPreviousClick = () => {},
    onNextClick = () => {},
    onLatestClick = () => {},
    historyPagination = {},
    historyPage = 0,
    updatedBy = { name: '', date: null },
  } = props;

  const getUpdatedBy = () => (
    <div
      style={{
        display: 'block',
        alignSelf: 'center',
      }}
    >
      <div>
        <Typography variant="h4">{`Modified By: ${updatedBy.name}, ${getDateFormat(updatedBy.date)}`}</Typography>
      </div>
    </div>
  );

  return (
    <div style={{ display: 'flex' }}>
      {getUpdatedBy()}
      <Button
        onClick={onPreviousClick}
        disabled={historyPagination?.total === 0 || historyPage === historyPagination?.pages}
      >
        <Typography variant="button">Previous</Typography>
      </Button>
      <Button onClick={onNextClick} disabled={historyPage <= 1}>
        <Typography variant="button">Next</Typography>
      </Button>
      <Button onClick={onLatestClick}>
        <Typography variant="button">Latest</Typography>
      </Button>
    </div>
  );
};

export default HistoryTabs;
