import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import ErrorEndAdornment from '../../../components/ErrorEndAdornment';
import { useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { usePropagateRef } from '../../../hooks/usePropagateRef';
import { getCurrency, isValueValid, roundOffValue } from '../../../utils/common-methods';
import {
  getDispensedFieldNameAndValue,
  getExpectedCashOrCoinRevenueByFieldName,
  getRevenueFieldNameAndRevenueInUSD,
  getRevenueInUSD,
  getTokenChangerRevenueAndDispensedValueFromFieldName,
  getTokenRevenueAndFieldNameByName,
  getVarianceValue,
} from '../../../utils/collection-methods';
import {
  DISPENSED_METER_TYPES,
  FIELD_NAME,
  METER_TYPE,
  NEGATIVE_VALUE_ALLOW_FIELD_LABELS,
} from '../../../constants/MeterTypeFieldMap';
import { CURRENCY_CONSTANT, CURRENCY_TYPE, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import { TextField } from '../../../components/shared';
import { setDefaultAccountSummaryState } from '../../../redux-slice/reconciliationCollection';

const displayName = 'PerformantCollectionTextField';
/**
 * Refactored from https://hackernoon.com/improving-formik-performance-when-its-slow-material-ui
 * https://github.com/superjose/increase-formik-performance-react/blob/main/src/components/Fields/Form/PerformantTextField/index.tsx
 * @param {Object} props { name, disablePerformance, loading, min, max, others}
 */
export const PerformantCollectionTextField = React.memo((props) => {
  const {
    disablePerformance,
    label,
    currencyType,
    priceIcon,
    showWarning,
    warningMessage,
    loading,
    customizedError,
    type,
    name,
    assetMeter,
    isReconciliation,
    costPerPlay,
    clicksPerPlay,
    ...otherProps
  } = props;

  const [field, meta] = useField(name);
  const { values, setValues, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  /**
   * For performance reasons (possible due to CSS in JS issues), heavy views
   * affect re-renders (Formik changes state in every re-render), bringing keyboard
   * input to its knees. To control this, we create a setState that handles the field's inner
   * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
   * onFocus.
   */
  const [currentFieldValue, setCurrentFieldValue] = useState(field.value);
  const [isFocus, setIsFocus] = useState(false);
  const {
    locationCurrency: reconciliationLocationCurrency,
    averageTokenValueInUSD,
    accountSummary,
  } = useSelector((state) => state.reconciliationCollection);
  const { locationCurrency } = useSelector((state) => state.newCollection);
  const { abbreviation, exchangeRate: readExchangeRate } = isReconciliation
    ? reconciliationLocationCurrency
    : locationCurrency;
  const exchangeRate = readExchangeRate ? readExchangeRate : DEFAULT_EXCHANGE_RATE;
  const error = !isValueValid(customizedError) ? !!meta.error && meta.touched : customizedError;
  const localPriceIcon = getCurrency(abbreviation || CURRENCY_CONSTANT.USD);
  const USDPriceIcon = getCurrency(CURRENCY_CONSTANT.USD);

  usePropagateRef({
    setCurrentFieldValue,
    name: name,
    value: field.value,
  });

  /**
   * Using this useEffect guarantees us that pre-filled forms
   */
  useEffect(() => {
    if (meta.touched) {
      return;
    }
    const [meterName, meterFieldName] = name.split('_');
    // Update token revenue here using latest averageTokenValue
    if (name && meterName === METER_TYPE.TOKEN && meterFieldName === FIELD_NAME.TOKEN_REVENUE) {
      const { tokenRevenueFieldName, tokenRevenueInLocalCurrency } = getTokenRevenueAndFieldNameByName(
        name,
        values,
        averageTokenValueInUSD,
        exchangeRate,
      );
      if (String(values[tokenRevenueFieldName]) !== String(tokenRevenueInLocalCurrency)) {
        setFieldValue(tokenRevenueFieldName, tokenRevenueInLocalCurrency);
        setCurrentFieldValue(tokenRevenueInLocalCurrency);
      }
    } else if (name && meterName === METER_TYPE.TOKEN && meterFieldName === FIELD_NAME.REVENUE_IN_USD) {
      const { tokenRevenueInUSDFieldName, tokenRevenueInUSD } = getTokenRevenueAndFieldNameByName(
        name,
        values,
        averageTokenValueInUSD,
        exchangeRate,
      );
      if (String(values[tokenRevenueInUSDFieldName]) !== String(tokenRevenueInUSD)) {
        setFieldValue(tokenRevenueInUSDFieldName, tokenRevenueInUSD);
        setCurrentFieldValue(tokenRevenueInUSD);
      }
    } else {
      if (field.value !== currentFieldValue) {
        setCurrentFieldValue(field.value ?? '');
      }
    }
  }, [field.value, averageTokenValueInUSD]);

  useEffect(() => {
    if (field.value !== currentFieldValue) {
      setCurrentFieldValue(field.value ?? '');
    }
  }, [values]);

  const onChange = (evt) => {
    setCurrentFieldValue(evt.target.value);
  };
  /**
   *
   * Propagate the change to Formik onBlur
   */
  const onBlur = (evt) => {
    if (accountSummary?.defaultAccountSummaryState) {
      dispatch(setDefaultAccountSummaryState(false));
    }
    let val = evt.target.value || '';
    if (field.name.includes(FIELD_NAME.METER_ADJUST) && val == '') {
      val = 0;
      setCurrentFieldValue(val);
    }
    window.setTimeout(() => {
      const update = { [name]: val };
      const [meterType, fieldName] = name.split('_');
      // set Revenue in USD
      if (
        abbreviation !== CURRENCY_CONSTANT.USD &&
        name &&
        [METER_TYPE.CANDY, METER_TYPE.IMPULSE_READER, METER_TYPE.CASH, METER_TYPE.COIN, METER_TYPE.BILL].includes(
          meterType,
        )
      ) {
        const { revenueFieldName, revenueInUSD } = getRevenueFieldNameAndRevenueInUSD(exchangeRate, val, values, name);
        if (revenueFieldName && values[revenueFieldName] !== revenueInUSD) {
          update[revenueFieldName] = roundOffValue(revenueInUSD);
        }
      }

      // set dispensed value
      // Est CC Revenue is moved to affectReconciliationCashOrCoinOrCreditRevenue
      const affectDispensedFields =
        (name && fieldName === FIELD_NAME.CURRENT_READING && DISPENSED_METER_TYPES.includes(meterType)) ||
        (isReconciliation &&
          name &&
          [METER_TYPE.MEDALLION, METER_TYPE.TICKET, METER_TYPE.PRIZE, METER_TYPE.TOKEN].includes(meterType) &&
          [FIELD_NAME.CURRENT_READING, FIELD_NAME.PRIOR_READING].includes(fieldName));
      if (affectDispensedFields) {
        const { dispensedFieldName, dispensedValue } = getDispensedFieldNameAndValue(
          name,
          val,
          values,
          isReconciliation,
        );
        if (values[dispensedFieldName] !== dispensedValue) {
          update[dispensedFieldName] = dispensedValue;
        }
      }

      // set tokenDispensed & revenueCollected for token changer
      if (
        name &&
        meterType === METER_TYPE.TOKEN_CHANGER &&
        [FIELD_NAME.CURRENT_READING, FIELD_NAME.PRIOR_READING].includes(fieldName)
      ) {
        const {
          tokenDispensedFieldName,
          tokenDispensed,
          revenueCollectedFieldName,
          revenueCollected,
          revenueInUSDFieldName,
        } = getTokenChangerRevenueAndDispensedValueFromFieldName(name, val, assetMeter, values);

        if (values[tokenDispensedFieldName] !== tokenDispensed) {
          update[tokenDispensedFieldName] = tokenDispensed;
        }
        if (values[revenueCollectedFieldName] !== revenueCollected) {
          update[revenueCollectedFieldName] = revenueCollected;
        }
        const revenueCollectedInUSD = getRevenueInUSD(revenueCollected, exchangeRate);
        if (values[revenueInUSDFieldName] !== revenueCollectedInUSD) {
          update[revenueInUSDFieldName] = roundOffValue(revenueCollectedInUSD);
        }
      }
      // For reconciliation form
      // set expectedCashRevenue for reconciliation cash meter / estimatedCoinRevenueValue for reconciliation Coin meter
      // or estimatedCCrevenue for reconciliation credit meter
      const affectReconciliationCashOrCoinOrCreditRevenue =
        (isReconciliation &&
          name &&
          meterType == METER_TYPE.CASH &&
          [FIELD_NAME.CURRENT_READING, FIELD_NAME.PRIOR_READING].includes(fieldName)) ||
        (isReconciliation &&
          name &&
          meterType == METER_TYPE.CREDIT &&
          [FIELD_NAME.CURRENT_READING, FIELD_NAME.PRIOR_READING].includes(fieldName)) ||
        (isReconciliation &&
          name &&
          meterType == METER_TYPE.COIN &&
          [FIELD_NAME.CURRENT_READING, FIELD_NAME.COINS_COLLECTED, FIELD_NAME.PRIOR_READING].includes(fieldName));
      if (affectReconciliationCashOrCoinOrCreditRevenue) {
        const { expectedRevenueFieldName, expectedRevenue } = getExpectedCashOrCoinRevenueByFieldName(
          name,
          val,
          clicksPerPlay,
          costPerPlay,
          values,
          exchangeRate,
        );
        if (values[expectedRevenueFieldName] !== expectedRevenue) {
          update[expectedRevenueFieldName] = expectedRevenue;
        }
      }

      if (
        meterType == METER_TYPE.CASH &&
        [
          FIELD_NAME.COINS_COLLECTED,
          FIELD_NAME.BILLS_COLLECTED,
          FIELD_NAME.METER_ADJUST,
          FIELD_NAME.CURRENT_READING,
          FIELD_NAME.PRIOR_READING,
        ].includes(fieldName)
      ) {
        const { variance, varianceFieldName } = getVarianceValue(
          name,
          fieldName,
          val,
          values,
          exchangeRate,
          costPerPlay,
          clicksPerPlay,
        );

        update[varianceFieldName] = variance;
      }

      //Only when update values is different from value of values[name], they will be updated
      if (Object.keys(update).length === 1 && Object.keys(update)[0] === name && String(values[name]) !== String(val)) {
        field.onChange({
          target: {
            name: name,
            value: val,
          },
        });
      } else if (Object.keys(update).length > 1) {
        setValues({ ...values, ...update });
      }
    }, 0);
  };

  // Will set depending on the performance props
  const performanceProps = disablePerformance
    ? {
        ...field,
        value: loading ? 'Loading...' : currentFieldValue,
      }
    : {
        ...field,
        value: loading ? 'Loading...' : currentFieldValue,
        onChange,
        onBlur: (evt) => {
          setIsFocus(false);
          onBlur(evt);
        },
        onFocus: () => {
          setIsFocus(true);
          onBlur;
        },
      };

  return (
    <>
      <TextField
        {...otherProps}
        type={type}
        label={label}
        name={name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {priceIcon ? (currencyType === CURRENCY_TYPE.USD ? USDPriceIcon : localPriceIcon) : ''}
            </InputAdornment>
          ),
          endAdornment: !isFocus && showWarning ? <ErrorEndAdornment message={warningMessage} /> : '',
          ...((props.type === 'number' && {
            inputProps: {
              min: props?.min && !NEGATIVE_VALUE_ALLOW_FIELD_LABELS.includes(label) ? 0 : null,
              max: props?.max,
              step: 'any',
            },
          }) ||
            undefined),
        }}
        error={error}
        helperText={meta.touched && meta.error}
        restrictNegativeValue={!NEGATIVE_VALUE_ALLOW_FIELD_LABELS.includes(label)}
        {...performanceProps}
      />
    </>
  );
});

PerformantCollectionTextField.displayName = displayName;
