import { Box, Typography, Stack } from '@mui/material';
import { Breadcrumbs, Accordion, NoContent, PositionedMenu } from '../../../components/shared';
import { useParams } from 'react-router-dom';
import { useGetMachineDetailsByIdQuery } from '../../../services/Machines/MachinesService';
import React, { useState, useEffect } from 'react';
import MachineDetailView from './MachineDetailsView';
import useStyles from './style';
import { routeConstants } from '../../../constants/routeConstants';
import { ROLE } from '../../../constants/roles';
import { useNavigate } from 'react-router-dom';
import AssignModem from './AssignModem';
import ModemDetailView from './ModemDetailsView';
import { RouterOutlined, Speed } from '@mui/icons-material';
import { useGetModemsByMachineIdQuery } from '../../../services/Machines/MachinesService';
import MeterTable from './MeterTable';
import EditMeterFields from './EditMeterFields';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import LoadingSpinner from '../../../components/LoadingSpinner';
import AddNewMeter from './AddNewMeter';
import { useHasRoles } from '../../../hooks';

const MachineDetails = () => {
  const params = useParams();
  const [modemUpdate, setModemUpdate] = useState(false);
  const [modemData, setModemData] = useState(null);
  const [meterData, setMeterData] = useState(null);
  const [showAssignModem, setShowAssignModem] = useState(false);
  const [addMeter, setAddMeter] = useState(false);
  const [isUpdateMeterField, setIsUpdateMeterField] = useState(false);
  const [updateMeterData, setUpdateMeterData] = useState(null);
  const [isModemDetailExpanded, setIsModemDetailExpanded] = useState(false);
  const isAdminOrEquipment = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.EQUIPMENT, ROLE.ACCOUNTING]);
  const {
    MACHINE: { MACHINE, MACHINE_DETAIL, EDIT_MODEM_DETAIL },
  } = BREADCRUMB_NAMES;
  const {
    isSuccess: modemSuccess,
    isFetching: modemFetching,
    isLoading: modemLoading,
    currentData: currentModemsData,
  } = useGetModemsByMachineIdQuery(Number(params.id));

  const {
    isSuccess,
    isFetching,
    isLoading,
    currentData: currentMachineDetails,
  } = useGetMachineDetailsByIdQuery(Number(params.id));

  const classes = useStyles();
  const navigate = useNavigate();

  const { data: machineDetailsResponse } = currentMachineDetails ?? {};
  const editModemBreadCrumbData = [
    {
      text: MACHINE,
      redirection: `${routeConstants.MACHINES_ROUTE}?selectedTab=${machineDetailsResponse?.assetStatus ? '1' : '2'}`,
    },
    {
      text: MACHINE_DETAIL,
      redirection: `${routeConstants.MACHINES_ROUTE}/${params.id}`,
    },
    { text: EDIT_MODEM_DETAIL },
  ];
  const breadcrumbData = [
    {
      text: MACHINE,
      redirection: `${routeConstants.MACHINES_ROUTE}?selectedTab=${machineDetailsResponse?.assetStatus ? '1' : '2'}`,
    },
    { text: MACHINE_DETAIL },
  ];

  useEffect(() => {
    let meterDetail = machineDetailsResponse?.assetMeters?.length !== 0 ? machineDetailsResponse?.assetMeters : null;
    setMeterData(meterDetail);
  }, [isSuccess, machineDetailsResponse]);

  useEffect(() => {
    let modems = currentModemsData?.data?.modems;
    if (modemSuccess && modems.length !== 0) {
      setModemData(currentModemsData?.data?.modems);
    } else {
      setModemData(null);
    }
  }, [modemSuccess, currentModemsData]);

  const UpdateMeterDataHandler = (data) => {
    setUpdateMeterData(data);
    setIsUpdateMeterField(true);
  };
  return (
    <React.Fragment>
      <Box className={classes.breadcrumbContainer} onClick={() => setModemUpdate(false)}>
        <Breadcrumbs
          icon={'/'}
          variant="smallText"
          underline="hover"
          data={modemUpdate ? editModemBreadCrumbData : breadcrumbData}
        />
      </Box>
      <Box mb={4} mt={4}>
        <Stack direction={'row'} spacing={2} alignItems="center">
          <Typography variant="h1" data-testid="machineTitle">
            {machineDetailsResponse?.title || 'N/A'}
          </Typography>
        </Stack>
      </Box>
      <Box mb={3} className={classes.machineDetailContent}>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title={'Machine Details'}
          content={
            isFetching || isLoading ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <MachineDetailView machineInfo={machineDetailsResponse} />
            )
          }
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: () =>
                    navigate(
                      `/${routeConstants.MACHINES_ROUTE}/${routeConstants.EDIT_MACHINE_ROUTE}/${machineDetailsResponse.id}`,
                      { redirect: true },
                    ),
                },
              ]}
              disabled={!isAdminOrEquipment}
            />
          }
        />
      </Box>
      {modemData && !modemUpdate && (
        <Box mb={3} className={classes.modemDetailContent}>
          <Accordion
            title={'Modem Details'}
            defaultExpanded={isModemDetailExpanded}
            content={
              modemFetching || modemLoading ? (
                <LoadingSpinner containerHeight={400} />
              ) : (
                <ModemDetailView modemInfo={modemData} />
              )
            }
            subComponent={
              <PositionedMenu
                menuItemData={[
                  {
                    btnName: 'Edit',
                    btnClickFunc: () => setModemUpdate(true),
                  },
                ]}
                disabled={!isAdminOrEquipment}
              />
            }
          />
        </Box>
      )}
      {modemData && modemUpdate && (
        <Box mb={3} className={classes.EditModemDetail}>
          <Accordion
            title={'Edit Modem Details'}
            content={
              <AssignModem
                machineDetails={machineDetailsResponse}
                showAssignModem={setModemUpdate}
                expandModemDetailView={setIsModemDetailExpanded}
                modemInfo={modemData}
              />
            }
            defaultExpanded
          />
        </Box>
      )}
      <Box mb={3} className={classes.noContent}>
        {!modemData && !showAssignModem && (
          <NoContent
            icon={<RouterOutlined className={classes.modemIcon} />}
            title="No Modem Associated"
            desc={
              <>
                There is no modem associated to this machine.
                <br />
                Please add new modem
              </>
            }
            buttonName="Assign New"
            onBtnClickHandler={() => {
              setShowAssignModem(true);
            }}
            disabled={!isAdminOrEquipment}
          />
        )}
        {showAssignModem && (
          <AssignModem
            machineDetails={machineDetailsResponse}
            showAssignModem={setShowAssignModem}
            expandModemDetailView={setIsModemDetailExpanded}
          />
        )}
      </Box>
      <Box mb={3} className={classes.noContent}>
        {!addMeter && !meterData && (
          <NoContent
            icon={<Speed className={classes.modemIcon} />}
            title="No Meter Associated"
            desc={
              <>
                There is no meter associated to this machine.
                <br />
                Please add new meter
              </>
            }
            buttonName="Add New"
            disabled={!isAdminOrEquipment}
            onBtnClickHandler={() => {
              setAddMeter(true);
            }}
          />
        )}
        {addMeter && <AddNewMeter setAddMeter={setAddMeter} machineId={params.id} meterInfo={meterData} />}
        {meterData &&
          !addMeter &&
          !isUpdateMeterField &&
          (isFetching || isLoading ? (
            <LoadingSpinner containerHeight={400} />
          ) : (
            <MeterTable
              meterInfo={meterData}
              setAddMeter={setAddMeter}
              onUpdateMeterField={UpdateMeterDataHandler}
              disabled={!isAdminOrEquipment}
            />
          ))}
        {isUpdateMeterField && (
          <EditMeterFields
            currentMeter={updateMeterData}
            setIsUpdateMeterField={setIsUpdateMeterField}
            meterInfo={meterData}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default MachineDetails;
