import submittedCollectionSlice from './submitted-filter';
export const {
  resetState,
  resetLocationSubmittedCollectionFiltersState,
  resetSubLocationSubmittedCollectionFiltersState,
  setLocationSubmittedCollectionFilters,
  setSubLocationSubmittedCollectionFilters,
} = submittedCollectionSlice.actions;

export default submittedCollectionSlice;
