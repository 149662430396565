import ReactTable from '../../../components/shared/ReactTable';
import { useMediaQuery, useTheme } from '@mui/material';
import { COLUMNS, MACHINE_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { useMemo, useState, useEffect, useCallback } from 'react';
import MachineAccordion from '../../../components/MachineAccordion/MachineAccordion';
import FIELD_TYPE from '../../../constants/fieldType';
import { useGetMachinesQuery } from '../../../services/Machines/MachinesService';
import { Link, NoContent } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { setActiveMachinesFilters } from '../../../redux-slice/machinesFilters';

const { NOT_APPLICABLE } = FIELD_TYPE;

export default function MachinesTable({ searchParams, onTotalMachine, machines }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(machines?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(machines?.rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetMachinesQuery(
    {
      searchParams: searchFilterData,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );
  const columns = useMemo(() => {
    const dynamicColumns = MACHINE_TABLE_DYNAMIC(isBelowMD);
    const col = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns];
    return col;
  }, [isBelowMD]);

  const titleLink = useCallback(
    (id) => (
      <Link underline="none" to={`/${routeConstants.MACHINES_ROUTE}/${id}`}>
        {id}
      </Link>
    ),
    [],
  );

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.assets.map((asset) => {
        const statusTitle = asset?.status?.title ?? NOT_APPLICABLE;
        const typeTitle = asset?.type?.title ?? NOT_APPLICABLE;
        const sublocationName = asset?.sublocation?.name ?? NOT_APPLICABLE;
        const numberOfPlays = asset?.numberOfPlays ?? 0;
        const modelTitle = asset?.model?.title ?? NOT_APPLICABLE;
        const transformMachine = {
          ...asset,
          statusTitle,
          typeTitle,
          sublocationName,
          numberOfPlays,
          modelTitle,
        };
        transformMachine.id = titleLink(asset.id);
        return transformMachine;
      });
      setTableData(data);
      let total = latestData?.pagination?.total ?? 0;
      setTotalCount(total);
      onTotalMachine(total ?? totalCount);
    } else if (isError) {
      onTotalMachine(0);
    }
  }, [isError, isSuccess, latestData]);

  const pageChangeHandler = (currentPage) => {
    dispatch(setActiveMachinesFilters({ ...machines, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(setActiveMachinesFilters({ ...machines, rowsPerPage: newPerPage, pageNumber: currentPage }));
    setPerPageNumber(newPerPage);
  };
  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(machines?.pageNumber);
    setPerPageNumber(machines?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  const detailLocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <MachineAccordion machineInfo={original} />;
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Machine Found"
          desc={(isError && error?.data?.message) || <>There are no machine present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          expandable
          renderRows
          renderRowSubComponent={detailLocationComponent}
        />
      )}
    </>
  );
}
