import React from 'react';
import { Stack, Box } from '@mui/material';
import { InformationField } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import ReportMachineSummaryField from './ReportMachineSummaryField';
import { FIELD_NAME, METER_TYPE } from '../../../constants/MeterTypeFieldMap';
import { useSelector } from 'react-redux';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import { getRevenueInUSD } from '../../../utils/collection-methods';
import { getCurrency } from '../../../utils/common-methods';

const SublocationSummaryField = ({ sublocations, sublocationNameMap, reportMachines }) => {
  const { formikData, locationCurrency } = useSelector((state) => state.newCollection);
  const { abbreviation, exchangeRate } = locationCurrency;
  return (
    <React.Fragment>
      {sublocations?.map((sub) => {
        let subtotal = 0;
        let subtotalTokenCollected = 0;
        let subtotalPrizeDispensed = 0;
        let subtotalTicketDispensed = 0;
        let subtotalMedallionDispensed = 0;
        sub?.assets
          ?.flatMap((asset) => {
            formikData.map((el) => {
              if (
                Number(el.assetId) === Number(asset.assetId) &&
                Number(el.sublocationId) === Number(sub.sublocationId) &&
                el?.meterName === METER_TYPE.TICKET &&
                el?.fieldName === FIELD_NAME.TICKET_DISPENSED
              ) {
                subtotalTicketDispensed += Number(el.fieldValue);
              }
              if (
                Number(el.assetId) === Number(asset.assetId) &&
                Number(el.sublocationId) === Number(sub.sublocationId) &&
                el?.meterName === METER_TYPE.PRIZE &&
                el?.fieldName === FIELD_NAME.PRIZE_DISPENSED
              ) {
                subtotalPrizeDispensed += Number(el.fieldValue);
              }
              if (
                Number(el.assetId) === Number(asset.assetId) &&
                Number(el.sublocationId) === Number(sub.sublocationId) &&
                el?.meterName === METER_TYPE.MEDALLION &&
                el?.fieldName === FIELD_NAME.MEDALLION_DISPENSED
              ) {
                subtotalMedallionDispensed += Number(el.fieldValue);
              }
            });

            if (asset) {
              return asset?.data;
            }
          })
          .filter((assetData) => {
            // Only add cash meter: coins and bills collected, coin meter: coins collected
            if (assetData && assetData.meterName === METER_TYPE.COIN) {
              subtotal += Number(assetData[FIELD_NAME.COINS_COLLECTED]);
            }
            if (assetData && assetData.meterName === METER_TYPE.CASH) {
              const sum = Number(assetData[FIELD_NAME.COINS_COLLECTED]) + Number(assetData[FIELD_NAME.BILLS_COLLECTED]);
              subtotal += sum;
            }
            if (assetData && assetData.meterName === METER_TYPE.CANDY) {
              const sum = Number(assetData[FIELD_NAME.COINS_COLLECTED]);
              subtotal += sum;
            }
            if (assetData && assetData.meterName === METER_TYPE.IMPULSE_READER) {
              const sum = Number(assetData[FIELD_NAME.GAME_REVENUE]);
              subtotal += sum;
            }
            if (assetData && assetData.meterName === METER_TYPE.TOKEN) {
              subtotalTokenCollected += Number(assetData[FIELD_NAME.TOKEN_COLLECTED]);
            }
          });

        return (
          <Stack key={sub.sublocationId}>
            <Box py={1}>
              <InformationField value={sublocationNameMap[sub.sublocationId]} variant="subtitle9" />
            </Box>
            <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
              <InformationField value={MESSAGE.ASSETS} variant="subtitle8" />
              <InformationField value={sub.assets.length} variant="h3" />
            </Stack>
            {subtotalTicketDispensed !== 0 && (
              <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
                <InformationField value={'Tickets dispensed'} variant="subtitle8" />
                <InformationField value={subtotalTicketDispensed} variant="h3" />
              </Stack>
            )}
            {subtotalPrizeDispensed !== 0 && (
              <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
                <InformationField value={'Prize dispensed'} variant="subtitle8" />
                <InformationField value={subtotalPrizeDispensed} variant="h3" />
              </Stack>
            )}
            {subtotalMedallionDispensed !== 0 && (
              <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
                <InformationField value={'Medallion dispensed'} variant="subtitle8" />
                <InformationField value={subtotalMedallionDispensed} variant="h3" />
              </Stack>
            )}
            {subtotalTokenCollected !== 0 && (
              <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
                <InformationField value={'Tokens collected'} variant="subtitle8" />
                <InformationField value={subtotalTokenCollected} variant="h3" />
              </Stack>
            )}
            <ReportMachineSummaryField sublocationId={sub.sublocationId} reportMachines={reportMachines} />
            <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
              <InformationField
                value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED} (${abbreviation || CURRENCY_CONSTANT.USD})`}
                variant="subtitle8"
              />
              <InformationField value={`${getCurrency(abbreviation)} ${subtotal}`} variant="h3" />
            </Stack>
            {abbreviation !== CURRENCY_CONSTANT.USD && (
              <Stack pr={2} py={1} direction={'row'} display="flex" justifyContent="space-between">
                <InformationField
                  value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED} (${CURRENCY_CONSTANT.USD})`}
                  variant="subtitle8"
                />
                <InformationField value={`$ ${getRevenueInUSD(subtotal, exchangeRate)}`} variant="h3" />
              </Stack>
            )}
          </Stack>
        );
      })}
    </React.Fragment>
  );
};
export default SublocationSummaryField;
