import React from 'react';
import { Box, Paper } from '@mui/material';
import { Tabs } from '../../../components/shared';
import { TAB_TYPES } from '../../../constants/CommonConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeConstants } from '../../../constants/routeConstants';
import SubLocationSubmittedCollection from './SubLocationSubmittedCollection';

const TABS = [
  {
    heading: TAB_TYPES.SUBMITTED_SUB_LOCATION_COLLECTIONS,
    content: <SubLocationSubmittedCollection />,
  },
];

const SubmittedCollections = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const collection = searchParams.get('collection');
  const selectedTab = searchParams.get('selectedTab');

  const onTabChange = (newValue) => {
    if (TABS.length > 1) {
      navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=${selectedTab}&collection=${newValue}`, {
        replace: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Box mt={2} component={Paper}>
        <Tabs defaultTab={collection === null ? '1' : collection} tabsData={TABS} onTabChange={onTabChange} />
      </Box>
    </React.Fragment>
  );
};

export default SubmittedCollections;
