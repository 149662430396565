import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useStyles from './style';
import MachinesTable from './MachineTable';
import { ROLE } from '../../../constants/roles';
import { Button } from '../../../components/shared';
import SearchBar from '../../../components/SearchBar';
import { sortIcon } from '../../../assets/images/icons';
import { routeConstants } from '../../../constants/routeConstants';
import { useHasRoles, useSearchFilterParam } from '../../../hooks';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';
import { useLazyGetModelsQuery } from '../../../services/Models/ModelsService';
import SORT_CONSTANT, { LATEST_UPDATED } from '../../../constants/SortConstants';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import { useLazyGetMachineStatusQuery } from '../../../services/Machines/MachinesService';
import { useLazyGetAllSublocationsQuery } from '../../../services/Sublocation/SublocationService';
import { resetActiveMachinesFiltersState, setActiveMachinesFilters } from '../../../redux-slice/machinesFilters';

const displayName = 'Machines';

const Machines = () => {
  const classes = useStyles();
  const canAdd = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.EQUIPMENT, ROLE.ACCOUNTING]);
  const [totalMachines, setTotalMachines] = useState('');
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const dispatch = useDispatch();
  const { activeMachines } = useSelector((state) => state.machinesFilter);
  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.MACHINE.INITIAL_PARAMS);
  const [sort, setSort] = useState(LATEST_UPDATED);
  const [getMachineStatus] = useLazyGetMachineStatusQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getModelQuery] = useLazyGetModelsQuery();

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.STATUS:
        return getMachineStatus;
      case FILTER_VALUES.SUBLOCATION:
        return getAllSublocations;
      case FILTER_VALUES.MODEL:
        return getModelQuery;
      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.MACHINE.FILTER.map((item) => ({
        ...item,
        api: getAPI(item.value),
      })),
    [],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.STATUS]: [],
      [FILTER_VALUES.SUBLOCATION]: [],
      [FILTER_VALUES.MODEL]: [],
    };

    if (activeMachines[FILTER_VALUES.STATUS] && activeMachines[FILTER_VALUES.STATUS]?.length > 0) {
      storedFilterParams.statusId = activeMachines[FILTER_VALUES.STATUS];
    }
    if (activeMachines[FILTER_VALUES.SUBLOCATION] && activeMachines[FILTER_VALUES.SUBLOCATION]?.length > 0) {
      storedFilterParams.subLocationId = activeMachines[FILTER_VALUES.SUBLOCATION];
    }
    if (activeMachines[FILTER_VALUES.MODEL] && activeMachines[FILTER_VALUES.MODEL]?.length > 0) {
      storedFilterParams.modelId = activeMachines[FILTER_VALUES.MODEL];
    }
    setSearchText(activeMachines.searchText);
    setSort(activeMachines.sort);
    setFilterParams(storedFilterParams);
  }, [activeMachines]);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.MACHINE,
    filterParams,
    sort,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setActiveMachinesFilters({
        ...activeMachines,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setActiveMachinesFilters({
        ...activeMachines,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const applyFilterHandler = (filterValues) => {
    dispatch(
      setActiveMachinesFilters({
        ...activeMachines,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(filterValues);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.MACHINE.INITIAL_PARAMS);
    dispatch(resetActiveMachinesFiltersState());
  };

  const handleSort = (e) => {
    dispatch(
      setActiveMachinesFilters({
        ...activeMachines,
        sort: e.target.value,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setSort(e.target.value);
  };

  return (
    <React.Fragment>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">Machines</Typography>
          <Button
            component={Link}
            to={`/${routeConstants.ADD_MACHINE_ROUTE}`}
            variant="contained"
            data-testid="add-machine-btn"
            disabled={!canAdd}
            startIcon={<Add />}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>
      <Box>
        <Typography pl={2} variant="h3" className={classes.totalMachines}>
          {totalMachines ? `${totalMachines} Machines` : ''}
        </Typography>
      </Box>
      <Box className={classes.machinesTable} mt={2} pt={2}>
        <Box className={classes.searchFilterWrap}>
          <Box className={classes.searchWrap}>
            <SearchBar onSearch={searchHandler} onReset={onReset} searchText={searchText} />
          </Box>
          <Box px={3}>
            <NewFilterDrawer
              name={TITLE.SORT_BY.toUpperCase()}
              title={TITLE.SORT_BY}
              drawerType={DRAWER_TYPES.SORT_DRAWER}
              startIcon={<>{sortIcon}</>}
              onSortSelect={handleSort}
              selectedSort={sort}
              sortData={SORT_CONSTANT.MACHINE_SORT}
            />
          </Box>
          <Box className={classes.filterWrap}>
            <NewFilterDrawer
              filteredValues={filterParams}
              name={TITLE.FILTERS.toUpperCase()}
              title={TITLE.FILTERS}
              drawerType={DRAWER_TYPES.FILTER_DRAWER}
              onReset={resetFilterParams}
              onApplyFilter={(v) => {
                applyFilterHandler(v);
              }}
              filterData={filterData}
              emptyFilterLength={JSON.stringify(FILTER_DATA.MACHINE.INITIAL_PARAMS).length}
            />
          </Box>
        </Box>
        <MachinesTable searchParams={searchParams} onTotalMachine={setTotalMachines} machines={activeMachines} />
      </Box>
    </React.Fragment>
  );
};

Machines.displayName = displayName;
export default Machines;
