import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getMachines: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.machine}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.machine}`;
      },
      transformResponse: (response) => {
        const pagination = response?.pagination;
        const loadAssets = response?.data?.assets;
        return { assets: loadAssets, pagination };
      },
      providesTags: ['Machines'],
    }),
    getMachineDetailsById: build.query({
      query: (id) => `${apiUrl.machine}/${id}`,
      providesTags: ['Machine'],
    }),
    getMachineDetailsHistoryById: build.query({
      query: (args) => {
        const { id, page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE } = args;

        return `${apiUrl.machine}/${id}/${apiUrl.history}?page=${page}&perPage=${perPage}`;
      },
      providesTags: ['Machine'],
    }),
    updateMachineById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.machine}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Machine', 'Machines', 'Sublocation'],
    }),
    getModemsByMachineId: build.query({
      query: (id) => `${apiUrl.machine}/${id}/modems`,
      providesTags: ['Machine', 'ModemMachine'],
    }),
    getMachineStatus: build.query({
      query: () => `${apiUrl.machineStatus}`,
    }),
    getMeters: build.query({
      query: () => `${apiUrl.meters}`,
    }),
    updateMetersByMachineId: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.machine}/${id}/meters`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Machine'],
    }),
    updateMachineMeterById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.machineMeters}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Machine', 'Location', 'Locations'],
    }),
    addMachine: build.mutation({
      query: (data) => ({
        url: `${apiUrl.machine}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Machines'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMachinesQuery,
  useGetMachineDetailsByIdQuery,
  useLazyGetMachineDetailsHistoryByIdQuery,
  useUpdateMachineByIdMutation,
  useGetModemsByMachineIdQuery,
  useGetMachineStatusQuery,
  useLazyGetMachineStatusQuery,
  useGetMetersQuery,
  useUpdateMetersByMachineIdMutation,
  useUpdateMachineMeterByIdMutation,
  useAddMachineMutation,
} = extendedApi;
