import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import useStyles from '../style';
import { DEFAULT_PER_PAGE, DEFAULT_PAGE } from '../../../constants/pagination';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_ACCOUNTING_ROLE, FILTER_VALUES } from '../../../constants/FilterConstants';
import SubmittedCollectionsTable from './SubmittedCollectionsTable';
import { useLazyGetAllLocationTypesQuery, useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import { useSearchFilterParam, useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import { useLazyGetUsersForSelectQuery } from '../../../services/Users/UsersService';
import { useDispatch, useSelector } from 'react-redux';
import { resetReconciliationFilterState, setReconciliationFilters } from '../../../redux-slice/collectionFilters';
import { getUserName } from '../../../utils/common-methods';
import {
  useLazyGetAllSublocationsQuery,
  useLazyGetProductCategoriesQuery,
} from '../../../services/Sublocation/SublocationService';

const displayName = 'Reconciliation';

const Reconciliation = () => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const isAccounting = useHasRoles([ROLE.ACCOUNTING]);
  const dispatch = useDispatch();
  const { reconciliation } = useSelector((state) => state.collectionFilter);
  const { userDetails } = useSelector((state) => state.user);
  const [noDefaultFilter, setNoDefaultFilter] = useState(false);

  const COLLECTION_RECONCILIATION = useMemo(() => {
    const filters = {
      INITIAL_PARAMS: isAccounting
        ? {
            ...FILTER_DATA.COLLECTION_RECONCILIATION.INITIAL_PARAMS,
            ...FILTER_DATA.COLLECTION_RECONCILIATION.ACCOUNTING_INITIAL_PARAMS,
          }
        : FILTER_DATA.COLLECTION_RECONCILIATION.INITIAL_PARAMS,
      FILTER: isAccounting
        ? [...FILTER_DATA.COLLECTION_RECONCILIATION.FILTER, ...FILTER_DATA.COLLECTION_RECONCILIATION.ACCOUNTING_FILTER]
        : FILTER_DATA.COLLECTION_RECONCILIATION.FILTER,
    };
    return filters;
  }, [isAccounting]);

  const [searchText, setSearchText] = useState('');
  const [selectedCollection, setSelectedCollection] = useState({});
  const [filterParams, setFilterParams] = useState(COLLECTION_RECONCILIATION.INITIAL_PARAMS);

  const [getLocations] = useLazyGetLocationsQuery();
  const [getAllSublocations] = useLazyGetAllSublocationsQuery();
  const [getAllLocationTypes] = useLazyGetAllLocationTypesQuery();
  const [getAccountingUsers] = useLazyGetUsersForSelectQuery();
  const [getAllProductTypes] = useLazyGetProductCategoriesQuery();
  const [getUsersForSelect] = useLazyGetUsersForSelectQuery();

  const getCustomParams = (value) => {
    switch (value) {
      case FILTER_VALUES.LOCATION:
        return { api: getLocations };
      case FILTER_VALUES.LOCATION_TYPE:
        return { api: getAllLocationTypes };
      case FILTER_VALUES.COLLECTION_SUBLOCATION:
        return { api: getAllSublocations, customParam: 'status=true' };
      case FILTER_VALUES.ACCOUNTING_USER:
        return { api: getAccountingUsers, customParam: `role=${FILTER_ACCOUNTING_ROLE}` };
      case FILTER_VALUES.PRODUCT_TYPE:
        return { api: getAllProductTypes };
      case FILTER_VALUES.USER:
        return { api: getUsersForSelect };
      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      COLLECTION_RECONCILIATION.FILTER.map((item) => {
        const params = getCustomParams(item.value);

        return {
          ...item,
          ...params,
        };
      }),
    [COLLECTION_RECONCILIATION],
  );

  useEffect(() => {
    const storedFilterParams = isAccounting
      ? {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.LOCATION_TYPE]: [],
          [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.ACCOUNTING_USER]: [],
        }
      : {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.LOCATION_TYPE]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        };
    if (reconciliation[FILTER_VALUES.LOCATION] && reconciliation[FILTER_VALUES.LOCATION]?.length > 0) {
      storedFilterParams.locationId = reconciliation[FILTER_VALUES.LOCATION];
    }
    if (reconciliation[FILTER_VALUES.LOCATION_TYPE] && reconciliation[FILTER_VALUES.LOCATION_TYPE]?.length > 0) {
      storedFilterParams.locationType = reconciliation[FILTER_VALUES.LOCATION_TYPE];
    }
    if (
      reconciliation[FILTER_VALUES.DATE_RANGE] &&
      reconciliation[FILTER_VALUES.DATE_RANGE]?.from &&
      reconciliation[FILTER_VALUES.DATE_RANGE]?.to
    ) {
      storedFilterParams.dateRange = reconciliation[FILTER_VALUES.DATE_RANGE];
    }
    if (reconciliation[FILTER_VALUES.ACCOUNTING_USER] && reconciliation[FILTER_VALUES.ACCOUNTING_USER]?.length > 0) {
      storedFilterParams.accountingUser = reconciliation[FILTER_VALUES.ACCOUNTING_USER];
    }
    if (reconciliation[FILTER_VALUES.ACCOUNTING_USER]?.length === 0 && isAccounting && !noDefaultFilter) {
      const username = getUserName(userDetails);
      const user = { name: username, text: username, id: userDetails?.id, value: userDetails?.id };
      if (!storedFilterParams.accountingUser.find((au) => au.id === user.id)) {
        storedFilterParams.accountingUser.push(user);
      }
    }
    if (
      reconciliation[FILTER_VALUES.COLLECTION_SUBLOCATION] &&
      reconciliation[FILTER_VALUES.COLLECTION_SUBLOCATION]?.length > 0
    ) {
      storedFilterParams.sublocation = reconciliation[FILTER_VALUES.COLLECTION_SUBLOCATION];
    }
    storedFilterParams.productType = reconciliation[FILTER_VALUES.PRODUCT_TYPE] ?? [];
    storedFilterParams.user = reconciliation[FILTER_VALUES.USER] ?? [];
    setSearchText(reconciliation.searchText);
    setFilterParams(storedFilterParams);
  }, [reconciliation, isAccounting, noDefaultFilter]);

  const searchParams = useSearchFilterParam({
    filterKey: COLLECTION_RECONCILIATION,
    filterParams,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setReconciliationFilters({
        ...reconciliation,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setReconciliationFilters({
        ...reconciliation,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetFilterParams = () => {
    setFilterParams(COLLECTION_RECONCILIATION.INITIAL_PARAMS);
    dispatch(resetReconciliationFilterState());
    setNoDefaultFilter(true);
  };

  const onCollectionSelectionChange = (currentSelectedCollection) => {
    const updatedSelectedCollection = { ...selectedCollection, ...currentSelectedCollection };
    setSelectedCollection(updatedSelectedCollection);
  };

  const onCancelPress = () => {
    setSelectedCollection({});
  };
  const onApprovePress = () => {
    setSelectedCollection({});
  };

  const onApplyFilterHandler = (v) => {
    dispatch(
      setReconciliationFilters({ ...reconciliation, ...v, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE }),
    );
    setFilterParams(v);
  };

  return (
    <React.Fragment>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            onSearch={searchHandler}
            placeholder={'Search by location'}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>
        <Box pl={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={onApplyFilterHandler}
            filterData={filterData}
            emptyFilterLength={
              COLLECTION_RECONCILIATION.INITIAL_PARAMS
                ? JSON.stringify(COLLECTION_RECONCILIATION.INITIAL_PARAMS)?.length
                : 0
            }
          />
        </Box>
      </Box>
      <SubmittedCollectionsTable
        searchParams={searchParams}
        allSelectedCollection={selectedCollection}
        onCollectionSelectionChange={onCollectionSelectionChange}
        onCancelPress={onCancelPress}
        onApprovePress={onApprovePress}
        reconciliation={reconciliation}
      />
    </React.Fragment>
  );
};

Reconciliation.displayName = displayName;
export default Reconciliation;
