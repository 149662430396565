export const LOCATION_OWNER_LABEL = {
  NAME: 'Company Name',
  EMAIL: 'Email Id',
  PHONE: 'Phone',
};

export const LOCATION_DETAIL_LABEL = {
  LOCATION_TYPE: 'Location Type',
  PHONE: 'Phone',
  CURRENCY: 'Currency',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
  ZIPCODE: 'Zipcode',
  OPEN_HOURS: 'Open Hours',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  STATE_TAX: 'State Tax %',
  AREA_TAX: 'Area Tax %',
  MISC_TAX: 'Misc Tax %',
  TAX_EXEMPT_FLAG: 'Tax Exempt',
  X3_LOCATIONS_ID: 'X3 LocationsID',
  X3_VENDORS_ID: 'X3 VendorsID',
  X3_LOCATIONS_NAME: 'X3 LocationName',
  X3_DEPARTMENT_ID: 'X3 DepartmentID',
  X3_INDUSTRY_ID: 'X3 IndustryID',
  X3_OWNER_ID: 'X3 OwnerID',
};

export const SUB_LOCATION_DETAIL_LABEL = {
  ZIPCODE: 'Zipcode',
  PHONE: 'Phone',
  OPEN_HOURS: 'Open Hours',
  LOCATION_TYPE: 'Sub Location Type',
  PLAY_TYPE: 'Play Type',
  LOCATION: 'Location',
  MANAGER: 'Manager',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  START_DATE: 'Installed Date',
  END_DATE: 'Lease End Date',
  UNINSTALL_DATE: 'Uninstall Date',
  SPACE_IDENTIFIER: 'Space Identifier',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
  PRODUCT_TYPE: 'Product Type',
  STATE_TAX: 'State Tax %',
  AREA_TAX_LOCATION: 'Area Tax Location',
  AREA_TAX: 'Area Tax %',
  MISC_TAX: 'Misc Tax %',
  TAX_EXEMPT_FLAG: 'Tax Exempt',
  LOC_PAYS_TAX: 'Location Pays Tax',
  CALC_RENT: 'Calculate Rent',
};

export const REMIT_DETAILS_LABEL = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  ZIPCODE: 'Zip code',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  EMAIL_ADDRESS: 'Email Address',
  CONTACT_NUMBER: 'Contact Number',
};

export const MODEL_DETAIL_LABEL = {
  LIST: {
    YEAR_MADE: 'Year Made',
    METER: 'Meter',
    HEIGHT: 'Height',
    WIDTH: 'Width',
    WEIGHT: 'Weight',
  },
  DETAILS: {
    MODEL_ID: 'Model ID',
    MANUFACTURER: 'Manufacturer',
    THEME: 'Theme',
    SUB_THEME: 'Sub Theme',
    CATEGORY: 'Category',
    YEAR_MADE: 'Year Made',
    HEIGHT: 'Height',
    WIDTH: 'Width',
    WEIGHT: 'Weight',
    TYPE: 'Type',
  },
  MACHINES: {
    TYPE: 'Type',
    PRICE: 'Price',
    MODEL: 'Model',
  },
};

export const USER_DETAIL_LABEL = {
  ROLES: 'Roles',
  EMAIL_ID: 'Email ID',
  PHONE: 'Phone',
  JOINING_DATE: 'Joining Date',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  REGIONAL_MANAGER: 'Regional Manager',
  ACCOUNTING_USER: 'Accounting User',
};

export const USER_LOCATION_DETAIL_LABEL = {
  OWNER: 'Owner',
  ZIPCODE: 'Zipcode',
  PHONE1: 'Phone1',
  PHONE2: 'Phone2',
  OPEN_HOURS: 'Open Hours',
  CURRENCY: 'Currency',
  LOCATION_TYPE: 'Location Type',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
};

export const LEASE_DETAIL_LABEL = {
  LOCATION: 'Location',
  SUBLOCATION: 'Sub Location',
  ADDRESS1: 'Location Address 1',
  ADDRESS2: 'Location Address 2',
  CITY: 'City',
  STATE: 'State',
  ZIPCODE: 'Zipcode',
  COUNTRY: 'Country',
  RENT_TYPE: 'Rent Type',
  RENT_RATE: 'Rent Rate',
  RENT_FEE: 'Rent Fee',
};

export const LEASE_DETAILS = {
  LEASE: {
    DATE: 'Date',
    LOCATION: 'Location',
    SUB_LOCATION: 'Sub Location',
    CITY: 'City',
    STATE: 'State',
    ZIPCODE: 'Zipcode',
    ADDRESS1: 'Address 1',
    ADDRESS2: 'Address 2',
    COUNTRY: 'Country',
    BOOTH_INFO: 'Booth Info',
    SPACE: 'Space',
    DBA: 'DBA',
    SALES_PERSON: 'Sales Person',
    TYPE: 'Type',
    STATUS: 'Status',
    LEASE_BEGINS: 'Lease Begins',
    LEASE_ENDS: 'Lease Ends',
  },
  RENT: {
    RENT_TYPE: 'Rent Type',
    RENT_FEE: 'Rent Fee',
    RENT_CALCULATION_TIMING: 'Rent Calculation Timing',
    RENT_RATE: 'Rent Rate',
    CALCULATION_STARTS: 'Calculation Starts',
    CALCULATION_ENDS: 'Calculation Ends',
    BREAKPOINT_NATURAL: 'Breakpoint Natural',
    BREAKPOINT_VALUE: 'Breakpoint Value',
    CAM_FEE: 'CAM Fee',
    CAM_RATE: 'CAM Rate',
    THRESHOLD_RATE: 'Threshold Rate',
    THRESHOLD_REVENUE: 'Threshold Revenue',
    NOTES: 'Notes',
    COIN_COST: 'Coin Cost',
    CARD_COST: 'Card Cost',
  },
  GENERAL: {
    WORKERS_COMP: 'Workers Comp (s/b $1million)',
    GEN_LIABILITY: 'Gen Liability (s/b $2million)',
    UMBRELLA: 'Umbrella (s/b $5million)',
    TERMINATION_DAYS: 'Termination Days',
    RELOCATION_DAYS: 'Relocation Days',
    SALES_REPORTS_BY: 'Sales Reports By',
    PERCENT_RENT_BY: 'Percent Rent By',
    FIXED_RENT_BY: 'Fixed Rent Due',
    ADDENDUM: 'Addendum',
    SECURITY_DEPOSIT: 'Security Deposit',
    GROSS_SALES: 'Gross Sales',
    OCC: 'OCC%',
  },
};
