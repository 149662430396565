import { useState, useEffect } from 'react';
import { Datepicker, GridContainer, GridItem, Button } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import {
  checkObjectNotEmpty,
  checkValueNotNullUndefinedBlank,
  dateFormatForApi,
  getDateFormat,
  isAllObjectValuesNullOrZero,
} from '../../../utils/common-methods';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSublocationDates,
  setIntercardData,
  setSubLocationDatesFormIsDirty,
} from '../../../redux-slice/newcollection';
import {
  setSublocationDatesForReconciliation,
  setUpdateStatesForReconciliationSublocationDatesChange,
  setIntercardDataForReconciliation,
  setDefaultAccountSummaryState,
} from '../../../redux-slice/reconciliationCollection';
import { useGetCollectionsBySublocationIdQuery } from '../../../services/CollectionService/CollectionService';
import {
  getIntercardData,
  getLastCollectionDate,
  getNewAssetDataFromSublocation,
  getSeedLiveDataForUpdate,
  getPrevIntercardDataExcludingUpdatedIntercard,
  getInitialPlaycardState,
  getRevisedPlaycardValuesWithChangeInDateOfReading,
  getPlayCardSalesRevenueInUsd,
} from '../../../utils/collection-methods';
import { setPlayCardSalesSummary, setPlayCards } from '../../../redux-slice/playcardsSummary';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';

const SublocationDateModule = ({
  sublocation,
  originalSublocation,
  setSkip,
  setSublocation,
  skip,
  isReconciliation = false,
  readFromDraft,
  handleSublocationDeleteClick,
  turnOffDraft = () => {},
}) => {
  const [dateOfReading, setDateOfReading] = useState(isReconciliation ? sublocation?.dateOfReading : new Date());
  const [prevDateOfReading, setPrevDateOfReading] = useState(dateOfReading);
  const [dateEntered, setDateEntered] = useState(isReconciliation ? sublocation?.dateEntered : new Date());
  const theme = useTheme();
  const isBelowLG = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { intercardData, sublocationDates } = useSelector((state) => state.newCollection);
  const { intercardDataForReconciliation, sublocationDatesForReconciliation, accountSummary } = useSelector(
    (state) => state.reconciliationCollection,
  );
  const { draftSublocationDates } = useSelector((state) => state.storedCollections);
  const { draftSublocationDatesOfReconciliation } = useSelector((state) => state.storedReconciliationCollections);

  const { playCards } = useSelector((state) => state.playCard);
  const isNotIndependentRep = useHasRoles([
    ROLE.SUPER_ADMIN_IT,
    ROLE.EXECS,
    ROLE.ACCOUNTING,
    ROLE.MANAGER,
    ROLE.DM,
    ROLE.VP,
    ROLE.REGIONAL_MANAGER,
  ]);
  const hasDeleteSublocationAndAssetAccess = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);
  useEffect(() => {
    if (
      isReconciliation &&
      sublocationDatesForReconciliation &&
      checkObjectNotEmpty(sublocationDatesForReconciliation) &&
      checkObjectNotEmpty(sublocationDatesForReconciliation[sublocation?.id])
    ) {
      let sublocationDatesFromState = sublocationDatesForReconciliation[sublocation?.id];
      setDateOfReading(sublocationDatesFromState?.dateOfReading);
      setDateEntered(sublocationDatesFromState?.dateEntered);
    }
    if (!isReconciliation) {
      if (sublocationDates && checkObjectNotEmpty(sublocationDates[sublocation?.id])) {
        let sublocationDatesFromState = sublocationDates[sublocation?.id];
        if (checkObjectNotEmpty(sublocationDatesFromState)) {
          setDateOfReading(sublocationDatesFromState?.dateOfReading);
          setDateEntered(sublocationDatesFromState?.dateEntered);
        }
      }
    }
  }, [sublocation]);

  useEffect(() => {
    if (readFromDraft && !isReconciliation) {
      const dates = draftSublocationDates[sublocation?.id];
      setDateOfReading(dates?.dateOfReading);
      setDateEntered(dates?.dateEntered);
    }
    if (readFromDraft && isReconciliation) {
      const dates = draftSublocationDatesOfReconciliation[sublocation?.id];
      setDateOfReading(dates?.dateOfReading);
      setDateEntered(dates?.dateEntered);
    }
  }, [readFromDraft, isReconciliation]);

  useEffect(() => {
    if (checkObjectNotEmpty(sublocation)) {
      if (!isReconciliation) {
        dispatch(
          setSublocationDates({
            [sublocation?.id]: {
              dateOfReading: dateFormatForApi(dateOfReading),
              dateEntered: dateFormatForApi(dateEntered),
            },
          }),
        );
      }
    }
  }, [sublocation]);

  const {
    currentData: sublocationData,
    error,
    isError,
    isSuccess,
  } = useGetCollectionsBySublocationIdQuery(
    {
      sublocationId: Number(sublocation?.id),
      dateOfReading: dateFormatForApi(dateOfReading || new Date()),
      transactionId: sublocation?.transactionId || '',
    },
    { skip: skip },
  );

  useEffect(() => {
    if (isSuccess && sublocationData) {
      const seedLiveStatesForUpdate = getSeedLiveDataForUpdate(sublocationData?.seedLiveData, originalSublocation);
      if (checkValueNotNullUndefinedBlank(seedLiveStatesForUpdate) && checkObjectNotEmpty(seedLiveStatesForUpdate)) {
        dispatch(setUpdateStatesForReconciliationSublocationDatesChange(seedLiveStatesForUpdate));
      }
      // Update all assets of originalSublocation
      const { updatedAssets } = getNewAssetDataFromSublocation(
        originalSublocation,
        sublocationData?.intercardTransaction,
      );

      // Update current asset
      const { updatedAssets: updatedCurrentAssets } = getNewAssetDataFromSublocation(
        sublocation,
        sublocationData?.intercardTransaction,
      );

      const intercardDataForUpdate = getIntercardData([{ ...originalSublocation, assets: [...updatedAssets] }]);
      if (!isReconciliation) {
        const prevIntercardData = getPrevIntercardDataExcludingUpdatedIntercard(intercardData, updatedAssets);
        dispatch(setIntercardData({ ...prevIntercardData, ...intercardDataForUpdate }));
      }
      if (isReconciliation) {
        const prevIntercardData = getPrevIntercardDataExcludingUpdatedIntercard(
          intercardDataForReconciliation,
          updatedAssets,
        );
        dispatch(setIntercardDataForReconciliation({ ...prevIntercardData, ...intercardDataForUpdate }));
      }
      // use updated current asset for this filtered sublocation
      setSublocation({
        ...sublocation,
        dateOfReading: dateOfReading,
        dateEntered: dateEntered,
        assets: [...updatedCurrentAssets],
      });
      const getCurrentPlayCard = playCards[sublocation?.id];
      const playCardData = sublocationData?.playcardData[0];
      let initialPlaycardState = getInitialPlaycardState(playCardData);
      const prevPlaycardValues =
        checkObjectNotEmpty(playCards) && checkObjectNotEmpty(getCurrentPlayCard) ? getCurrentPlayCard : {};
      if (!isAllObjectValuesNullOrZero(playCardData) || sublocation?.isIntercardSublocation) {
        const revisedState = getRevisedPlaycardValuesWithChangeInDateOfReading(
          initialPlaycardState,
          prevPlaycardValues,
        );
        initialPlaycardState = {
          ...revisedState,
        };
      }
      const finalPlayCardCollection = {
        ...playCards,
        [sublocation?.id]: initialPlaycardState,
      };

      const totalPlayCardSales = getPlayCardSalesRevenueInUsd(finalPlayCardCollection);
      dispatch(setPlayCards({ ...playCards, [sublocation?.id]: initialPlaycardState }));
      dispatch(setPlayCardSalesSummary(totalPlayCardSales));
      if (accountSummary?.defaultAccountSummaryState) {
        dispatch(setDefaultAccountSummaryState(false));
      }
    } else if (isError && prevDateOfReading) {
      setDateOfReading(prevDateOfReading);
    }
  }, [sublocationData, isSuccess, isError]);

  const handleReadingDateChange = (value) => {
    setPrevDateOfReading(dateOfReading);
    setDateOfReading(getDateFormat(value));
    setSkip(false);
    dispatch(setSubLocationDatesFormIsDirty(true));
    turnOffDraft();
  };
  const handleDateEnteredChange = (value) => {
    setDateEntered(getDateFormat(value));
    dispatch(setSubLocationDatesFormIsDirty(true));
  };

  useEffect(() => {
    if (checkObjectNotEmpty(sublocation)) {
      if (!isReconciliation) {
        dispatch(
          setSublocationDates({
            [sublocation.id]: {
              dateOfReading: dateFormatForApi(dateOfReading),
              dateEntered: dateFormatForApi(dateEntered),
            },
          }),
        );
      }
      if (isReconciliation) {
        dispatch(
          setSublocationDatesForReconciliation({
            [sublocation.id]: {
              dateOfReading: dateFormatForApi(dateOfReading),
              dateEntered: dateFormatForApi(dateEntered),
            },
          }),
        );
      }
    }
  }, [dateEntered, dateOfReading]);

  const lastCollectionDate = getLastCollectionDate(sublocation, isReconciliation);
  return (
    <GridContainer lg={12} className={classes.dateContainer}>
      <GridItem xs={12} sm={12} md={12} lg={2} py={isBelowLG ? 1 : 0}>
        <Typography data-testid="accordionTitle" variant="subtitle5">
          {sublocation?.name}
        </Typography>
      </GridItem>
      <GridItem
        xs={12}
        sm={3.5}
        md={4}
        lg={2}
        mr={3}
        my={1}
        className={classes.datePointerEvent}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Datepicker
          label={MESSAGE.DATE_OF_READING}
          name="dateOfReading"
          id="dateOfReading"
          onChange={handleReadingDateChange}
          value={dateOfReading}
          inputFormat="MM/DD/YYYY"
          disableFuture
          error={isError && error.status === 419}
          helperText={isError && error.status === 419 ? error.data.message : ''}
        />
      </GridItem>
      <GridItem
        xs={12}
        sm={3.5}
        md={4}
        lg={2}
        mr={3}
        my={1}
        className={classes.datePointerEvent}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Datepicker
          label={MESSAGE.DATE_ENTERED}
          name="dateEntered"
          onChange={handleDateEnteredChange}
          value={dateEntered}
          id="dateEntered"
          inputFormat="MM/DD/YYYY"
          disableFuture
          disabled={!isNotIndependentRep}
        />
      </GridItem>
      <GridItem xs={12} sm={3.5} md={3} lg={3}>
        <Typography variant="subtitle2">{`Last Collection - ${
          checkValueNotNullUndefinedBlank(lastCollectionDate) ? getDateFormat(lastCollectionDate) : 'N/A'
        }`}</Typography>
      </GridItem>
      {hasDeleteSublocationAndAssetAccess && isReconciliation && (
        <GridItem xs={12} sm={3.5} md={3} lg={2}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSublocationDeleteClick(sublocation.collectionId); // Handle the delete logic
            }}
          >
            delete
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default SublocationDateModule;
