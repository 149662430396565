const STATUS = {
  APPROVED: 'APPROVED',
  SUBMITTED: 'SUBMITTED',
  VERIFIED: 'VERIFIED',
};

export const COLLECTION_STATUS = [
  { name: STATUS.SUBMITTED, id: STATUS.SUBMITTED, text: STATUS.SUBMITTED, value: STATUS.SUBMITTED },
  { name: STATUS.VERIFIED, id: STATUS.VERIFIED, text: STATUS.VERIFIED, value: STATUS.VERIFIED },
  { name: STATUS.APPROVED, id: STATUS.APPROVED, text: STATUS.APPROVED, value: STATUS.APPROVED },
];
