import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, InputAdornment, IconButton, Typography, Tooltip } from '@mui/material';
import { TextField } from '../shared';
import { Send, Close } from '@mui/icons-material';
import { getESTDateFormat } from '../../utils/common-methods';
import { useUpdateCommentMutation, useDeleteCommentMutation } from '../../services/CollectionService/CollectionService';
import dayjs from 'dayjs';
import useStyles from './style';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModel';
import { MESSAGE } from '../../constants/message';
import Comment from './Comment';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleVariant: PropTypes.string,
    fieldName: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialCommentData: PropTypes.arrayOf(PropTypes.object),
  isApproved: PropTypes.bool,
};

const CommentBox = ({
  data = {},
  handleClose = () => {},
  handleSubmit = () => {},
  initialCommentData = [],
  isApproved = false,
}) => {
  const params = useParams();
  const { title, titleVariant, fieldName } = data;
  const classes = useStyles();
  const commentScrollRef = useRef();
  const { name, userDetails } = useSelector((state) => state.user);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelEditingModal, setShowCancelEditing] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [deleteCommentText, setDeleteCommentText] = useState(null);
  const [commentData, setCommentData] = useState(initialCommentData);
  const [newComment, setNewComment] = useState('');
  const [editMode, setEditMode] = useState(null);
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  useEffect(() => {
    if (commentScrollRef.current?.scrollHeight) {
      commentScrollRef.current.scrollTop = commentScrollRef.current.scrollHeight;
    }
  }, [commentData]);

  const handleCommentUpdate = () => {
    if (newComment) {
      const date = dayjs();
      const createdAt = getESTDateFormat(date);
      const newCommentData = {
        name,
        comment: newComment,
        userId: userDetails.id,
        createdAt,
      };

      if (editMode !== null) {
        // Editing a comment
        if (commentData[editMode].id) {
          // Edit for existing comment (with an ID from the server)
          updateComment({
            transactionId: params.id,
            commentId: commentData[editMode].id,
            data: newCommentData,
          })
            .then(() => {
              const updatedComments = [...commentData];
              updatedComments[editMode] = {
                ...newCommentData,
                id: commentData[editMode].id,
                createdByUser: userDetails.id,
              };
              setCommentData(updatedComments);
              setEditMode(null);
            })
            .catch((error) => {
              console.error('Error updating comment:', error);
            });
        } else {
          // Edit for new comment (no ID)
          const updatedComments = [...commentData];
          updatedComments[editMode] = {
            ...newCommentData,
            createdByUser: userDetails.id,
          };
          setCommentData(updatedComments);
          handleSubmit(updatedComments);
          setEditMode(null);
        }
      } else {
        // Adding a new comment
        setCommentData((prevComments) => [...prevComments, newCommentData]);
        handleSubmit([...commentData, newCommentData]);
      }
      setNewComment('');
    }
  };

  const handleEditClick = (index) => {
    setEditMode(index);
    setNewComment(commentData[index].comment);
  };

  const clearInputAndEditMode = () => {
    setNewComment('');
    setEditMode(null);
  };

  const onDeleteClickHandler = (id, commentText) => {
    setDeleteCommentId(id);
    setDeleteCommentText(commentText);
    setShowDeleteModal(true);
  };

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      if (deleteCommentId) {
        deleteHandlerAPI(deleteCommentId);
      } else if (deleteCommentText) {
        const updatedComments = commentData.filter((comment) => comment.comment !== deleteCommentText);
        setCommentData(updatedComments);
        handleSubmit(updatedComments);
      }
      clearInputAndEditMode(); // Clear input and edit mode after deletion
    }
    setShowDeleteModal(false);
    setDeleteCommentId(null);
    setDeleteCommentText(null);
  };

  const deleteHandler = () => {
    if (deleteCommentId) {
      setCommentData(commentData.filter((comment) => comment.id !== deleteCommentId));
    } else if (deleteCommentText) {
      setCommentData(commentData.filter((comment) => comment.comment !== deleteCommentText));
    }
    clearInputAndEditMode(); // Clear input and edit mode after deletion
    setDeleteCommentId(null);
    setDeleteCommentText(null);
  };

  const deleteHandlerAPI = (id) => {
    deleteComment({ transactionId: params.id, commentId: id })
      .then(() => {
        deleteHandler();
      })
      .catch((error) => {
        console.error('Error deleting comment:', error);
        // TODO: We could show a message to user here.
      });
  };

  const handleCancelEditClick = () => {
    setShowCancelEditing(true);
  };

  const handleCancelEditModalClick = (value) => {
    if (value === MESSAGE.YES) {
      setNewComment('');
      setEditMode(null);
    }
    setShowCancelEditing(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.commentHead}>
        <Typography variant={titleVariant}>{title}</Typography>
        <IconButton onClick={() => handleClose(commentData)}>
          <Close />
        </IconButton>
      </Box>
      {/* Comment List */}
      <Box ref={commentScrollRef} className={classes.commentList}>
        {commentData.map((item, index) => (
          <Comment
            key={index}
            comment={item}
            editMode={editMode === index}
            onEdit={() => handleEditClick(index)}
            onDelete={onDeleteClickHandler}
            userDetails={userDetails}
          />
        ))}
      </Box>
      {!isApproved && (
        <Box>
          <TextField
            label=""
            variant="outlined"
            placeholder="Write a comment..."
            name={fieldName}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            InputProps={{
              'aria-label': 'Comment',
              'data-testid': 'CommentInput',
              className: editMode !== null ? classes.editModeInput : '',
              startAdornment: (
                <InputAdornment position="start">
                  {editMode !== null && (
                    <Tooltip title="Cancel Edit of Comment" arrow>
                      <IconButton onClick={handleCancelEditClick}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={editMode !== null ? 'Edit Comment' : 'Save Comment'} arrow>
                    <IconButton onClick={handleCommentUpdate}>
                      <Send />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      {showDeleteModal && (
        <ConfirmationModal
          key="delete-modal"
          isOpen={showDeleteModal}
          title={MESSAGE.CONFIRMATION}
          msg={MESSAGE.RECONCILIATION_DELETE_COMMENT}
          buttons={[
            { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
            { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
          ]}
          onClick={onDeleteModalClickHandler}
        />
      )}
      {showCancelEditingModal && (
        <ConfirmationModal
          key="cancel-editing-modal"
          isOpen={showCancelEditingModal}
          title={MESSAGE.CONFIRMATION_CANCEL_ACTION}
          msg={MESSAGE.RECONCILIATION_CANCEL_EDITING_COMMENT}
          buttons={[
            { text: MESSAGE.YES, value: MESSAGE.YES },
            { text: MESSAGE.NO, value: MESSAGE.NO },
          ]}
          onClick={handleCancelEditModalClick}
        />
      )}
    </Box>
  );
};

CommentBox.propTypes = propTypes;
export default CommentBox;
