import { Button, Typography, Paper, Box } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { PositionedMenu } from '../../../components/shared';
import ReactTable from '../../../components/shared/ReactTable';
import { COLUMNS } from '../../../constants/tableColumns';
import useStyles from './style';
import { Add } from '@mui/icons-material';
import { METER_TYPES } from '../../../constants/MeterConstants';

const MeterTable = ({ meterInfo, setAddMeter, onUpdateMeterField, disabled }) => {
  const classes = useStyles();
  const [meters, setMeters] = useState([]);

  const COLUMN_METER_NAME = {
    Header: 'METER NAME',
    accessor: 'meterName',
    Cell: ({ row }) => (
      <Typography className={`${classes.meterNameColumn}`}>
        <Box component="span" className={!row.original.status ? classes.archive : ''}>
          {row.original.meterName}
        </Box>
      </Typography>
    ),
  };
  const rowCell = (row, data) => (
    <Typography>
      <Box component="span" className={!row.original.status ? classes.archive : ''}>
        {row.original[data]}
      </Box>
    </Typography>
  );

  const METER_COLS = COLUMNS.ASSET_METER_TABLE.map((col) => ({
    ...col,
    Cell: ({ row }) => rowCell(row, col.accessor),
  }));
  const COLUMN_POSITIONED_MENU = [
    {
      Header: () => null,
      id: 'more',
      Cell: ({ row }) => (
        <Box component="span" className={!row.original.status ? classes.archive : ''}>
          <PositionedMenu
            menuItemData={[
              {
                btnName: 'Edit',
                btnClickFunc: () => {
                  onUpdateMeterField(row.original);
                },
              },
            ]}
            disabled={disabled}
          />
        </Box>
      ),
    },
  ];

  const meterColumns = [COLUMN_METER_NAME, ...METER_COLS, ...COLUMN_POSITIONED_MENU];
  useEffect(() => {
    if (meterInfo) {
      const meterData = meterInfo.map((meter) => {
        let currentAssetMeterReading = meter.currentAssetMeterReading ?? 0;
        if (
          METER_TYPES.CANDY_METER.includes(meter?.meter.name) ||
          METER_TYPES.IMPULSE_READER_METER.includes(meter?.meter.name)
        ) {
          currentAssetMeterReading = 'N/A';
        }
        return {
          ...meter,
          meterName: `${meter?.meter.name} Meter`,
          identifier: meter.identifier ?? '',
          currentAssetMeterReading: currentAssetMeterReading,
          maxCount: meter.maxCount ?? 0,
        };
      });
      setMeters(meterData);
    }
  }, [meterInfo]);

  const AddBtn = () => (
    <Box className={classes.addBtn}>
      <Button
        data-testid="add-newMeter"
        variant="outlined"
        size="large"
        startIcon={<Add />}
        onClick={() => {
          setAddMeter(true);
        }}
        disabled={disabled}
      >
        ADD NEW
      </Button>
    </Box>
  );
  return (
    <Box component={Paper} className={classes.addMeterTable}>
      <Box className={classes.meterTableHeading}>
        <Typography variant="h2">Meters</Typography>
      </Box>
      <ReactTable data={meters} columns={meterColumns} localFilter extraBtn={<AddBtn />} />
    </Box>
  );
};

export default MeterTable;
