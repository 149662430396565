import ReactTable from '../../../components/shared/ReactTable';
import AddIcon from '@mui/icons-material/Add';
import { COLUMNS } from '../../../constants/tableColumns';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useGetLocationsQuery } from '../../../services/Location/LocationService';
import { ControlledTooltips, Link, NoContent } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { getControlledTooltipValueAndCellValue, getDateFormat } from '../../../utils/common-methods';
import FIELD_TYPE from '../../../constants/fieldType';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { setOpenCollectionFilters } from '../../../redux-slice/collectionFilters';

const CollectionLocationsColumns = [
  ...COLUMNS.COLLECTIONS_LOCATIONS_TABLE,
  {
    Header: () => null,
    id: 'newCollection',
    Cell: ({ row }) => (
      <span>
        <Link to={`/${routeConstants.COLLECTIONS_ROUTE}/${routeConstants.ADD_COLLECTIONS_ROUTE}/${row?.original?.id}`}>
          {<AddIcon />}
        </Link>
      </span>
    ),
    width: 56,
  },
];

export default function CollectionsTable({ searchParams, openCollection }) {
  const COLLECTION_LOCATIONS_COLUMNS = useMemo(() => CollectionLocationsColumns, []);
  const [currentPage, setCurrentPage] = useState(openCollection?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(openCollection?.rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetLocationsQuery({ searchParams, page: currentPage, perPage: perPageNumber });

  const pageChangeHandler = (currentPage) => {
    dispatch(setOpenCollectionFilters({ ...openCollection, pageNumber: currentPage, rowsPerPage: perPageNumber }));
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(setOpenCollectionFilters({ ...openCollection, rowsPerPage: newPerPage, pageNumber: currentPage }));
    setPerPageNumber(newPerPage);
  };

  const getSublocationName = useCallback((sublocations) => {
    const sublocationNames = sublocations.map((sub) => sub?.name);
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(sublocationNames);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  const getProductTypes = useCallback((productTypes) => {
    const { tooltipValue, cellValue } = getControlledTooltipValueAndCellValue(productTypes);
    return <ControlledTooltips tooltipValue={tooltipValue}>{cellValue}</ControlledTooltips>;
  }, []);

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData?.locations?.map((location) => {
        const current = { ...location };
        if (current?.lastCollection) {
          current.lastCollection = getDateFormat(current?.lastCollection?.dateOfReading) ?? FIELD_TYPE.NOT_APPLICABLE;
        } else {
          current.lastCollection = FIELD_TYPE.NOT_APPLICABLE;
        }
        if (!current?.sublocationName) {
          current.sublocationName = getSublocationName(location?.sublocations?.filter((sub) => sub?.status));
        }
        if (current?.productTypes) {
          current.productTypes = getProductTypes(current.productTypes);
        }
        return current;
      });
      setTableData(data);
      let total = latestData?.pagination?.total ?? 0;
      setTotalCount(total);
    }
  }, [isSuccess, latestData]);

  useEffect(() => {
    setCurrentPage(openCollection?.pageNumber);
    setPerPageNumber(openCollection?.rowsPerPage);
  }, [searchParams]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Location Found"
          desc={(isError && error?.data?.message) || <>There are no location present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={COLLECTION_LOCATIONS_COLUMNS}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
        />
      )}
    </>
  );
}
