import { KeyboardArrowRight, ExpandMore, HistoryOutlined } from '@mui/icons-material';

export const COLUMNS = {
  LOCATION_TABLE: {
    COMMON: [
      {
        Header: 'LOCATION NAME',
        accessor: 'locationName',
        width: 'auto',
      },
      {
        Header: 'LOCATION TYPE',
        accessor: 'locationType',
        width: 'auto',
      },
      { Header: 'PRODUCT TYPE', accessor: 'productTypes', hideHeaderAndColumn: false, width: 'auto' },
    ],
    get ACTIVE() {
      return [
        ...this.COMMON,
        {
          Header: 'CITY',
          accessor: 'cityName',
          width: 'auto',
        },
      ];
    },

    get ARCHIVE() {
      return [
        ...this.COMMON,
        {
          Header: 'DATE ARCHIVED',
          accessor: 'dateArchived',
          width: 'auto',
        },
        {
          Header: () => null,
          id: 'archive',
          Cell: () => (
            <span data-testid="expandIcon">
              <HistoryOutlined />
            </span>
          ),
        },
      ];
    },
  },
  SUBLOCATION_TABLE_DEFAULT: [
    {
      Header: 'Zip Code',
      accessor: 'code',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'Phone 1',
      accessor: 'phone1',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'Phone 2',
      accessor: 'phone2',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'Open Hours',
      accessor: 'openHours',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'Close Hours',
      accessor: 'closeHours',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'currency',
      accessor: 'currency',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'sub_location_type',
      accessor: 'subLocationType',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'address 1',
      accessor: 'address1',
      hideHeaderAndColumn: true,
    },
    {
      Header: 'address 2',
      accessor: 'address2',
      hideHeaderAndColumn: true,
    },
  ],
  SUBLOCATION_MACHINE_TABLE: [
    {
      Header: 'MACHINE NAME',
      accessor: 'title',
    },
    {
      Header: 'MACHINE TYPE',
      accessor: ' machineType',
    },
  ],
  USER_TABLE: [
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'EMAIL ID',
      accessor: 'email',
    },
    {
      Header: 'ROLE',
      accessor: 'roles',
    },
    {
      Header: 'LOCATION',
      accessor: 'location',
    },
  ],
  ASSIGN_LOCATION_TABLE: [
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'Postal',
      accessor: 'postcode',
    },
  ],
  COLLECTIONS_LOCATIONS_TABLE: [
    {
      Header: 'LOCATION NAME',
      accessor: 'locationName',
      width: 'auto',
    },
    {
      Header: 'SUB LOCATIONS NAME',
      accessor: 'sublocationName',
      width: 'auto',
    },
    { Header: 'PRODUCT TYPE', accessor: 'productTypes', width: 'auto' },
    {
      Header: 'LAST COLLECTION',
      accessor: 'lastCollection',
      width: 'auto',
    },
  ],
  SUBMITTED_COLLECTIONS_LOCATIONS_TABLE: [
    {
      Header: 'LOCATION NAME',
      accessor: 'locationName',
      width: 'auto',
    },
    {
      Header: 'SUBMITTED BY',
      accessor: 'serviceRep',
      width: 'auto',
    },
    {
      Header: 'TYPE',
      accessor: 'locationType',
      width: 'auto',
    },
    { Header: 'PRODUCT TYPE', accessor: 'productTypes', width: 'auto' },
    {
      Header: 'SUBMISSION DATE',
      accessor: 'submissionDate',
      width: 'auto',
    },
    {
      Header: 'TOTAL REVENUE',
      accessor: 'locationRevenue',
      width: 'auto',
    },
  ],
  SUBMITTED_COLLECTIONS_FOR_SUBLOCATIONS_TABLE: [
    {
      Header: 'SUBLOCATION NAME',
      accessor: 'sublocationName',
      width: 'auto',
    },
    {
      Header: 'LOCATION NAME',
      accessor: 'locationName',
      width: 'auto',
    },
    {
      Header: 'DATE OF READING',
      accessor: 'dateOfReading',
      width: 'auto',
    },
    {
      Header: 'LAST SUBMISSION DATE',
      accessor: 'dateEntered',
      width: 'auto',
    },
    { Header: 'PRODUCT TYPE', accessor: 'productTypes', width: 'auto' },
    {
      Header: 'STATUS',
      accessor: 'status',
      width: 'auto',
    },
  ],
  SUBMITTED_COLLECTIONS_FOR_LOCATIONS_TABLE: [
    {
      Header: 'LOCATION NAME',
      accessor: 'locationName',
      width: 'auto',
    },
    {
      Header: 'SUBLOCATION NAME',
      accessor: 'sublocationName',
      width: 'auto',
    },
    {
      Header: 'LAST SUBMISSION DATE',
      accessor: 'dateEntered',
      width: 'auto',
    },
    {
      Header: 'PRODUCT TYPE',
      accessor: 'productType',
      width: 'auto',
    },
  ],
  APPROVED_COLLECTION_TABLE: [
    {
      Header: 'LOCATION NAME',
      accessor: 'locationName',
      width: 'auto',
    },
    {
      Header: 'SUBMITTED BY',
      accessor: 'serviceRep',
      width: 'auto',
    },
    {
      Header: 'TYPE',
      accessor: 'locationType',
      width: 'auto',
    },
    { Header: 'PRODUCT TYPE', accessor: 'productTypes', width: 'auto' },
    {
      Header: 'SUBMISSION DATE',
      accessor: 'submissionDate',
      width: 'auto',
    },
    {
      Header: 'TOTAL REVENUE',
      accessor: 'locationRevenue',
      width: 'auto',
    },
  ],
  LOCATION_OWNERS_TABLE: [
    {
      Header: 'Owner / Business Name',
      accessor: 'name',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'Postal Code',
      accessor: 'postal_code',
    },
  ],
  MODEMS_TABLE: [
    {
      Header: 'Serial Number',
      accessor: 'serialNumber',
      width: 'auto',
    },
    {
      Header: 'Installed date',
      accessor: 'installedDate',
      width: 'auto',
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 'auto',
    },
    {
      Header: 'Asset ID',
      accessor: 'assetId',
      width: 'auto',
    },
  ],
  ASSIGN_MODEM_TABLE: [
    {
      Header: 'SERIAL NUMBER',
      accessor: 'serialNumber',
      width: 'auto',
    },
    {
      Header: 'INSTALLED DATE',
      accessor: 'installedDate',
      width: 'auto',
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      width: 'auto',
    },
    {
      Header: 'MACHINE NAME',
      accessor: 'assetName',
      width: 'auto',
    },
  ],
  ASSET_METER_TABLE: [
    {
      Header: 'POSITION',
      accessor: 'identifier',
      width: 'auto',
    },
    {
      Header: 'MAX COUNT',
      accessor: 'maxCount',
      width: 'auto',
    },
    {
      Header: 'CURRENT METER',
      accessor: 'currentAssetMeterReading',
      width: 'auto',
    },
  ],
  ASSIGN_MACHINE_TABLE: [
    {
      Header: 'ASSET ID',
      accessor: 'id',
      width: 'auto',
    },
    {
      Header: 'LEGACY ASSET ID',
      accessor: 'legacyAssetId',
      width: 'auto',
    },
    {
      Header: 'MODEL NO',
      accessor: 'modelId',
      width: 'auto',
    },
    {
      Header: 'MACHINE NAME',
      accessor: 'title',
      width: 'auto',
    },
    {
      Header: 'MODEL TYPE',
      accessor: 'modelTitle',
      width: 'auto',
    },
    {
      Header: 'SUBLOCATION NAME',
      accessor: 'sublocationName',
      width: 'auto',
    },
  ],
  ASSIGN_SUBLOCATION_MACHINE_TABLE: [
    {
      Header: 'MODEL TYPE',
      accessor: 'modelTitle',
      width: 'auto',
    },
    {
      Header: 'SUBLOCATION NAME',
      accessor: 'sublocationName',
      width: 'auto',
    },
  ],
  EXPAND_ICON_COLUMN: {
    Header: () => null,
    id: 'expander',
    isPadding: true,
    padding: '20px 0px 20px 24px',
    width: 56,
    Cell: ({ row }) => (
      <span
        {...row.getToggleRowExpandedProps()}
        data-testid="expandIcon"
        style={{
          width: '32px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRight />}
      </span>
    ),
  },
  HISTORY_ICON_COLUMN: {
    Header: () => null,
    id: 'history',
    width: 64,
    isPadding: true,
    padding: '20px 32px 20px 0px',
    Cell: () => (
      <span
        data-testid="historyIcon"
        style={{
          width: '32px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HistoryOutlined />
      </span>
    ),
  },
  RECONCILIATION_ACCORDION_ASSET_TABLE: [
    {
      Header: 'ASSET ID',
      accessor: 'id',
      width: 'auto',
    },
    {
      Header: 'LEGACY ASSET ID',
      accessor: 'legacyAssetId',
      width: 'auto',
    },
    {
      Header: 'MACHINE NAME',
      accessor: 'title',
      width: 'auto',
    },
  ],
  CREATE_COLLECTION_ASSET_TABLE: [
    {
      Header: 'LAST COLLECTION',
      accessor: 'lastCollection',
      width: 'auto',
    },
  ],
  RECONCILIATION_APPROVED_ACCORDION_ASSET_TABLE: [
    {
      Header: 'CLICKS PER PLAY',
      accessor: 'clicksPerPlay',
      width: 'auto',
    },
    {
      Header: 'COST PER PLAY',
      accessor: 'costPerPlay',
      width: 'auto',
    },
  ],
};

const LOCATION_TABLE_DYNAMIC = (isBelowMD, isArchiveTab) => {
  const COMMON = [
    {
      Header: 'STATE',
      accessor: 'stateName',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
  ];
  const ACTIVE_COLUMNS = [
    ...COMMON,
    {
      Header: 'COUNTRY',
      accessor: 'countryName',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
  ];
  const ARCHIVE_COLUMNS = [
    {
      Header: 'CITY',
      accessor: 'cityName',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
    ...COMMON,
  ];
  return isArchiveTab ? ARCHIVE_COLUMNS : ACTIVE_COLUMNS;
};

const SUBLOCATION_TABLE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'LEGACY SUB LOCATION ID',
      accessor: 'legacysublocId',
      width: 'auto',
    },
    {
      Header: 'NO.OF MACHINES',
      accessor: 'noOfMachines',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
  ];
  return columns;
};

const USER_TABLE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'EMAIL ID',
      accessor: 'email',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'ROLE',
      accessor: 'roles',
    },
    {
      Header: 'SUB LOCATION',
      accessor: 'location',
    },
  ];
  return columns;
};

const USER_ASSIGN_LOCATION_TABLE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'City',
      accessor: 'city',
      width: 300,
    },
    {
      Header: 'State',
      accessor: 'state',
      width: 300,
    },
    {
      Header: 'Postal',
      accessor: 'postcode',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
  ];

  return columns;
};

const MODEL_TABLE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'MODEL TITLE',
      accessor: 'modelTitle',
      width: 'auto',
    },
    {
      Header: 'MANUFACTURER',
      accessor: 'manufacturer',
      width: 'auto',
    },
    {
      Header: 'THEME',
      accessor: 'theme',
      width: 'auto',
    },
    {
      Header: 'SUB THEME',
      accessor: 'subTheme',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
    {
      Header: 'CATEGORY',
      accessor: 'category',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
  ];

  return columns;
};

const MODEL_MACHINE_DYNAMIC_COLUMN = (isBelowMD) => [
  {
    Header: 'MACHINE ID',
    accessor: 'machineId',
    width: 'auto',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
    width: 'auto',
  },
  {
    Header: 'STATUS',
    accessor: 'statusTitle',
    width: 'auto',
  },
  {
    Header: 'NUMBER OF PLAYS',
    accessor: 'numberOfPlays',
    hideHeaderAndColumn: isBelowMD,
    width: 'auto',
  },
  {
    Header: 'INSTALLED DATE',
    accessor: 'installedDate',
    hideHeaderAndColumn: isBelowMD,
    width: 'auto',
  },
  {
    Header: 'SUB LOCATION',
    accessor: 'sublocationName',
    width: 'auto',
  },
];

const USER_DETAILS_DYNAMIC_COLUMNS = (isBelowMD) => {
  const columns = [
    {
      Header: 'ADDRESS',
      accessor: 'address1',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'ZIPCODE',
      accessor: 'postcode',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
  ];
  return columns;
};

const USER_DETAILS_DYNAMIC_SUB_LOC_TABLE = (isBelowMD) => {
  // This is a Table within Table in user details page of location's sub-location
  // So we need to use react to re-render the table, because react-table package does not re-render it when there is a change
  // Hence the below code
  if (isBelowMD)
    return [
      {
        Header: 'SUB LOCATION NAME',
        accessor: 'subLocationName',
        width: 'auto',
      },
      {
        Header: 'STATE',
        accessor: 'state',
        width: 'auto',
      },
      {
        Header: 'ZIPCODE',
        accessor: 'postcode',
        width: 'auto',
      },
      {
        Header: 'REP RATE',
        accessor: 'repRate',
        width: 'auto',
        hideHeaderAndColumn: isBelowMD,
      },
      {
        Header: 'REP FEE',
        accessor: 'repFee',
        width: 'auto',
        hideHeaderAndColumn: isBelowMD,
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        width: 'auto',
        hideHeaderAndColumn: isBelowMD,
      },
    ];

  return [
    {
      Header: 'SUB LOCATION NAME ',
      accessor: 'subLocationName',
      width: 'auto',
    },

    {
      Header: 'CITY',
      accessor: 'city',
      width: 'auto',
    },
    {
      Header: 'STATE',
      accessor: 'state',
      width: 'auto',
    },
    {
      Header: 'ZIPCODE',
      accessor: 'postcode',
      width: 'auto',
    },
    {
      Header: 'REP RATE',
      accessor: 'repRate',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'REP FEE',
      accessor: 'repFee',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      width: 'auto',
      hideHeaderAndColumn: isBelowMD,
    },
  ];
};
const MACHINE_TABLE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'ASSET ID',
      accessor: 'id',
      width: 'auto',
    },
    {
      Header: 'LEGACY ASSET ID',
      accessor: 'legacyAssetId',
      width: 'auto',
    },
    {
      Header: 'MACHINE NAME',
      accessor: 'title',
      width: 'auto',
    },
    {
      Header: 'STATUS',
      accessor: 'statusTitle',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
    {
      Header: 'MODEL NAME',
      accessor: 'modelTitle',
      hideHeaderAndColumn: isBelowMD,
      width: 'auto',
    },
    {
      Header: 'SUBLOCATION',
      accessor: 'sublocationName',
      width: 'auto',
    },
  ];
  return columns;
};

const DEPOSIT_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'DEPOSIT #',
      accessor: 'id',
    },
    {
      Header: 'SERVICE REP',
      accessor: 'serviceRep',
    },
    {
      Header: 'SUB LOCATION NAME',
      accessor: 'sublocationName',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'DEPOSIT DATE',
      accessor: 'depositDate',
      hideHeaderAndColumn: isBelowMD,
    },
    {
      Header: 'TOTAL DEPOSIT AMOUNT',
      accessor: 'totalDepositAmount',
    },
  ];

  return columns;
};

const LEASE_TABLE_DYNAMIC = (isBelowMD, isArchiveTab) => {
  const COMMON = [
    {
      Header: 'LEASE NAME',
      accessor: 'name',
      width: isArchiveTab ? 900 : 600,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      width: 'auto',
    },
    { Header: 'PRODUCT TYPE', accessor: 'productTypes', width: 'auto' },
  ];
  const ACTIVE_COLUMNS = [
    ...COMMON,
    {
      Header: 'START DATE',
      accessor: 'startDate',
      width: 'auto',
    },
    {
      Header: 'END DATE',
      accessor: 'endDate',
      width: 'auto',
    },
  ];

  const ARCHIVE_COLUMNS = [
    {
      Header: 'ARCHIVED DATE',
      accessor: 'archivedDate',
      width: 150,
    },
    ...COMMON,
  ];

  return isArchiveTab ? ARCHIVE_COLUMNS : ACTIVE_COLUMNS;
};

const CURRENCY_EXCHANGE_DYNAMIC = (isBelowMD) => {
  const columns = [
    {
      Header: 'CURRENCY',
      accessor: 'currencyName',
    },
    {
      Header: 'CODE',
      accessor: 'currencyAbbreviation',
    },
    {
      Header: 'CONVERSION RATE',
      accessor: 'exchangeRate',
    },
    {
      Header: 'UPDATED AT',
      accessor: 'updatedAt',
      hideHeaderAndColumn: isBelowMD,
    },
  ];

  return columns;
};

const INTERCARD_TRACKINGS_TABLE = () => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 'auto',
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      width: 'auto',
    },
    {
      Header: 'CREATED BY',
      accessor: 'createdBy',
      width: 'auto',
    },
  ];

  return columns;
};

export {
  LOCATION_TABLE_DYNAMIC,
  SUBLOCATION_TABLE_DYNAMIC,
  USER_TABLE_DYNAMIC,
  USER_ASSIGN_LOCATION_TABLE_DYNAMIC,
  MODEL_TABLE_DYNAMIC,
  MODEL_MACHINE_DYNAMIC_COLUMN,
  USER_DETAILS_DYNAMIC_COLUMNS,
  USER_DETAILS_DYNAMIC_SUB_LOC_TABLE,
  MACHINE_TABLE_DYNAMIC,
  DEPOSIT_DYNAMIC,
  LEASE_TABLE_DYNAMIC,
  CURRENCY_EXCHANGE_DYNAMIC,
  INTERCARD_TRACKINGS_TABLE,
};
